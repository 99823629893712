import React, { useState, Fragment } from 'react';
import { AuthConsumer } from '../AuthContext';
import ContentHeader from '../../Layout/Common/ContentHeader';

// Semantic UI
import { Form, Button } from 'semantic-ui-react';

export default function RecoverPasswordPage() {
    const [email, set_email] = useState("");
    return (
        <AuthConsumer>
            {({ RecoverUserAccount }) => (
                <Fragment>
                    <ContentHeader text="Recover account" />
                        <div className="PageForm">
                            <div className="FormContainer">

                                <div style={{ paddingBottom: 20 }}>
                                    <p style={{ fontSize: 16, fontWeight: 700 }}>Recover your account</p>
                                    <p style={{ fontSize: 14, fontWeight: 500 }}>Tell us the email address associated with your account, and we’ll send you an email with your username and a password reset link.</p>
                                </div>

                                <Form onSubmit={() => RecoverUserAccount(email)}>

                                    <Form.Field>
                                        <label>Email</label>
                                        <input
                                            value={email}
                                            onChange={(e) => set_email(e.target.value)}
                                        />
                                    </Form.Field>

                                    <Button
                                        content="Send"
                                        type="submit"
                                        fluid
                                        color="facebook"
                                        disabled={email === ""}
                                    />

                                </Form>

                            </div>
                        </div>
                </Fragment>
            )}
        </AuthConsumer>
    )
}

import React from "react";

import IconsRow from "./IconsRow/IconsRow";

const HeaderInfoCol = ({ project, name, side }) => (
  <div className="building-info-col">
    <div
      className={`building-info-name-row ${side === "left" ? "left" : "right"}`}
    >
      {name}
    </div>
    <IconsRow project={project} />
  </div>
);

export default HeaderInfoCol;

import React, { Fragment, useState } from "react";
import { Accordion, Icon } from "semantic-ui-react";
import ContentHeader from "../../Common/ContentHeader";
import "./FagPage.css";

const FaqPage = () => {
  return (
    <Fragment>
      <ContentHeader text="FAQ" />

      <div className="ContentContainer">
        <div className="ContentRow" style={{ flexDirection: "column" }}>
          {FAQCategories.map((category) => (
            <div key={category.name} className="faq-category">
              <div className="faq-category-header">{category.name}</div>
              {category.items.map((item) => (
                <FaqAccordion key={item.question} faq={item} />
              ))}
            </div>
          ))}
        </div>

        <div className="ContentRow">
          <p className="contact-text">
            If you have any questions that have not been answered in the
            frequently asked questions please contact us at <a href="jokotai@oopeaa.com">jokotai@oopeaa.com</a>
          </p>
        </div>
      </div>
    </Fragment>
  );
};

const FaqAccordion = ({ faq }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <Accordion className="faq-accordion">
      <Accordion.Title
        className="faq-question"
        onClick={() => setOpen(!isOpen)}
        active={isOpen}
      >
        <div className="faq-question-icon">
          <Icon name="dropdown" />
        </div>
        <div className="">{faq.question}</div>
      </Accordion.Title>
      <Accordion.Content active={isOpen}>
        <p className="faq-answer">{faq.answer}</p>
      </Accordion.Content>
    </Accordion>
  );
};

const FAQCategories = [
  {
    name: "Aim of the tool",
    items: [
      {
        question:
          "Can I perform a full life cycle assessment with the JOKOTAI tool?",
        answer:
          "No, the JOKOTAI Material Impact Screener is meant to be used for a rough screening of a preliminary building design and not a full life cycle analysis. The scope of the JOKOTAI tool includes only the production phase of building materials (LCA phase A1-3). ",
      },
      {
        question: "What can the JOKOTAI tool be used for?",
        answer:
          "The JOKOTAI tool can be used as a support in the early design phases of a building to understand and communicate about the impact of the material choices with team mates in the architecture office and with clients, developers or public officials.",
      },
    ],
  },
  {
    name: "Building Design",
    items: [
      {
        question:
          "Can I import a building design from an existing CAD or 3D modelling software?",
        answer:
          "No, the JOKOTAI tool does not include the option for importing a 3D file from a CAD or 3D modelling software. The tool was developed with the intention of enabling a quick, web-based 3D sketch of a building without the need for owning a license to a 3D modelling software. The tool can be used independently of a full 3D modelling software prior or during the design process.",
      },
      {
        question:
          "How can I create a building to screen the environmental impact of my design choices?",
        answer:
          "A 3D sketch of a building can be created by clicking through the “building set-up” pages before jumping into the comparison view. By using dynamic sliders, the building’s dimensions, structural density, structural system and opening percentage can be adjusted.",
      },
      {
        question:
          "Can I design a free-form building or combination of multiple buildings with the JOKOTAI tool?",
        answer:
          "No, the current version of the JOKOTAI tool only allows for a simplified rectangular building shape and the design of one building only. However, in a future development of the tool we would like to include a more flexible building design and the assessment of multiple buildings at the same time.",
      },
    ],
  },
  {
    name: "Calculation",
    items: [
      {
        question:
          "Why does the tool only focus on the production phase (LCA phase A1-3)?",
        answer:
          "We decided to focus on the life cycle phase that, after the consumption of operative energy, is the most resource, energy and emission intensive part of a building’s life cycle. The production phase is also the phase in which the architect has the most pertinent influence in determining choices about the structural system and materials that have a significant impact on the overall sustainability of a building.",
      },
      {
        question:
          "What does the production phase (LCA phase A1-3) of a building include?",
        answer:
          "LCA phase A1-3 includes the extraction of raw materials, transportation to production facilities and full production process of the building material.",
      },
      {
        question:
          "Is the transportation distance to the construction site included?",
        answer:
          "No, the transportation distance to the construction site is calculated in LCA phase A4 and is therefore not included. However, all selected material EPD’s are from European manufacturers, which limits the transportation distances to the area of the European Union.",
      },
      {
        question: "Is carbon storage potential included in the results?",
        answer:
          "No, the carbon storage potential is not included in the amount of CO2 emissions, but listed separately. We wanted to clearly divide the amount of emissions that occur during the extraction, transportation and production process from the carbon storage benefit of some building materials. The carbon storage potential can be found next to the emission / energy result in the top bar marked in green.",
      },
      {
        question:
          "In which environmental indicators are the results displayed?",
        answer:
          "The results are shown in the environmental indicators global warming potential (kgCO2e) and primary energy from non-renewable energy sources (MJ). The indicator category can be switched by clicking the black button with the earth / light bulb symbol in the centre.",
      },
      {
        question: "How is the material volume calculated?",
        answer:
          "The material volume is calculated by multiplying the user-defined building settings (dimensions, structural density, structural system and opening percentage) with the predefined material thicknesses of the chosen materials. A list of the pre-defined material thicknesses can be found in the “research” page.",
      },
      {
        question: "How high is the accuracy of the results?",
        answer:
          "The results are based on predefined values for material thickness and preselected EPD’s for all materials. Therefore, the results should be seen as a rough overview and not as a precise assessment that reflects every detail of the building design. They are intended as estimates to help inform decisions regarding material choices at an early stage of the design process, not as precise calculations of a completed project.",
      },
    ],
  },
  {
    name: "Materials",
    items: [
      {
        question: "Why can I choose materials for only seven building parts?",
        answer:
          "The JOKOTAI tool enables you to choose materials for the building parts that usually have the largest impact on the environmental footprint.",
      },
      {
        question: "How were the materials for the tool selected?",
        answer:
          "The material library was created on the basis of practical knowledge about commonly used building materials. Furthermore, materials were included that may not be considered as conventional building materials, but represent an ecological alternative.",
      },
      {
        question: "How did you decide which EPD to use for each material?",
        answer:
          "Each material that can be selected in the tool is represented by a European based environmental product declaration (EPD). The EPDs were selected with a focus on covering a wide range of common building materials from different European countries. In order to use EPDs that represent the average environmental impact of a product and to avoid large aberrations, the EPD selection is based on a comparison of multiple EPDs of the same product from different manufacturers, after which the EPD closest to the median  was chosen for the JOKOTAI tool.",
      },
      {
        question: "Where can I find the EPD source of each material?",
        answer:
          "A link to each EPD can be found in the material infobox in the comparison screen, under the tab “calculation”.",
      },
    ],
  },
];

export default FaqPage;

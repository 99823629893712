import React, { Component } from "react";

// 3D
import { BabylonView } from "../Scene/BabylonView";
import { CreateFoundation } from "../Functions/Foundation";
import { CreateFloor } from "../Functions/Floor";
import { CreateHorizontalConstruction } from "../Functions/HorizontalConstruction";
import { CreateVerticalConstruction } from "../Functions/VerticalConstruction";
import { CreateFacade } from "../Functions/Facade";
import { CreateInsulation } from "../Functions/Insulation";
import { CreateRoof } from "../Functions/Roof";
import { StandardMaterial } from "babylonjs";
import { material_types } from "../../ProjectSettings";

export default class BuildingMaterialsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: null,
      foundation: null,
      floor: null,
      horizontal: null,
      vertical: null,
      facade: null,
      insulation: null,
      roof: null,
      floorMaterial: null,
      horizontalMaterial: null,
      verticalMaterial: null,
      facadeMaterial: null,
      facadeOpeningMaterial: null,
      insulationMaterial: null,
      insulationOpeningMaterial: null,
      roofMaterial: null,
      projectId: null,
    };
  }

  componentDidMount() {
    let { project } = this.props;
    let { width, depth, stories, story_height } = project;

    // Results test
    let view = new BabylonView(this.props.option);
    view.updateZoom(width, story_height * stories, depth);

    // Create materials
    let floorMaterial = new StandardMaterial("floorMaterial", view.scene);
    let horizontalMaterial = new StandardMaterial(
      "horizontalMaterial",
      view.scene
    );
    let verticalMaterial = new StandardMaterial("verticalMaterial", view.scene);
    let facadeMaterial = new StandardMaterial("facadeMaterial", view.scene);
    let facadeOpeningMaterial = new StandardMaterial(
      "facadeOpeningMaterial",
      view.scene
    );
    let insulationMaterial = new StandardMaterial(
      "insulationMaterial",
      view.scene
    );
    let insulationOpeningMaterial = new StandardMaterial(
      "insulationOpeningMaterial",
      view.scene
    );
    let roofMaterial = new StandardMaterial("roofMaterial", view.scene);

    // Use different ambient colour data conditionally on option
    let suffix = "" + (this.props.option === "Option B" ? "_b" : "_a");

    // Set materials ambient colours
    floorMaterial.ambientColor = view.rgbToCol3(
      material_types[
        this.props.materials_list[this.props.project["floor" + suffix]]
          .material_type
      ].colour
    );
    horizontalMaterial.ambientColor = view.rgbToCol3(
      material_types[
        this.props.materials_list[this.props.project["horizontal" + suffix]]
          .material_type
      ].colour
    );
    verticalMaterial.ambientColor = view.rgbToCol3(
      material_types[
        this.props.materials_list[this.props.project["vertical" + suffix]]
          .material_type
      ].colour
    );
    facadeMaterial.ambientColor = view.rgbToCol3(
      material_types[
        this.props.materials_list[this.props.project["facade" + suffix]]
          .material_type
      ].colour
    );
    facadeOpeningMaterial.ambientColor = view.rgbToCol3(
      material_types[
        this.props.materials_list[this.props.project["facade" + suffix]]
          .material_type
      ].colour_dark
    );
    insulationMaterial.ambientColor = view.rgbToCol3(
      material_types[
        this.props.materials_list[this.props.project["insulation" + suffix]]
          .material_type
      ].colour
    );
    insulationOpeningMaterial.ambientColor = view.rgbToCol3(
      material_types[
        this.props.materials_list[this.props.project["insulation" + suffix]]
          .material_type
      ].colour_dark
    );
    roofMaterial.ambientColor = view.rgbToCol3(
      material_types[
        this.props.materials_list[this.props.project["roof" + suffix]]
          .material_type
      ].colour
    );

    // Create building geometry elements
    let foundation = CreateFoundation(project, view);
    let floor = CreateFloor(project, view, floorMaterial);
    let horizontalConstruction = CreateHorizontalConstruction(
      project,
      view,
      horizontalMaterial
    );
    let verticalConstruction = CreateVerticalConstruction(
      project,
      view,
      verticalMaterial
    );
    let facade = CreateFacade(
      project,
      view,
      facadeMaterial,
      facadeOpeningMaterial
    );
    let insulation = CreateInsulation(
      project,
      view,
      insulationMaterial,
      insulationOpeningMaterial
    );
    let roof = CreateRoof(project, view, roofMaterial);

    this.setState({
      view: view,
      foundation: foundation,
      floor: floor,
      horizontalConstruction: horizontalConstruction,
      verticalConstruction: verticalConstruction,
      facade: facade,
      insulation: insulation,
      roof: roof,
      floorMaterial: floorMaterial,
      horizontalMaterial: horizontalMaterial,
      verticalMaterial: verticalMaterial,
      facadeMaterial: facadeMaterial,
      facadeOpeningMaterial: facadeOpeningMaterial,
      insulationMaterial: insulationMaterial,
      insulationOpeningMaterial: insulationOpeningMaterial,
      roofMaterial: roofMaterial,
      projectId: project.id,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.project.id !== this.props.project.id) {
    }

    if (this.props !== prevProps) {
      // Update materials ambient colours
      let suffix = "" + (this.props.option === "Option B" ? "_b" : "_a");
      const {
        floorMaterial,
        horizontalMaterial,
        verticalMaterial,
        facadeMaterial,
        facadeOpeningMaterial,
        insulationMaterial,
        insulationOpeningMaterial,
        roofMaterial,
      } = this.state;

      // Set materials ambient colours
      floorMaterial.ambientColor = this.state.view.rgbToCol3(
        material_types[
          this.props.materials_list[this.props.project["floor" + suffix]]
            .material_type
        ].colour
      );
      horizontalMaterial.ambientColor = this.state.view.rgbToCol3(
        material_types[
          this.props.materials_list[this.props.project["horizontal" + suffix]]
            .material_type
        ].colour
      );
      verticalMaterial.ambientColor = this.state.view.rgbToCol3(
        material_types[
          this.props.materials_list[this.props.project["vertical" + suffix]]
            .material_type
        ].colour
      );
      facadeMaterial.ambientColor = this.state.view.rgbToCol3(
        material_types[
          this.props.materials_list[this.props.project["facade" + suffix]]
            .material_type
        ].colour
      );
      facadeOpeningMaterial.ambientColor = this.state.view.rgbToCol3(
        material_types[
          this.props.materials_list[this.props.project["facade" + suffix]]
            .material_type
        ].colour_dark
      );
      insulationMaterial.ambientColor = this.state.view.rgbToCol3(
        material_types[
          this.props.materials_list[this.props.project["insulation" + suffix]]
            .material_type
        ].colour
      );
      insulationOpeningMaterial.ambientColor = this.state.view.rgbToCol3(
        material_types[
          this.props.materials_list[this.props.project["insulation" + suffix]]
            .material_type
        ].colour_dark
      );
      roofMaterial.ambientColor = this.state.view.rgbToCol3(
        material_types[
          this.props.materials_list[this.props.project["roof" + suffix]]
            .material_type
        ].colour
      );

      this.setState({
        floorMaterial: floorMaterial,
        horizontalMaterial: horizontalMaterial,
        verticalMaterial: verticalMaterial,
        facadeMaterial: facadeMaterial,
        facadeOpeningMaterial: facadeOpeningMaterial,
        insulationMaterial: insulationMaterial,
        insulationOpeningMaterial: insulationOpeningMaterial,
        roofMaterial: roofMaterial,
      });
    }
  }

  render() {
    return <canvas className="Canvas" id={this.props.option} />;
  }
}

import React, { Fragment } from "react";
import { ProjectConsumer } from "../ProjectContext";

// 3D
//import BuildingComparisonView from '../Geometry/Views/BuildingComparisonView';
import BuildingMaterialsView from "../Geometry/Views/BuildingMaterialsView";

// Containers
import ComparisonMenu from "./Containers/ComparisonMenu";
import MaterialInfoContainer from "./Containers/MaterialInfoContainer";
import MaterialButton from "./Common/MaterialButton";
import GraphView from "./Containers/GraphView";
import GuideModals from "./Containers/GuideModals/GuideModals";
//import MaterialInfo from "./Containers/MaterialInfoContainer/MaterialInfo";

import ComparisonHeader from "./Containers/6_ComparisonView/ComparisonHeader";

// Functions
import { calculateResult } from "../Functions/Results";

function ComparisonView(props) {
  let { project, materials_list, latest_selection } = props;
  const results = calculateResult(project, materials_list, latest_selection);

  return (
    <ProjectConsumer>
      {({
        project,
        materials,
        materials_list,
        handleMaterialChange,
        calculation_unit,
        display_graphs,
      }) => (
        <Fragment>
          {/* Guide Modals */}
          <GuideModals />

          <ComparisonHeader
            project={project}
            results={results}
            unit={calculation_unit}
          />

          {/* CONTENT */}
          <div className="ProjectContentContainer">
            <ComparisonMenu />

            <div className="ProjectContentCol">
              {display_graphs ? (
                <GraphView
                  project={project}
                  results={results}
                  building="building1"
                  unit={calculation_unit}
                  side="left"
                  materials_list={materials_list}
                />
              ) : (
                <Fragment>
                  <BuildingMaterialsView
                    key={project.id}
                    project={project}
                    materials_list={materials_list}
                    option="Option A"
                  />

                  <div className="ProjectSelectionContainer VerticalPadded">
                    <div className="ButtonCollection">
                      <MaterialButton
                        project={project}
                        category="Foundation"
                        materials={materials}
                        materials_list={materials_list}
                        building="building1"
                        changeHandler={handleMaterialChange}
                      />
                      <MaterialButton
                        project={project}
                        category="Vertical structure"
                        materials={materials}
                        materials_list={materials_list}
                        building="building1"
                        changeHandler={handleMaterialChange}
                      />
                      <MaterialButton
                        project={project}
                        category="Horizontal structure"
                        materials={materials}
                        materials_list={materials_list}
                        building="building1"
                        changeHandler={handleMaterialChange}
                      />
                      <MaterialButton
                        project={project}
                        category="Insulation"
                        materials={materials}
                        materials_list={materials_list}
                        building="building1"
                        changeHandler={handleMaterialChange}
                      />
                      <MaterialButton
                        project={project}
                        category="Facade"
                        materials={materials}
                        materials_list={materials_list}
                        building="building1"
                        changeHandler={handleMaterialChange}
                      />
                      <MaterialButton
                        project={project}
                        category="Floor"
                        materials={materials}
                        materials_list={materials_list}
                        building="building1"
                        changeHandler={handleMaterialChange}
                      />
                      <MaterialButton
                        project={project}
                        category="Roof"
                        materials={materials}
                        materials_list={materials_list}
                        building="building1"
                        changeHandler={handleMaterialChange}
                      />
                    </div>
                  </div>
                </Fragment>
              )}
            </div>

            <div className="RightBordered" />

            <div className="ProjectContentCol">
              {display_graphs ? (
                <GraphView
                  project={project}
                  results={results}
                  building="building2"
                  unit={calculation_unit}
                  side="right"
                  materials_list={materials_list}
                />
              ) : (
                <Fragment>
                  <BuildingMaterialsView
                    key={project.id}
                    project={project}
                    materials_list={materials_list}
                    option="Option B"
                  />
                  <div className="ProjectSelectionContainer VerticalPadded">
                    <div className="ButtonCollection">
                      <MaterialButton
                        project={project}
                        category="Foundation"
                        materials={materials}
                        materials_list={materials_list}
                        building="building2"
                        changeHandler={handleMaterialChange}
                      />
                      <MaterialButton
                        project={project}
                        category="Vertical structure"
                        materials={materials}
                        materials_list={materials_list}
                        building="building2"
                        changeHandler={handleMaterialChange}
                      />
                      <MaterialButton
                        project={project}
                        category="Horizontal structure"
                        materials={materials}
                        materials_list={materials_list}
                        building="building2"
                        changeHandler={handleMaterialChange}
                      />
                      <MaterialButton
                        project={project}
                        category="Insulation"
                        materials={materials}
                        materials_list={materials_list}
                        building="building2"
                        changeHandler={handleMaterialChange}
                      />
                      <MaterialButton
                        project={project}
                        category="Facade"
                        materials={materials}
                        materials_list={materials_list}
                        building="building2"
                        changeHandler={handleMaterialChange}
                      />
                      <MaterialButton
                        project={project}
                        category="Floor"
                        materials={materials}
                        materials_list={materials_list}
                        building="building2"
                        changeHandler={handleMaterialChange}
                      />
                      <MaterialButton
                        project={project}
                        category="Roof"
                        materials={materials}
                        materials_list={materials_list}
                        building="building2"
                        changeHandler={handleMaterialChange}
                      />
                    </div>
                  </div>
                </Fragment>
              )}
            </div>
          </div>

          {/* FOOTER */}
          <div className="ProjectInfoContainer">
            <MaterialInfoContainer results={results} />
          </div>
        </Fragment>
      )}
    </ProjectConsumer>
  );
}

export default ComparisonView;

import React from "react";
import { Popup } from "semantic-ui-react";

import { material_types_dict, material_types } from "../ProjectSettings";

import R1_plants from "../img/MaterialTypes/R1_plant_icon.png";
import R2_soil from "../img/MaterialTypes/R2_soil_icon.png";
import R3_minerals from "../img/MaterialTypes/R3_minerals_icon.png";
import R4_metals from "../img/MaterialTypes/R4_metals_icon.png";
import R5_oil from "../img/MaterialTypes/R5_oil_icon.png";
import R6_glass from "../img/MaterialTypes/R6_glass_icon.png";
import R7_mixed from "../img/MaterialTypes/R7_mixed_icon.png";

export default function HeaderResult(props) {
  const { results, unit, building } = props;
  const building_result = get_top3(results[building], unit);
  return (
    <div className="building-results-col">
      {building_result.map((result, index) => (
        <Popup
          key={index}
          position="top center"
          size="tiny"
          style={{
            backgroundColor: `RGB(${result.color.R},${result.color.G},${result.color.B})`,
            borderRadius: 0,
            whiteSpace: "nowrap",
            borderStyle: "none",
            padding: 8,
            "&::before": {
              background: `RGB(${result.color.R},${result.color.G},${result.color.B})`,
            },
          }}
          trigger={
            <div className="building-results-row">
              <div className="building-results-cell HorizontalEndAligned">
                <img
                  className="building-results-icon"
                  src={result.icon}
                  alt="icon"
                />
              </div>
              <div className="building-results-cell">{result.value}%</div>
            </div>
          }
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={result.icon}
              alt={result.icon}
              style={{ maxHeight: 25, paddingRight: 10 }}
            />
            <strong>
              {result.value}% {result.name} based
            </strong>
          </div>
        </Popup>
      ))}
    </div>
  );
}

const get_top3 = (results, unit) => {
  let types = results.type;
  let reversed_types = ReverseDict(material_types_dict);
  let out_list = [];
  let sum_value = unit ? results.GWP.EMI : results.PENRT;

  for (let [key, value] of Object.entries(types)) {
    // Get color
    let type_color = material_types[reversed_types[key]].colour;
    let type_color_obj = {
      R: Math.round(type_color.r * 255),
      G: Math.round(type_color.g * 255),
      B: Math.round(type_color.b * 255),
    };

    let type_object = {
      name: key,
      value: unit
        ? Math.round((100 * value.GWP.EMI) / sum_value)
        : Math.round((100 * value.PENRT) / sum_value),
      icon: iconsDict(key),
      color: type_color_obj,
    };
    out_list.push(type_object);
  }

  let sorted_list = out_list.sort((a, b) => (a.value < b.value ? 1 : -1));
  let sliced_list = sorted_list.slice(0, 3);

  return sliced_list;
};

const ReverseDict = (obj) => {
  const ret = {};
  Object.keys(obj).forEach((key) => {
    ret[obj[key]] = key;
  });
  return ret;
};

const iconsDict = (type) => {
  switch (type) {
    case "Glass":
      return R6_glass;
    case "Metals":
      return R4_metals;
    case "Minerals":
      return R3_minerals;
    case "Mix":
      return R7_mixed;
    case "Oils":
      return R5_oil;
    case "Plants":
      return R1_plants;
    case "Soil":
      return R2_soil;
    default:
      return;
  }
};

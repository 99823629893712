import React, { Fragment } from 'react';
import { ProjectConsumer } from '../ProjectContext';

// Settings
import { ProjectSettings } from '../ProjectSettings';

// 3D
import BuildingSizeView from '../Geometry/Views/BuildingSizeView';

// Containers
import DescriptionFooter from './Common/DescriptionFooter';
import InputSlider from './Common/InputSlider';

// Logos
import Slider_depth from '../img/Sliders/Slider_depth.png';
import Slider_floors from '../img/Sliders/Slider_floors.png';
import Slider_height from '../img/Sliders/Slider_height.png';
import Slider_width from '../img/Sliders/Slider_width.png';

function DimensionsView() {
    return (
        <ProjectConsumer>
            {({ project, handleSliderChange }) => (

                <Fragment>

                    {/* View head */}
                    <div className="ProjectHeadContainer">
                        <div className="TextContainer">
                            <div className="Title"><strong>Dimensions</strong></div>
                        </div>
                    </div>

                    {/* View Content */}
                    <div className="ProjectContentContainer">

                        {/* View Geometry */}
                        <div className="Col">
                            <BuildingSizeView project={project} />
                        </div>

                        {/* View Tooltip */}
                        <div className="ProjectTooltipContainer">Move sliders to define the building’s dimensions</div>

                        {/* View Selection */}
                        <div className="ProjectSelectionContainer">
                            <div className="ProjectSelectionPadding">
                                <div className="ProjectSelectionCol">

                                    <div className="ProjectSelectionRow VerticalEndAligned">
                                        <InputSlider
                                            icon={Slider_width}
                                            unit="m"
                                            value={project.width}
                                            min={ProjectSettings.sliders.buildingWidthMin}
                                            max={ProjectSettings.sliders.buildingWidthMax}
                                            changeHandler={handleSliderChange}
                                            propName="width"
                                        />
                                    </div>

                                    <div className="ProjectSelectionRow VerticalStartAligned">
                                        <InputSlider
                                            icon={Slider_depth}
                                            unit="m"
                                            value={project.depth}
                                            min={ProjectSettings.sliders.buildingDepthMin}
                                            max={ProjectSettings.sliders.buildingDepthMax}
                                            changeHandler={handleSliderChange}
                                            propName="depth"
                                        />
                                    </div>
                                </div>

                                <div className="ProjectSelectionCol">

                                    <div className="ProjectSelectionRow VerticalEndAligned">
                                        <InputSlider
                                            icon={Slider_height}
                                            unit="m"
                                            value={project.story_height}
                                            min={ProjectSettings.sliders.storyHeightMin}
                                            max={ProjectSettings.sliders.storyHeightMax}
                                            changeHandler={handleSliderChange}
                                            propName="story_height"
                                            step={0.1}
                                        />
                                    </div>

                                    <div className="ProjectSelectionRow VerticalStartAligned">
                                        <InputSlider
                                            icon={Slider_floors}
                                            unit="x"
                                            value={project.stories}
                                            min={ProjectSettings.sliders.storiesMin}
                                            max={ProjectSettings.sliders.storiesMax}
                                            changeHandler={handleSliderChange}
                                            propName="stories"
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                    {/* View Footer */}
                    <DescriptionFooter title="Building dimensions" description="To estimate the overall volume of materials in a preliminary design stage, an abstract building mass model is defined. The developed building volume is to be seen as a rough building mass, instead of a preliminary design. A more complex building design should be abstracted to this compact building mass, for the purpose of analyzing the environmental impact of structural and material choices. However, it should be kept in mind that a more complex building volume may have an impact on the energy efficiency of the building as well as on the materials that are applicable to choose from." />
                </Fragment>
            )}
        </ProjectConsumer>
    )
}

export default DimensionsView;
import React from 'react';
import Back from '../../img/UI_Misc/Back.png';
import Next from '../../img/UI_Misc/Next.png';
import { ProjectConsumer } from '../../ProjectContext';


export default function DescriptionFooter({ title, description }) {
    return (
        <ProjectConsumer>
            {({ activeViewIndex, setViewIndex }) => (

                <div className="ProjectInfoContainer">
                    <div className="NavigationCol Left">
                        <img className="NavigationButton" src={Back} alt="Back" onClick={() => setViewIndex(activeViewIndex - 1)} />
                    </div>
                    <div className="NavigationInfoCol RowDirection">
                        <div className="InfoIcon">i</div>
                        <div className="Col">
                            <div className="Title">{title}</div>
                            <div className="Description">{description}</div>
                        </div>
                    </div>
                    <div className="NavigationCol Right">
                        <img className="NavigationButton" src={Next} alt="Next" onClick={() => setViewIndex(activeViewIndex + 1)} />
                    </div>
                </div>

            )}
        </ProjectConsumer>
    )
}

import React, { useState } from "react";
import { AuthConsumer } from "../../Authentication/AuthContext";
import ContentHeader from "../Common/ContentHeader";

import { Form, Button, Message, Checkbox } from "semantic-ui-react";
import { Fragment } from "react";

export default function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [mailingList, setMailingList] = useState(true);
  const [tosChecked, setTosChecked] = useState(false);

  const ResetValues = () => {
    setEmail("");
    setPassword("");
    setPassword2("");
  };

  return (
    <AuthConsumer>
      {({ Register, registrationMessage }) => (
        <Fragment>
          <ContentHeader text="Register" />
          <div className="PageForm">
            <div className="FormContainer">
              {/* Error row */}
              {registrationMessage ? (
                <Message hidden={registrationMessage === null}>
                  <Message.Header>{registrationMessage.type}</Message.Header>
                  <p>{registrationMessage.message}</p>
                </Message>
              ) : null}

              <Form>
                <Form.Field>
                  <label>Email</label>
                  <input
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Password</label>
                  <input
                    placeholder="Password"
                    type="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Password (again)</label>
                  <input
                    placeholder="Password (again)"
                    type="Password"
                    value={password2}
                    onChange={(e) => setPassword2(e.target.value)}
                  />
                </Form.Field>

                <Form.Field>
                  <Checkbox
                    checked={tosChecked}
                    onClick={() => setTosChecked(!tosChecked)}
                    label={
                      <label>
                        I accept the{" "}
                        <a href="/TOS" target="_blank">
                          Terms of Use
                        </a>
                      </label>
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    checked={mailingList}
                    onChange={() => setMailingList(!mailingList)}
                    label="Signup for the mailing list"
                  />
                </Form.Field>

                <Form.Field>
                  <Button
                    fluid
                    disabled={!tosChecked}
                    color="facebook"
                    content="Register"
                    onClick={() =>
                      Register(
                        email,
                        password,
                        password2,
                        mailingList,
                        (response) => {
                          ResetValues();
                        }
                      )
                    }
                  />
                </Form.Field>

                {/* Submit row */}
                <div className="Row PaddedTop PaddedBottom">
                  <div>
                    Already have an account? <a href="/login">Click here</a> to
                    login
                  </div>
                </div>

                <div className="Row PaddedTop PaddedBottom">
                  <p>
                    View our Privacy Policy <a href="/privacypolicy">here</a>
                  </p>
                </div>
              </Form>
            </div>
          </div>
        </Fragment>
      )}
    </AuthConsumer>
  );
}

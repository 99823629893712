import React from 'react';

const ContentHeader = ({ text }) => {
    return (
        <div className="ContentHeader White">
            <div className="TextContainer">
                <div className="Title">
                    <strong>{text}</strong>
                </div>
            </div>
        </div>
    )
}

export default ContentHeader;
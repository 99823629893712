import React, { Fragment } from "react";
import { ProjectConsumer } from "../ProjectContext";

// 3D
import BuildingConstructionView from "../Geometry/Views/BuildingConstructionView";

import DescriptionFooter from "./Common/DescriptionFooter";
import c1 from "../img/Constructions/c1.png";
import c2 from "../img/Constructions/c2.png";
import c3 from "../img/Constructions/c3.png";

function ConstructionView() {
  return (
    <ProjectConsumer>
      {({ project, handleConstructionChange }) => (
        <Fragment>
          <div className="ProjectHeadContainer">
            <div className="TextContainer">
              <div className="Title">
                <strong>Construction</strong>
              </div>
            </div>
          </div>

          <div className="ProjectContentContainer">
            <BuildingConstructionView project={project} />

            <div className="ProjectTooltipContainer">
              Choose the building’s construction type
            </div>

            <div className="ProjectSelectionContainer VerticalPadded">
              <div className="ButtonCollection">
                <img
                  className="SelectionButton HoverButton"
                  src={c1}
                  alt={c1}
                  onClick={() => handleConstructionChange(0)}
                />
                <img
                  className="SelectionButton HoverButton"
                  src={c2}
                  alt={c2}
                  onClick={() => handleConstructionChange(1)}
                />
                <img
                  className="SelectionButton HoverButton"
                  src={c3}
                  alt={c3}
                  onClick={() => handleConstructionChange(2)}
                />
              </div>
            </div>
          </div>

          <DescriptionFooter
            title="Construction method"
            description="To estimate the mass of structural materials in the building, the construction type needs to be defined. This is crucial, since the structure and foundation usually account for a large share of the building's volume and therefore contribute heavily to the overall environmental sustainability. Based on the selected construction type, the mass of structural materials are determined."
          />
        </Fragment>
      )}
    </ProjectConsumer>
  );
}

export default ConstructionView;

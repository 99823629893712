import React, { Component, createContext } from "react";
import { withRouter } from "react-router-dom";
import { AuthConsumer } from "../Authentication/AuthContext";

class _ProjectProvider extends Component {
  constructor() {
    super();
    this.state = {
      // App state
      projectIsLoading: true,
      activeViewIndex: 0,
      calculation_unit: true, // Determines which unit to display (true=GWP, false=PENRT)
      display_graphs: false, // Determines whether to display graphs in comparison view
      active_graph_index: 0, // Determines which graph to display in comparison view
      save_interval: null,
      guideIndex: -1, //
    };
  }

  componentDidMount() {
    // Get active view index from location
    let activeViewIndex = this.props.location.state
      ? this.props.location.state.view
      : 6;
    this.setState({ activeViewIndex: activeViewIndex });
  }

  setViewIndex = (index) => {
    this.setState({ activeViewIndex: index });
  };

  setViewState = (prop, value) => {
    this.setState({ [prop]: value });
  };

  setGuideIndex = (index) => {
    this.setState({ guideIndex: index });
  };

  render() {
    return (
      <AuthConsumer>
        {({
          project,
          materials,
          materials_list,
          latest_selection,
          PartialUpdateProject,
          handleSliderChange,
          handleConstructionChange,
          handleMaterialChange,
          SaveProject,
        }) => (
          <ProjectContext.Provider
            value={{
              // View state
              activeViewIndex: this.state.activeViewIndex,
              calculation_unit: this.state.calculation_unit,
              display_graphs: this.state.display_graphs,
              active_graph_index: this.state.active_graph_index,
              guideIndex: this.state.guideIndex,
              setViewIndex: this.setViewIndex,
              setViewState: this.setViewState,
              setGuideIndex: this.setGuideIndex,

              // Inherited
              project: project,
              materials: materials,
              materials_list: materials_list,
              latest_selection: latest_selection,
              PartialUpdateProject: PartialUpdateProject,
              handleSliderChange: handleSliderChange,
              handleConstructionChange: handleConstructionChange,
              handleMaterialChange: handleMaterialChange,
            }}
          >
            {this.props.children}
          </ProjectContext.Provider>
        )}
      </AuthConsumer>
    );
  }
}

export const ProjectProvider = withRouter(_ProjectProvider);
export const ProjectContext = createContext();
export const ProjectConsumer = ProjectContext.Consumer;

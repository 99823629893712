import React, { useState } from 'react';
import { AuthConsumer } from '../../AuthContext';

// Semantic UI
import { Button, Modal, Form } from 'semantic-ui-react';

export default function ChangePasswordModal() {
    const [is_open, set_open] = useState(false);
    const [old_password, set_old_password] = useState("");
    const [new_password, set_new_password] = useState("");
    const [new_password_2, set_new_password_2] = useState("");

    return (
        <AuthConsumer>
            {({ user, ChangeUserPassword }) => (
                <Modal
                    open={is_open}
                    onClose={() => set_open(false)}
                    closeIcon
                    trigger={<button className="Button" onClick={() => set_open(true)}>Change</button>}
                >
                    <Modal.Header content="Reset password" />

                    <Modal.Content>
                        <Modal.Description>
                            <Form>
                                <Form.Field>
                                    <label>Old password</label>
                                    <input
                                        type="password"
                                        placeholder="Old password"
                                        value={old_password}
                                        onChange={(e) => set_old_password(e.target.value)}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>New password</label>
                                    <input
                                        type="password"
                                        placeholder="New password"
                                        value={new_password}
                                        onChange={(e) => set_new_password(e.target.value)}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>New password (again)</label>
                                    <input
                                        type="password"
                                        placeholder="New password (again)"
                                        value={new_password_2}
                                        onChange={(e) => set_new_password_2(e.target.value)}
                                    />
                                </Form.Field>
                            </Form>
                        </Modal.Description>
                    </Modal.Content>

                    <Modal.Actions>
                        <Button
                            content="Save"
                            positive
                            onClick={() => {
                                set_open(false)
                                ChangeUserPassword(old_password, new_password, new_password_2)
                            }}
                        />
                    </Modal.Actions>


                </Modal>
            )}
        </AuthConsumer>
    )
}

import firebase from "firebase";
import "firebase/analytics";

const firebaseSettings = {
  apiKey: "AIzaSyAQTX9g0ikEpBpIK2VIDM3ZxMmedKltNOc",
  authDomain: "jokotai-9f7b6.firebaseapp.com",
  projectId: "jokotai-9f7b6",
  storageBucket: "jokotai-9f7b6.appspot.com",
  messagingSenderId: "103057369787",

  // Production
  //appId: "1:103057369787:web:647030a4ec3911388f579e",
  //measurementId: "G-M3LBGKKXNY"

  // Development
  appId: "1:103057369787:web:c7bd1338f151bda88f579e",
  measurementId: "G-S5CN1QHNZ1",
};

const firebaseApp = firebase.initializeApp(firebaseSettings);

export const firestore = firebaseApp.firestore();
export const firebaseAuth = firebaseApp.auth();

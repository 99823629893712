import React, { PureComponent } from 'react'
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer, LabelList } from 'recharts';

import { building_part_dict, material_types } from '../ProjectSettings';

import B1_foundation from '../img/BuildingParts/B1_foundation.png';
//import B2_structure from '../img/BuildingParts/B2_structure.png';
import B2_horizontal from '../img/BuildingParts/B2a_horizontal.png';
import B2_vertical from '../img/BuildingParts/B2b_vertical.png';
import B3_minerals from '../img/BuildingParts/B3_insulation.png';
import B4_facade from '../img/BuildingParts/B4_facade.png';
import B5_floor from '../img/BuildingParts/B5_floor.png';
import B6_roof from '../img/BuildingParts/B6_roof.png';

export default class BuildingPartsGraph extends PureComponent {
    render() {
        const { project, results, building, side, unit, materials_list } = this.props;
        const data = dictToList(project, results, building, unit, side, materials_list);
        return (
            <ResponsiveContainer height='100%' width='100%' minWidth={850} minHeight={500} >
                <BarChart data={data} barCategoryGap={'8%'} margin={{ top: 100, right: this.props.side === "left" ? 100 : 200, left: this.props.side === "left" ? 200 : 100, bottom: 200, }}>
                    <CartesianGrid strokeDasharray="3 3" vertical={false} />
                    <XAxis dataKey="name" tick={<CustomAxisLabel data={data} />} axisLine={false} tickLine={false} interval={0}/>
                    <YAxis unit="%" orientation={this.props.side === "left" ? "right" : "left"} axisLine={true} tickLine={false} domain={[0, 80]} fontSize={12} />
                    <Legend content={<div style={{ transform: `translate(${this.props.side === "left" ? 50 : -50}px,${70}px)`, fontSize: 15 }}>{this.props.unit ? <React.Fragment>CO2e kg/m2</React.Fragment> : <React.Fragment>MJ/m2</React.Fragment>}</div>} align={this.props.side === "left" ? "right" : "left"} />
                    <Bar dataKey="percentage" name={this.props.unit ? "GWP" : "PENRT"}>
                        {
                            data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.color} />
                            ))
                        }
                        <LabelList dataKey="percentage" content={CustomizedListLabel} />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        )
    }
}

class CustomAxisLabel extends PureComponent {
    render() {
        const { x, y, payload, width } = this.props;
        const barWidth = width / 7;
        return (
            <g transform={`translate(${x},${y})`}>
                <image y={10} href={iconsDict(payload.value)} width={(barWidth)} transform={"translate(-" + (barWidth / 2).toString() + ",-20)"} ></image>
                <text x={0} y={0} dy={110} textAnchor="middle" fontSize="30px">{Math.round(this.props.data[payload.index].numericValue)}</text>
            </g>
        )
    }
}

const CustomizedListLabel = (props) => {
    const { x, y, width, value } = props;
    const offset = 8;
  
    return (
      <g>
        <text x={x + width / 2} y={y - offset} fill="#000" textAnchor="middle" dominantBaseline="middle" fontSize="10px" fontWeight="bold" >
          {value + '%'}
        </text>
      </g>
    );
  };

const dictToList = (project, results, building, unit, side, materials_list) => {
    let percentageList = []
    let sumValue = unit ? results[building].GWP.EMI : results[building].PENRT;
    let parts = results[building].parts;

    // Calculate item value
    for (let [key, value] of Object.entries(parts)) {
        //let reversed_types = ReverseDict(material_types_dict);

        // Find the associated material in the project dictionary
        let suffix = building === "building1" ? "_a" : "_b";
        let prop_name = building_part_dict[key] + suffix;
        let material_id = project[prop_name];
        let material = materials_list[material_id];
        let material_type = material.material_type;
        let type_color = material_types[material_type].colour;

        let type_color_hex = rgbToHex(Math.round(type_color.r * 255), Math.round(type_color.g * 255), Math.round(type_color.b * 255));
        let part_impact = unit ? value.GWP.EMI : value.PENRT;

        let newObj = { name: key, percentage: Math.round((part_impact / sumValue) * 100), numericValue: part_impact, color: type_color_hex };
        percentageList.push(newObj);
    };

    if (side === "left") {
        percentageList.sort((a, b) => (a.percentage > b.percentage) ? 1 : ((b.percentage > a.percentage) ? -1 : 0));
    } else {
        percentageList.sort((a, b) => (a.percentage < b.percentage) ? 1 : ((b.percentage < a.percentage) ? -1 : 0));
    }
    return percentageList;
};

/*
const ReverseDict = (obj) => {
    const ret = {};
    Object.keys(obj).forEach(key => {
        ret[obj[key]] = key;
    });
    return ret;
}
*/

function componentToHex(c) {
    var hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
}

function rgbToHex(r, g, b) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

const iconsDict = (category) => {
    switch (category) {
        case "Foundation":
            return B1_foundation;
        case "Horizontal structure":
            return B2_horizontal;
        case "Vertical structure":
            return B2_vertical;
        case "Insulation":
            return B3_minerals;
        case "Facade":
            return B4_facade;
        case "Floor":
            return B5_floor;
        case "Roof":
            return B6_roof;
        default:
            return;
    }
}
import React from "react";
import "./MobileRestriction.css";

const MobileRestriction = () => (
  <div className="mobile-restriction">
    <p>The content of this page is only available for larger screen devices</p>
  </div>
);

export default MobileRestriction;

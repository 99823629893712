import React, { PureComponent } from 'react'
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer, LabelList } from 'recharts';

import { material_types_dict, material_types } from '../ProjectSettings';

import R1_plants from '../img/MaterialTypes/R1_plants.png';
import R2_soil from '../img/MaterialTypes/R2_soil.png';
import R3_minerals from '../img/MaterialTypes/R3_minerals.png';
import R4_metals from '../img/MaterialTypes/R4_metals.png';
import R5_oil from '../img/MaterialTypes/R5_oil.png';
import R6_glass from '../img/MaterialTypes/R6_glass.png';
import R7_mixed from '../img/MaterialTypes/R7_mixed.png';

export default class MaterialTypeGraph extends PureComponent {
    render() {
        const { results, building, side, unit } = this.props;
        const data = dictToList(results, building, unit, side);
        return (
            <ResponsiveContainer height='100%' width='100%' minWidth={850} minHeight={500}>
                <BarChart data={data} barCategoryGap={'8%'} margin={{ top: 100, right: this.props.side === "left" ? 100 : 200, left: this.props.side === "left" ? 200 : 100, bottom: 200, }}>
                    <CartesianGrid strokeDasharray="3 3" vertical={false} />
                    <XAxis dataKey="name" tick={<CustomAxisLabel data={data} />} axisLine={false} tickLine={false} interval={0} />
                    <YAxis unit="%" orientation={this.props.side === "left" ? "right" : "left"} axisLine={true} tickLine={false} domain={[0, 80]} fontSize={12} />
                    
                    <Legend content={<div style={{ transform: `translate(${this.props.side === "left" ? 50 : -50}px,${70}px)`, fontSize: 15 }}>{this.props.unit ? <React.Fragment>CO2e kg/m2</React.Fragment> : <React.Fragment>MJ/m2</React.Fragment>}</div>} align={this.props.side === "left" ? "right" : "left"} verticalAlign="bottom" />
                    <Bar dataKey="percentage" fill="#8884d8" name={this.props.unit ? "GWP" : "PENRT"} >
                        {
                            data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.color} />
                            ))
                        }
                        <LabelList dataKey="percentage" content={CustomizedListLabel} />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        )
    }
}

class CustomAxisLabel extends PureComponent {
    render() {
        const { x, y, payload, width } = this.props;
        const barWidth = width / 7;                    
        return (
            <g transform={`translate(${x},${y})`}>
                <image y={10} href={iconsDict(payload.value)} width={(barWidth)} transform={"translate(-" + (barWidth / 2).toString() + ",-20)"} ></image>
                <text x={0} y={0} dy={110} textAnchor="middle" fontSize="30px">{Math.round(this.props.data[payload.index].numericValue)}</text>
            </g>
        )
    }
}

const CustomizedListLabel = (props) => {
    const { x, y, width, value } = props;
    const offset = 8;
  
    return (
      <g>
        <text x={x + width / 2} y={y - offset} fill="#000" textAnchor="middle" dominantBaseline="middle" fontSize="10px" fontWeight="bold" >
          {value + '%'}
        </text>
      </g>
    );
  };
const dictToList = (results, building, unit, side) => {
    let percentageList = []
    let sumValue = unit ? results[building].GWP.EMI : results[building].PENRT;
    let ressources = results[building].type;

    let reversed_types = ReverseDict(material_types_dict);

    // Calculate item value
    for (let [key, value] of Object.entries(ressources)) {
        let type_impact = unit ? value.GWP.EMI : value.PENRT;
        let type_color = material_types[reversed_types[key]].colour;
        let type_color_hex = rgbToHex(Math.round(type_color.r * 255), Math.round(type_color.g * 255), Math.round(type_color.b * 255));

        let newObj = { name: key, percentage: Math.round((type_impact / sumValue) * 100), numericValue: type_impact, color: type_color_hex };
        percentageList.push(newObj);
    };

    // Sort list by value and direction
    if (side === "left") {
        percentageList.sort((a, b) => (a.percentage > b.percentage) ? 1 : ((b.percentage > a.percentage) ? -1 : 0));
    } else {
        percentageList.sort((a, b) => (a.percentage < b.percentage) ? 1 : ((b.percentage < a.percentage) ? -1 : 0));
    }

    return percentageList;
};

const ReverseDict = (obj) => {
    const ret = {};
    Object.keys(obj).forEach(key => {
        ret[obj[key]] = key;
    });
    return ret;
}

function componentToHex(c) {
    var hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
}

function rgbToHex(r, g, b) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

const iconsDict = (type) => {
    switch (type) {
        case "Glass":
            return R6_glass;
        case "Metals":
            return R4_metals;
        case "Minerals":
            return R3_minerals;
        case "Mix":
            return R7_mixed;
        case "Oils":
            return R5_oil;
        case "Plants":
            return R1_plants;
        case "Soil":
            return R2_soil;
        default:
            return;
    }
}

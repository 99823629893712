import React from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

// Project View
import ProjectView from "./Project/ProjectView";

// Auth Views
import Login from "./Layout/Pages/Login";
import RecoverPasswordPage from "./Authentication/Views/RecoverPasswordPage";
import Register from "./Layout/Pages/Register";
import ProjectList from "./Project/ProjectList";
import ProfilePage from "./Authentication/Views/ProfilePage";

// Static Pages
import HomePage from "./Layout/Pages/HomePage/HomePage";
import AboutPage from "./Layout/Pages/AboutPage";
import ResearchPage from "./Layout/Pages/ResearchPage";
import VisionPage from "./Layout/Pages/VisionPage";
import PrivacyPolicyPage from "./Layout/Pages/PrivacyPolicyPage";
import TOSPage from "./Layout/Pages/TOSPage";
import FaqPage from "./Layout/Pages/FaqPage/FaqPage";
import PageNotFound from "./Templates/PageNotFound";

const BaseRouter = () => (
  <Switch>
    {/* Static Pages */}
    <Route exact path="/FAQ" component={FaqPage} />
    <Route exact path="/TOS" component={TOSPage} />
    <Route exact path="/PrivacyPolicy" component={PrivacyPolicyPage} />
    <Route exact path="/vision" component={VisionPage} />
    <Route exact path="/research" component={ResearchPage} />
    <Route exact path="/about" component={AboutPage} />

    {/* Auth Views */}
    <Route exact path="/register" component={Register} />
    <Route exact path="/login" component={Login} />
    <Route exact path="/recover" component={RecoverPasswordPage} />
    <PrivateRoute
      exact
      path="/profile"
      requireConfirmation={false}
      component={ProfilePage}
    />

    {/* Project View */}
    <PrivateRoute
      exact
      path="/projects"
      requireConfirmation={true}
      component={ProjectList}
    />
    <PrivateRoute
      exact
      path="/project"
      requireConfirmation={true}
      component={ProjectView}
    />

    <Route exact path="/" component={HomePage} />
    <Route path="" component={PageNotFound} />
  </Switch>
);

export default BaseRouter;

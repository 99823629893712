import React from "react";
import { Redirect, Route } from "react-router-dom";
import { AuthConsumer } from './Authentication/AuthContext';

import ConfirmEmail from './Templates/ConfirmEmail';

export const PrivateRoute = ({ component: Component, requireConfirmation = false, ...rest }) => {
    return (
        <AuthConsumer>
            {({ isAuth, user }) => (
                <Route {...rest} render={props =>
                    isAuth ?
                        requireConfirmation && !user.emailVerified ?
                            <ConfirmEmail />
                            :
                            <Component {...props} />
                        :
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: props.location }
                            }}
                        />
                }
                />
            )}
        </AuthConsumer>
    )
}


export default PrivateRoute;
import React, { Fragment } from "react";
import { AuthConsumer } from "../../../Authentication/AuthContext";

import "./HomePage.css";
import { Button } from "semantic-ui-react";
import Intro_Graphic from "../../../static/IntroPage/Intro_Graphic.png";

const HomePage = () => {
  return (
    <AuthConsumer>
      {({ isAuth, RedirectToCreateNewProject }) => (
        <Fragment>
          {/* Content Header */}
          <div className="ContentHeader White">
            <div className="TextContainer">
              <div className="Title">
                <strong>OOPEAA</strong> JOKOTAI
              </div>
              <div className="SubTitle">Building Material Impact Screener</div>
            </div>
          </div>

          {/* Content */}
          <div className="ContentContainer">
            <div className="ContentRow">
              <p>
                OOPEAA JOKOTAI is a tool for <strong>screening</strong> the
                environmental impact of structural and material choices on the
                sustainability of a building project. The tool enables
                architects to quickly sketch the{" "}
                <strong>building volume</strong> of a preliminary design and
                compare the environmental impact of alternative material choices
                to support the early decision making process.
              </p>
            </div>

            <div className="ContentRow Centered">
              <img
                className="intro-graphic"
                src={Intro_Graphic}
                alt="Intro Graphic"
              />
            </div>

            <div className="ContentRow">
              <p>
                The result breakdown identifies the most contributing material
                choices in terms of CO2 emissions and primary energy demand
                during production. The calculated environmental impact of the{" "}
                <strong>production phase (A1-3)</strong> is complemented by
                additional information about the expected service life,
                production process and recycling potential of materials.
              </p>
            </div>

            <div className="ContentRow Centered">
              {isAuth ? (
                <Button
                  size="large"
                  style={startButtonStyle}
                  content="New project"
                  onClick={() => RedirectToCreateNewProject()}
                />
              ) : (
                <Button size="large" href="/login" style={startButtonStyle}>
                  Login
                </Button>
              )}
            </div>
          </div>
        </Fragment>
      )}
    </AuthConsumer>
  );
};

const startButtonStyle = {
  backgroundColor: "white",
  color: "black",
  boxShadow: "2px 2px 1px 1px #ccc",
};

export default HomePage;

import { Vector3, Mesh } from 'babylonjs';
import { buildingParams } from '../../ProjectSettings';
import { CreateWallWithOpenings } from './Walls';

export const CreateFacade = (project, view, facadeMaterial, openingMaterial, onboarding = false, showEnd = true, showSide = true) => {
    // Init 
    let facade = new Mesh("facadeMesh", view.scene);
    let { width, depth, density, story_height, stories, openings } = project;
    let building_height = story_height * stories;

    let wallRatio = buildingParams.layout.ratio;
    let wallsX = Math.round(width / density) + 1;
    let wallsY = Math.round((width / density) / wallRatio) + 1;

    // Setup offset position
    let ratio = (onboarding ? buildingParams.onboarding.facadeOffsetRatio : buildingParams.construction.facadeOffsetRatio);
    let meanSide = (depth + width) / 2;
    let offset = meanSide * ratio;

    // Create end facade
    if (showEnd) {

        let endFacade = CreateWallWithOpenings(view,
            depth,
            building_height,
            buildingParams.construction.facadeThickness,
            wallsY,
            stories,
            story_height,
            openings,
            buildingParams.construction.openingHeight,
            facadeMaterial,
            openingMaterial);

        endFacade.position = new Vector3(0 + (width / 2) + offset, 0 + (building_height / 2), 0);
        endFacade.parent = facade;
    }

    // Create side facade
    if (showSide) {
        let sideFacade = CreateWallWithOpenings(view,
            width,
            building_height,
            buildingParams.construction.facadeThickness,
            wallsX,
            stories,
            story_height,
            openings,
            buildingParams.construction.openingHeight,
            facadeMaterial,
            openingMaterial);

        sideFacade.position = new Vector3(0, 0 + (building_height / 2), 0 + (depth / 2) + offset);
        sideFacade.rotation.y += 1.5707963268;
        sideFacade.parent = facade;
    }

    return facade;
}

import { buildingParams, colours } from '../../ProjectSettings';
import { Vector3, Mesh, MeshBuilder } from 'babylonjs';

// Creates a mesh of the vertical construction elements of type Modular, Element or Frame
export const CreateVerticalConstruction = (project, view, verticalMaterial) => {
    // Init params / constants
    const horizontalThickness = buildingParams.construction.horizontalThickness;
    const thickness = buildingParams.construction.verticalThickness;
    let { width, depth, stories, story_height, density } = project;

    // Colours 
    const grey95 = view.rgbToCol3(colours.grey95);
    const grey90 = view.rgbToCol3(colours.grey90);
    const grey85 = view.rgbToCol3(colours.grey85);

    // Calculation walls proportions
    let wallRatio = buildingParams.layout.ratio;
    let wallsX = Math.round(width / density);
    let wallsY = Math.round((width / density) / wallRatio);
    let roomWidth = width / wallsX;
    let roomDepth = depth / wallsY;

    // Base positions
    let base = new Vector3(0, 0, 0);
    base.x = 0 - width / 2;
    base.y = 0 + (buildingParams.construction.foundationThickness / 2) + (story_height / 2) + (horizontalThickness / 2);
    base.z = 0 - depth / 2;

    // Used to store meshes to be merged
    var meshes = [];

    // Create ground floor elements based on building type
    switch (project.construction_type) {
        case 0:         // Modular

            // Create 2 side walls for each room
            for (let x = 0; x < wallsX; x++) {
                for (let y = 0; y < wallsY; y++) {
                    let options = {
                        width: thickness,
                        height: story_height - (horizontalThickness) - (horizontalThickness / 2),
                        depth: roomDepth - thickness,
                        faceColors: [grey90, grey90, grey85, grey85, grey95, grey95]
                    };
                    let leftWall = MeshBuilder.CreateBox("leftModuleWall_" + x + "," + y, options, view.scene);
                    leftWall.position = new Vector3(base.x + thickness + (roomWidth * x), base.y - (horizontalThickness - (horizontalThickness / 4)), base.z + roomDepth / 2 + (roomDepth * y));
                    leftWall.material = verticalMaterial;
                    meshes.push(leftWall);
                    let rightWall = MeshBuilder.CreateBox("leftModuleWall_" + x + "," + y, options, view.scene);
                    rightWall.position = new Vector3(base.x + (roomWidth - thickness) + (roomWidth * x), base.y - (horizontalThickness - (horizontalThickness / 4)), base.z + roomDepth / 2 + (roomDepth * y));
                    rightWall.material = verticalMaterial;
                    meshes.push(rightWall);
                }
            }
            break;

        case 1:         // Element

            // Create X walls
            for (let x = 0; x <= wallsX; x++) {
                let options = {
                    width: thickness,
                    height: story_height - (horizontalThickness),
                    depth: depth,
                    faceColors: [grey90, grey90, grey85, grey85, grey95, grey95]
                };
                let wall = MeshBuilder.CreateBox("wall_X_" + x, options, view.scene);
                wall.position = new Vector3(base.x + (roomWidth * x), base.y, base.z + depth / 2);
                // Position tweaks for outer walls
                if (x === 0) wall.position.x += (thickness / 2)
                if (x === wallsX) wall.position.x -= (thickness / 2)

                wall.material = verticalMaterial;
                meshes.push(wall);
            }

            // Create Y walls
            for (let y = 1; y < wallsY; y++) {
                let options = {
                    width: width,
                    height: story_height - (horizontalThickness),
                    depth: thickness,
                    faceColors: [grey90, grey90, grey85, grey85, grey95, grey95]
                };
                let wall = MeshBuilder.CreateBox("wall_Y_" + y, options, view.scene);
                wall.position = new Vector3(base.x + (width / 2), base.y, base.z + roomDepth * y);
                if (y === 0) wall.position.z += (thickness / 2)
                if (y === wallsY) wall.position.z -= (thickness / 2)

                wall.material = verticalMaterial;
                meshes.push(wall);
            }

            break;

        case 2:         // Frame

            // Create X beams and columns
            for (let x = 0; x <= wallsX; x++) {
                // Create beam
                let options = {
                    width: thickness,
                    height: thickness,
                    depth: depth - thickness,
                    faceColors: [grey90, grey90, grey85, grey85, grey95, grey95]
                };
                let beam = MeshBuilder.CreateBox("beam_X_" + x, options, view.scene);
                beam.position = new Vector3(base.x + (roomWidth * x), base.y + (story_height / 2) - horizontalThickness, base.z + depth / 2);
                // Position tweaks for outer beams
                if (x === 0) beam.position.x += (thickness / 2)
                if (x === wallsX) beam.position.x -= (thickness / 2)

                beam.material = verticalMaterial;
                meshes.push(beam);

                // Create columns
                for (let y = 0; y <= wallsY; y++) {
                    let options = {
                        width: thickness,
                        height: story_height - horizontalThickness,
                        depth: thickness,
                        faceColors: [grey90, grey90, grey85, grey85, grey95, grey95]
                    };
                    let column = MeshBuilder.CreateBox("column_" + x + "," + y, options, view.scene);
                    column.position = new Vector3(base.x + (roomWidth * x), base.y, base.z + roomDepth * y);
                    // Position tweaks for outer columns
                    if (x === 0) column.position.x += (thickness / 2)
                    if (x === wallsX) column.position.x -= (thickness / 2)
                    if (y === 0) column.position.z += (thickness / 2)
                    if (y === wallsY) column.position.z -= (thickness / 2)

                    column.material = verticalMaterial;
                    meshes.push(column);
                }

            }

            // Create Y beams
            for (let y = 0; y <= wallsY; y++) {
                let options = {
                    width: width - thickness,
                    height: thickness,
                    depth: thickness,
                    faceColors: [grey90, grey90, grey85, grey85, grey95, grey95]
                };
                let beam = MeshBuilder.CreateBox("beam_Y_" + y, options, view.scene);
                beam.position = new Vector3(base.x + (width / 2), base.y + (story_height / 2) - horizontalThickness, base.z + roomDepth * y);
                // Position tweaks for outer beams
                if (y === 0) beam.position.z += (thickness / 2)
                if (y === wallsY) beam.position.z -= (thickness / 2)

                beam.material = verticalMaterial;
                meshes.push(beam);
            }
            break;

        default:        // Invalid construction index, draw nothing
            console.warn('Invalid construction index in CreateVerticleConstruction');

    }

    // Create ground story from smeshes
    let story0 = Mesh.MergeMeshes(meshes, true, true);
    story0.name = "story0";

    // Array of story meshes
    let storyMeshes = [story0];

    // Duplicate story0 for other stories and add to mesh array
    for (let storyIndex = 1; storyIndex < stories; storyIndex++) {
        let nextStory = story0.clone("story" + storyIndex.toString());
        nextStory.position.y += (story_height * storyIndex);
        storyMeshes.push(nextStory);
    }

    // Create a single vertical construction mesh and dispose of original meshes
    var verticalConstruction = Mesh.MergeMeshes(storyMeshes, true, true);
    verticalConstruction.name = "verticalContruction";

    return verticalConstruction;
}
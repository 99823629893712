import React from "react";

import "./PageNotFound.css";
import ContentHeader from "../../Layout/Common/ContentHeader";

const PageNotFound = () => (
  <div className="page-not-found">
    <ContentHeader text="Oops!" />
    <div className="ContentContainer">
      <div className="ContentRow">
        <p className="page-not-found-text">
          Page not found. Please check your address.
        </p>
      </div>
    </div>
  </div>
);

export default PageNotFound;

import React from "react";

import MsArchitect from "../../img/Graphs/MsArchitect.png";

const ImpactSaved = ({ project, unit, results }) => {
  return (
    <div className="impact-saved">
      <div className="title">Construction phases A1-3</div>

      <div className="divider"></div>
      <div className="calculation">
        {project.width * project.depth * project.stories} m2 x{" "}
        <strong>
          {unit
            ? Math.round(results.building1.GWP.EMI) + "kg"
            : Math.round(results.building1.PENRT) + "MJ"}
        </strong>{" "}
        vs{" "}
        <strong>
          {unit
            ? Math.round(results.building2.GWP.EMI) + "kg"
            : Math.round(results.building2.PENRT) + "MJ"}
        </strong>
      </div>
      <div className="divider"></div>

      <div className="result">
        {unit
          ? numberWithCommas(
              Math.abs(
                Math.round(results.building1.GWP.EMI) -
                  Math.round(results.building2.GWP.EMI)
              ) *
                (project.width * project.depth * project.stories)
            )
          : numberWithCommas(
              Math.abs(
                Math.round(results.building1.PENRT) -
                  Math.round(results.building2.PENRT)
              ) *
                (project.width * project.depth * project.stories)
            )}
      </div>

      <div className="result-description">
        <strong>{unit ? "Kg of CO2e" : "MJ"}</strong> saved with this option
      </div>

      <div className="result-info-container">
        <div className="result-info-icon-container">
          <img
            className="result-info-icon"
            src={MsArchitect}
            alt="architect"
          />
        </div>

        <div className="result-info-description">
          <strong>Architect</strong>, you are in a great position to make a
          difference by analyzing your design and choosing sustainable materials
          that produce both less CO2 emissions and require less primary energy
          during production. The extraction and material production (life cycle
          phase A1-3) often accounts for a large share of the environmental
          impact in a building´s lifetime. Rethinking structural and material
          choices already at the beginning of the design process and
          understanding the impact that the chosen materials will have on our
          planet during and after their lifetime, is an important part of
          sustainable architecture and can make a big difference.
        </div>
      </div>
    </div>
  );
};

export default ImpactSaved;

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

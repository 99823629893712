export const defaultProject = {
    width: 20,
    depth: 10,
    stories: 3,
    story_height: 2.5,
    openings: 50,
    density: 5,

    construction_type: 0,

    foundation_a: 32,
    floor_a: 3,
    vertical_a: 2,
    horizontal_a: 33,
    roof_a: 7,
    facade_a: 16,
    insulation_a: 12,
    openings_a: 31,

    foundation_b: 32,
    floor_b: 3,
    vertical_b: 2,
    horizontal_b: 33,
    roof_b: 7,
    facade_b: 16,
    insulation_b: 12,
    openings_b: 31,
/*
    // Sequential ID's 
    foundation_a: 6,
    floor_a: 14,
    vertical_a: 0,
    horizontal_a: 17,
    roof_a: 10,
    facade_a: 15,
    insulation_a: 12,
    openings_a: 5,

    foundation_b: 6,
    floor_b: 14,
    vertical_b: 0,
    horizontal_b: 17,
    roof_b: 10,
    facade_b: 15,
    insulation_b: 12,
    openings_b: 5,
*/
}



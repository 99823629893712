import React, { Fragment } from "react";
import "./ResearchPage.css";

import ContentHeader from "../../Common/ContentHeader";
import { Table } from "semantic-ui-react";
import img1 from "../../../static/ResearchPage/01_Building sector.jpg";
import img2 from "../../../static/ResearchPage/02_LCA.jpg";
import img3 from "../../../static/ResearchPage/03_Screening.jpg";
import img4 from "../../../static/ResearchPage/04_Operative Energy.jpg";
import img5 from "../../../static/ResearchPage/05_Production.jpg";
import img6 from "../../../static/ResearchPage/06_Infobox.png";
import img7 from "../../../static/ResearchPage/07_Environmental Indicators.jpg";

const ResearchPage = () => {
  return (
    <Fragment>
      <ContentHeader text="RESEARCH" />

      <div className="ContentContainer research-page">
        {sections.map((section) => (
          <div className="ContentRow" key={section.id}>
            <div className="TextBlock">
              <div className="RowHeader text-section-header">
                <span>{section.header}</span>
              </div>
              <div className="RowText text-section-content">
                <span>{section.content}</span>
              </div>
              {section.sources.map((source) => (
                <ul
                  className="TextList text-section-footer"
                  key={source.number}
                >
                  <li>
                    {source.number}: {source.source}
                  </li>
                </ul>
              ))}

              {section.img ? (
                <img
                  src={section.img}
                  alt={section.img}
                  style={{ maxWidth: "100%" }}
                />
              ) : null}
              {section.imgSources.length !== 0 ? (
                <div style={{ fontSize: 12 }}> Image Copyright:</div>
              ) : null}
              <ul style={{ padding: 0 }}>
                {section.imgSources.map((imgSource) => (
                  <div className="text-section-sources" key={imgSource.number}>
                    {imgSource.number}: {imgSource.text}
                  </div>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default ResearchPage;

const TableData = [
  {
    id: 0,
    part: "Foundation",
    materials: [{ name: "Reinforced Concrete", thickness: "300mm", id: 0 }],
  },
  {
    id: 1,
    part: "Vertical Structure",
    materials: [
      { name: "Reinforced Concrete", thickness: "200mm", id: 0 },
      { name: "CLT", thickness: "150mm", id: 1 },
      { name: "Solid Timber", thickness: "200mm", id: 2 },
      { name: "Rammed Earth", thickness: "300mm", id: 3 },
      { name: "Limestone", thickness: "200mm", id: 4 },
      { name: "Brick", thickness: "200mm", id: 5 },
      { name: "Steel", thickness: "HEB 200", id: 6 },
    ],
  },
  {
    id: 2,
    part: "Horizontal Structure",
    materials: [
      { name: "Reinforced Concrete", thickness: "200mm", id: 0 },
      { name: "CLT", thickness: "150mm", id: 1 },
    ],
  },
  {
    id: 3,
    part: "Insulation",
    materials: [
      { name: "Cork", thickness: "200mm", id: 0 },
      { name: "Expanded Polystyrene (EPS)", thickness: "200mm", id: 1 },
      { name: "Foamglass", thickness: "200mm", id: 2 },
      { name: "Woodfibre", thickness: "200mm", id: 3 },
      { name: "Cellulose", thickness: "200mm", id: 4 },
      { name: "Mineral Wool", thickness: "200mm", id: 5 },
    ],
  },
  {
    id: 4,
    part: "Facade",
    materials: [
      { name: "Brick", thickness: "70mm", id: 0 },
      { name: "Copper", thickness: "10mm", id: 1 },
      { name: "Concrete", thickness: "120mm", id: 2 },
      { name: "Glass Aluminium", thickness: "50mm", id: 3 },
      { name: "Lime Cement Render", thickness: "10mm", id: 4 },
      { name: "Timber", thickness: "50mm", id: 5 },
    ],
  },
  {
    id: 5,
    part: "Floor",
    materials: [
      { name: "Carpet", thickness: "10mm", id: 0 },
      { name: "Timber Parquet", thickness: "20mm", id: 1 },
      { name: "Ceramic Tiles", thickness: "10mm", id: 2 },
      { name: "Cement", thickness: "70mm", id: 3 },
      { name: "Laminate", thickness: "10mm", id: 4 },
      { name: "Laminate", thickness: "10mm", id: 5 },
    ],
  },
  {
    id: 6,
    part: "Roof",
    materials: [
      { name: "Clay Tiles", thickness: "40mm", id: 0 },
      { name: "Timber Shingles", thickness: "50mm", id: 1 },
      { name: "Green Roof", thickness: "300mm", id: 2 },
      { name: "Zinc", thickness: "10mm", id: 3 },
      { name: "Bitumen", thickness: "30mm", id: 4 },
    ],
  },
  {
    id: 7,
    part: "Glazing",
    materials: [{ name: "Double Glazing", thickness: "10mm", id: 0 }],
  },
];

const sections = [
  {
    id: 0,
    header: "Intro",
    content: (
      <div>
        <div>
          <strong>OOPEAA JOKOTAI</strong> is a tool for screening the
          environmental impact of structural and material choices on the
          sustainability of a building project. Because the extraction and
          production of building materials often accounts for the biggest share
          of a building’s total embodied impact, the screening focuses on
          analyzing the production phase (A1-A3) in a building's life cycle. The
          tool enables architects to quickly sketch the building volume of a
          preliminary design and to compare the environmental impact of
          alternative material choices to support the early decision making
          process. The result breakdown identifies the resource types, building
          parts and building materials that are most contributing in terms of
          CO2 emissions or primary energy demand during production.The
          calculated environmental impact of the production phase (A1-3) is
          complemented by additional information about the expected service
          life, production process and recycling potential of materials.
        </div>
        <br />
        <div>
          <strong>JOKOTAI</strong> is designed to communicate environmental
          impact of structural and material choices in an easily understandable
          and visual manner. It is targeted for the early stage design process
          to give guidance on the environmental impact before there is enough
          information for conducting a comprehensive life-cycle analysis. Think
          of the tool as a compass, not as a map.
        </div>
      </div>
    ),
    sources: [],
    img: img1,
    imgSources: [
      { number: 1, text: "Petr Kratochvil" },
      { number: 2, text: "Energy.gov" },
      { number: 3, text: "Alexandra Pugachevsky" },
      { number: 4, text: "Gennady Grachev" },
    ],
  },
  {
    id: 1,
    header: "Environmental impact of the building industry",
    content: (
      <div>
        <div>
          Since the discovery of fossil fuels followed by the industrial
          revolution, human activities on earth are estimated to have caused
          approximately 1.0°C of global warming above pre-industrial levels.
          Between 2030 and 2050 global warming is likely to reach 1.5°C or more,
          if it continues to increase at the current rate. [1] Climate change in
          the 21st Century is projected to have severe consequences for the
          environment and human health world-wide. [2] The urgency to take
          action becomes clear in the special report on global warming of 1.5°C,
          published by the intergovernmental panel on climate change (IPCC),
          which states that by 2030 all sectors world-wide need to undergo a
          dramatic change of direction towards a more sustainable and
          responsible use of resources in order to limit global warming to a
          maximum of 1.5°C. [1]
        </div>
        <br />
        <div>
          Hereby the building sector plays an important role. Currently it is
          the most resource intensive sector globally. In Europe buildings are
          responsible for 35% of greenhouse gas emissions, 42% of final energy
          consumption, 50% of extracted raw materials and 30% of generated
          waste. [3] The immense need for resources and the production of waste
          and emissions by the building sector imposes an enormous pressure on
          the environment. Therefore, architects carry an important
          responsibility in lowering the environmental impact of their projects.
          But in order to create buildings with a smaller environmental
          footprint, architects need to be able to understand all inputs
          (energy, resources) and outputs (emissions, waste) that are caused by
          their design decisions.
        </div>
      </div>
    ),
    sources: [
      {
        number: 1,
        source:
          "Allen, M.R., et al. (2018) Framing and Context. In: Global Warming of 1.5°C. An IPCC Special Report on the impacts of global warming of 1.5°C above pre-industrial levels and related global greenhouse gas emissions pathways, in the context of strengthening the global response to the threat of climate change, sustainable development and efforts to eradicate poverty",
      },
      {
        number: 2,
        source:
          "Füssel, H. -M. et al. (2017) Climate change, impacts and vulnerability in Europe 2016 - An indicator based report",
      },
      {
        number: 3,
        source:
          "Dimova, S. and Gervasio H. (2018) Model for Life Cycle Assessment (LCA) of buildings, Publications Office of the European Union",
      },
    ],
    img: img2,
    imgSources: [],
  },
  {
    id: 2,
    header: "Life Cycle Assessment (LCA)",
    content: (
      <div>
        Assessing environmental sustainability of buildings requires taking a
        look at the whole building life cycle - from material extraction to
        demolition. Life Cycle Assessment (LCA) used as a method to quantify the
        environmental impacts of buildings can give a valuable insight into all
        processes related to the construction, use and deconstruction of a
        building. It models the building´s technical life cycle and documents
        energy and material flows throughout the building´s lifetime, while
        assessing the associated environmental burdens.The building’s life cycle
        is divided into five stages which need to be dealt with: The production
        stage, construction process stage, use stage, the end-of-life stage and
        benefits and loads beyond the system boundary.The methodology of life
        cycle assessment is based on the international standards ISO 14040 and
        ISO 14044. In Europe LCA is included in the European standards on
        sustainable construction and further specified in the standards EN 15643
        series, EN 15978 and EN 15804. [1]
      </div>
    ),
    sources: [
      {
        number: 1,
        source:
          "Gantner, J. et al (2015) EeB Guide Guidance Document - Part B: Buildings - Operational guidance for Life Cycle Assessment studies of the Energy Efficiency Building Initiative",
      },
    ],
    img: img3,
    imgSources: [],
  },
  {
    id: 3,
    header: "EeB Guide",
    content: (
      <div>
        EeBGuide is a project funded by the European Commission for Research and
        Innovation and Environment. Since a full life cycle assessment can be
        complex and difficult to handle at an early stage of the project, the
        EeBGuide provides useful guidance for performing LCA and divides LCA for
        buildings in three study types: Screening, Simplified and Complete LCA.
        The JOKOTAI tool is oriented on the screening study method of LCA which
        serves for an initial overview of the environmental impacts of a
        building. With a screening LCA, it is not possible to retrieve detailed
        results on the environmental performance of a building or a product, and
        comparative assertions according to ISO 14044 cannot be based on it.
        Comparisons can be made internally, but these comparisons should not be
        published. This type of study yields an estimate of the environmental
        performance, which can be helpful in the early stages of design i.e. to
        identify environmental hotspots in a building. [1]
      </div>
    ),
    sources: [
      {
        number: 1,
        source:
          "Gantner, J. et al (2015) EeB Guide Guidance Document - Part B: Buildings - Operational guidance for Life Cycle Assessment studies of the Energy Efficiency Building Initiative",
      },
    ],
    img: img4,
    imgSources: [],
  },
  {
    id: 4,
    header: "Embodied and Operative Energy",
    content: (
      <div>
        A building's environmental impact can also be divided into embodied and
        operative emissions. Embodied emissions include all processes before and
        after the occupation of a building, while operative emissions cover the
        energy and water consumption during occupation time. Since the global
        oil crisis in the 1970s, significant efforts have been made to reduce
        the operative energy consumption of buildings. As a result, the
        operational energy consumption of new and refurbished buildings has
        decreased considerably.[1] Therefore, reducing the embodied energy of
        buildings will be an important part of mitigating the environmental
        impact of the building sector in the future. In the latest action report
        by the World Green Building Council of 2019, the goal is set to reduce
        the embodied carbon of all new buildings, infrastructure and renovations
        to at least 40% by 2030 and reaching net zero embodied carbon by 2050.
        [2]
      </div>
    ),
    sources: [
      {
        number: 1,
        source:
          "Gantner, J. et al (2015) EeB Guide Guidance Document - Part B: Buildings - Operational guidance for Life Cycle Assessment studies of the Energy Efficiency Building Initiative",
      },
      {
        number: 2,
        source:
          "Adams, M. et al. (2019) Bringing embodied carbon upfront - Coordinated action for the building and construction sector to tackle embodied carbon, World Green Building Council ",
      },
    ],
    img: img5,
    imgSources: [],
  },
  {
    id: 5,
    header: "Production Phase A1-3",
    content: (
      <div>
        Within embodied energy, the production phase (A1-3) often accounts for
        the biggest share of total embodied energy. In a study published in 2018
        24 statistically-based dwelling types, representative of the EU housing
        stock, were investigated to identify their average environmental impact
        and the contribution of each life cycle phase. It showed that the use
        phase (energy and water consumption) is the most relevant one (56-97%),
        followed by the production phase (3-40%). [1] The calculation of the
        production phase is based on independently verified and registered
        environmental product declarations (EPD) that communicate transparent
        and comparable information about the environmental impact of building
        materials and does not depend on external influences in terms of service
        life of materials or possible end-of-life scenarios. The production
        phase is the one in which the architect has the most pertinent influence
        in determining choices that impact the sustainability of a building. The
        JOKOTAI tool focuses on the environmental impact of material and
        structural choices during the production phase including the carbon
        storage potential, while giving additional information about the
        expected service life, production process and recycling potential of
        materials.
      </div>
    ),
    sources: [
      {
        number: 1,
        source:
          "Lavagna et al. (2018), Benchmarks for environmental impact of housing in Europe: Definition of archetypes and LCA of the residential building stock",
      },
    ],
    img: img6,
    imgSources: [],
  },
  {
    id: 6,
    header: "Environmental Product Declarations (EPD)",
    content: (
      <div>
        Being developed by the architecture office OOPEAA, which is based in
        Helsinki, Finland, the JOKOTAI tool currently focuses on screening the
        environmental impact of building materials that were either produced in
        Europe or are from a European manufacturer. Each material that can be
        selected in the tool is represented by a European based environmental
        product declaration (EPD). The EPDs were selected with a focus on
        covering a wide range of common building materials from different
        European countries. In order to use EPDs that represent the average
        environmental impact of a product and avoid large aberrations, the EPD
        selection is based on a comparison of multiple EPDs of the same product
        from different manufacturers, after which the median EPD was chosen for
        the JOKOTAI tool. However, the environmental impact of the same product
        can be very different. A factory that is using renewable energy, is
        efficient in its use of materials and can avoid long transport distances
        can produce functionally comparable products with considerably less
        emissions than a factory that relies on fossil energy and has not yet
        optimized its production and raw material acquisition processes. That is
        why each material has a link to its EPD source, to enable full
        transparency and the possibility for self-evaluation.
      </div>
    ),
    sources: [],
    img: img7,
    imgSources: [],
  },
  {
    id: 7,
    header: "Environmental Indicators",
    content: (
      <div>
        The JOKOTAI tool focuses on two environmental indicators to quantify the
        impact of structural and material choices during the production phase
        (A1-3). Global Warming Potential (GWP), measured in kg/CO2 equivalents,
        quantifies the greenhouse gas emissions that occur during the production
        of a building material. [1] Total primary energy from non-renewable
        resources (PENRT), measured in MJ, has been chosen as a second
        environmental indicator to visualise the demand of non-renewable energy
        resources (crude oil, coal, etc.) in the production of building
        materials. [1]
      </div>
    ),
    sources: [
      {
        number: 1,
        source:
          "Dimova, S. and Gervasio H. (2018) Model for Life Cycle Assessment (LCA) of buildings, Publications Office of the European Union",
      },
    ],
    img: null,
    imgSources: [],
  },
  {
    id: 8,
    header: "Calculation",
    content: (
      <div>
        The calculation of environmental impacts includes only phase A1-3
        (production phase) of a life cycle assessment. The environmental
        performance is calculated by multiplying the building parts area
        (foundation, structure, insulation, facade, floor, roof) with the
        default thickness of the chosen building material (see material
        thickness table below). The resulting volume is then multiplied with the
        associated environmental impact measured in kgCO2e (global warming
        potential) or MJ (primary energy from non-renewable energy sources)
        which originate from the material EPD. Finally the total impact is
        divided by the total floor area of the building.
        <div style={{ fontWeight: 700, fontSize: 12, paddingTop: 12 }}>
          Material Thickness Table
        </div>
        <Table>
          <Table.Header>
            <Table.HeaderCell style={{ fontSize: 12 }}>
              Building Part
            </Table.HeaderCell>
            <Table.HeaderCell style={{ fontSize: 12 }}>
              Material
            </Table.HeaderCell>
            <Table.HeaderCell style={{ fontSize: 12 }}>
              Default Thickness
            </Table.HeaderCell>
          </Table.Header>
          <Table.Body>
            {TableData.map((row) =>
              row.materials.map((material) => (
                <Table.Row key={material.id}>
                  {material.id === 0 ? (
                    <Table.Cell rowSpan={row.materials.length}>
                      {row.part}
                    </Table.Cell>
                  ) : null}
                  <Table.Cell>{material.name}</Table.Cell>
                  <Table.Cell>{material.thickness}</Table.Cell>
                </Table.Row>
              ))
            )}
          </Table.Body>
        </Table>
      </div>
    ),
    sources: [],
    img: null,
    imgSources: [],
  },
];

import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

// Authentication
import { AuthProvider, AuthConsumer } from './Authentication/AuthContext';

// Router
import BaseRouter from './routes';

// UI
import PageHeader from './Layout/PageHeader';
import MessageHeader from './Layout/Common/MessageHeader';
import { Segment } from 'semantic-ui-react';
import './App.css';

function App() {
    return (
        <div className="App">
            <Router >
                <AuthProvider>
                    <AuthConsumer>
                        {({ isLoading }) => (
                            isLoading ?
                                <Segment className="LoaderSegment" loading={isLoading} />
                                :
                                <div className="PageContent">
                                    <PageHeader />
                                    <MessageHeader />
                                    <BaseRouter />
                                </div>
                        )}
                    </AuthConsumer>
                </AuthProvider>
            </Router>
        </div>
    );
}

export default App;

import React from "react";

import Pie_30 from "../../img/Graphs/Pie_30.png";
import Pie_35 from "../../img/Graphs/Pie_35.png";
import Pie_42 from "../../img/Graphs/Pie_42.png";
import Pie_50 from "../../img/Graphs/Pie_50.png";

const ImpactInfo = () => {
  return (
    <div className="impact-info">
      <div className="impact-info-title-row">
        The building industry is the most resources intensive sector in Europe.
        It is responsible for:
      </div>
      <div className="divider"></div>

      <div className="impact-info-row">
        <div className="impact-info-col-img">
          <img className="impact-info-img" src={Pie_35} alt={Pie_35} />
        </div>
        <div className="impact-info-col-percentage">35%</div>
        <div className="impact-info-col-title">Greenhouse gas emissions</div>
      </div>

      <div className="impact-info-row">
        <div className="impact-info-col-img">
          <img className="impact-info-img" src={Pie_42} alt={Pie_42} />
        </div>
        <div className="impact-info-col-percentage">42%</div>
        <div className="impact-info-col-title">Final energy consumption</div>
      </div>

      <div className="impact-info-row">
        <div className="impact-info-col-img">
          <img className="impact-info-img" src={Pie_50} alt={Pie_50} />
        </div>
        <div className="impact-info-col-percentage">50%</div>
        <div className="impact-info-col-title">Extracted raw materials</div>
      </div>

      <div className="impact-info-row">
        <div className="impact-info-col-img">
          <img className="impact-info-img" src={Pie_30} alt={Pie_30} />
        </div>
        <div className="impact-info-col-percentage">30%</div>
        <div className="impact-info-col-title">Generated waste</div>
      </div>
    </div>
  );
};

export default ImpactInfo;

export const ProjectSettings = {
  sliders: {
    storiesMin: 1,
    storiesMax: 20,
    storyHeightMin: 2.5,
    storyHeightMax: 5,
    openingsMin: 15,
    openingsMax: 85,
    buildingWidthMin: 10,
    buildingWidthMax: 130,
    buildingDepthMin: 10,
    buildingDepthMax: 130,
    buildingDensityMin: 3,
    buildingDensityMax: 10,
  },
};

export const building_part_dict = {
  Roof: "roof",
  Floor: "floor",
  Facade: "facade",
  Insulation: "insulation",
  "Vertical structure": "vertical",
  "Horizontal structure": "horizontal",
  Openings: "openings",
  Foundation: "foundation",
};

export const construction_types = {
  0: {
    name: "Modular",
    sort_priority: 10,
    material_locks: [8, 10, 11, 28, 30],
  },
  1: {
    name: "Element",
    sort_priority: 20,
    material_locks: [8, 30],
  },
  2: {
    name: "Frame",
    sort_priority: 30,
    material_locks: [2, 10, 11, 28],
  },
};

export const material_types_dict = {
  0: "None",
  1: "Soil",
  2: "Minerals",
  3: "Metals",
  4: "Oils",
  6: "Glass",
  7: "Mix",
  8: "Plants",
};

// Mainly colours for each material type. Move from firestore to local dict to reduce calls to
// Firestore, and as material types colours change so infrequently.
export const material_types = {
  0: {
    name: "None",
    colour: { r: 0.9, g: 0.9, b: 0.9 },
    colour_light: { r: 1, g: 1, b: 1 },
    colour_dark: { r: 0.8, g: 0.8, b: 0.8 },
  },
  1: {
    name: "Soil",
    colour: { r: 0.8, g: 0.7, b: 0.57 },
    colour_light: { r: 0.83, g: 0.75, b: 0.65 },
    colour_dark: { r: 0.64, g: 0.58, b: 0.5 },
  },
  2: {
    name: "Minerals",
    colour: { r: 0.81, g: 0.87, b: 0.89 },
    colour_light: { r: 0.91, g: 0.95, b: 0.96 },
    colour_dark: { r: 0.72, g: 0.74, b: 0.75 },
  },
  3: {
    name: "Metals",
    colour: { r: 0.67, g: 0.64, b: 0.72 },
    colour_light: { r: 0.71, g: 0.67, b: 0.73 },
    colour_dark: { r: 0.52, g: 0.49, b: 0.54 },
  },
  4: {
    name: "Oils",
    colour: { r: 0.35, g: 0.35, b: 0.36 },
    colour_light: { r: 0.57, g: 0.57, b: 0.57 },
    colour_dark: { r: 0.37, g: 0.37, b: 0.37 },
  },
  6: {
    name: "Glass",
    colour: { r: 0.82, g: 0.95, b: 0.85 },
    colour_light: { r: 0.86, g: 0.92, b: 0.86 },
    colour_dark: { r: 0.68, g: 0.77, b: 0.7 },
  },
  7: {
    name: "Mix",
    colour: { r: 0.91, g: 0.9, b: 0.83 },
    colour_light: { r: 0.88, g: 0.88, b: 0.88 },
    colour_dark: { r: 0.68, g: 0.68, b: 0.68 },
  },
  8: {
    name: "Plants",
    colour: { r: 1, g: 0.93, b: 0.64 },
    colour_light: { r: 0.98, g: 0.96, b: 0.85 },
    colour_dark: { r: 0.88, g: 0.83, b: 0.6 },
  },
};

export const colours = {
  // general use, especially in onboarding views
  white: { r: 1, g: 1, b: 1, a: 1 },
  black: { r: 0, g: 0, b: 0, a: 1 },
  transparent: { r: 1, g: 1, b: 1, a: 0 },

  // Used to provide shade/definition on building geometry
  grey95: { r: 0.95, g: 0.95, b: 0.95, a: 1 },
  grey90: { r: 0.9, g: 0.9, b: 0.9, a: 1 },
  grey85: { r: 0.85, g: 0.85, b: 0.85, a: 1 },

  grey80: { r: 0.8, g: 0.8, b: 0.8, a: 1 },
  grey60: { r: 0.6, g: 0.6, b: 0.6, a: 1 },
  grey20: { r: 0.2, g: 0.2, b: 0.2, a: 1 },

  grey80alpha45: { r: 0.8, g: 0.8, b: 0.8, a: 0.45 },
  grey80alpha30: { r: 0.8, g: 0.8, b: 0.8, a: 0.3 },
  grey80alpha15: { r: 0.8, g: 0.8, b: 0.8, a: 0.15 },
};

export const buildingParams = {
  foundation: {
    height: 0.6, // Height i z-axis
    width: 0.3, // Depth in x- or y-axis
  },
  slab: {
    height: 0.3, // Slab Thickness
  },
  roof: {
    totalThickness: 0.3,
    externalSurface: 0.005, // Outer Surface layer thickness
    constructionLayer1: 0.1, // Construction Layer 1 thickness
    insulation: 0.095, // Insulation thickness
    constructionLayer2: 0.1, // Construction Layer 2 thickness
  },
  layout: { ratio: 3, gapSize: 5 },
  modular: { wallThickness: 0.15 },
  elements: { wallThickness: 0.3 },
  frame: {
    flangeThickness: 0.01, // Thickness of plate
    columnX: 0.3, // Cross-section (max) size in x-direction
    columnY: 0.3, // Cross-section (max) size in y-direction
    floorThickness: 0.3,
    wallThickness: 0.3,
  },
  facade: {
    totalThickness: 0.3,
    externalSurface: 0.005,
    constructionLayer1: 0.1,
    insulation: 0.095,
    constructionLayer2: 0.1,
  },
  construction: {
    // Added DB. Need to refactor this file and remove unused.
    foundationThickness: 0.3,
    floorThickness: 0.1,
    horizontalThickness: 0.3,
    verticalThickness: 0.3,
    facadeThickness: 0.3,
    facadeOffset: 8,
    facadeOffsetRatio: 0.25,
    insulationThickness: 0.3,
    insulationOffset: 4,
    insulationOffsetRatio: 0.1,
    openingHeight: 0.7, // percentage of story
    roofOffset: 8,
    roofOffsetRatio: 0.2,
    roofThickness: 0.8,
    roofSize: 1.05, // Roof size multiplier
  },
  onboarding: {
    facadeOffset: 4,
    facadeOffsetRatio: 0.2,
  },
};

import React, { Component } from 'react';

import { BabylonView } from '../Scene/BabylonView';
import { CreateLayoutBox } from '../Functions/LayoutBox';
import { CreateFoundation } from '../Functions/Foundation';
import { CreateInternalDashedLines } from '../Functions/InternalWallDashes';


export default class BuildingDensityView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            view: null,
            foundation: null,
            internalWalls: null,
            transparentMass: null
        }
    }

    componentDidMount() {
        let { project } = this.props;
        let { width, story_height, stories, depth } = project;

        let view = new BabylonView("Density");
        view.updateZoom(width, (story_height * stories), depth);

        let layoutBox = CreateLayoutBox(project, view);
        let foundation = CreateFoundation(project, view, false);
        let internalWalls = CreateInternalDashedLines(project, view);

        this.setState({ view: view, layoutBox: layoutBox, foundation: foundation, internalWalls: internalWalls });
    }

    componentDidUpdate(prevProps) {
        let { density } = this.props.project;
        // Check if slider values have changed
        if (density !== prevProps.project.density) {
            this.state.internalWalls.dispose();
            let internalWalls = CreateInternalDashedLines(this.props.project, this.state.view);
            this.setState({ internalWalls: internalWalls })
        }
    }

    render() {
        return (
            <canvas className="Canvas" id="Density" />
        )
    }
}

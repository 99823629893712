import React from 'react';

export default function InputSlider({ icon, value, unit, min, max, step = 1, changeHandler, propName }) {
    return (
        <div className="ProjectSelectionInput">

            <div className="ProjectSelectionIcon">
                <img
                    className="Icon"
                    src={icon}
                    alt={icon}
                />
            </div>

            <div className="ProjectSelectionSlider">
                <input
                    className="Slider"
                    name={propName}
                    value={value}
                    type="range"
                    min={min}
                    max={max}
                    onChange={(e) => changeHandler(e)}
                    step={step}
                />
            </div>

            <div className="ProjectSelectionUnit">
                {value}{unit}
            </div>

        </div>
    )
}

import { Vector3, MeshBuilder } from "babylonjs";
import { buildingParams, colours } from '../../ProjectSettings';

export const CreateRoof = (project, view, roofMaterial) => {
    // Init 
    let { width, depth, story_height, stories } = project;
    let building_height = story_height * stories;

    // Colours
    const white = view.rgbToCol3(colours.white);
    const grey90 = view.rgbToCol3(colours.grey90);

    // Setup ofset position
    let ratio = buildingParams.construction.roofOffsetRatio;
    let longestSide = ((width > depth) ? width : depth);
    let offset = longestSide * ratio;

    // Define roof options
    let options = {
        width: width * buildingParams.construction.roofSize,
        height: buildingParams.construction.roofThickness,
        depth: depth * buildingParams.construction.roofSize,
        faceColors: [grey90, grey90, grey90, grey90, white, white]
    };

    // Create roof and set it's position and material
    let roof = MeshBuilder.CreateBox("topInsulation", options, view.scene);
    roof.position = new Vector3(0, 0 + (building_height) + offset, 0);
    roof.material = roofMaterial;

    return roof;
}
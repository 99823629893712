import { buildingParams, colours } from '../../ProjectSettings';
import { Vector3, Mesh, MeshBuilder } from 'babylonjs';

// Creates a mesh of the horizontal construction elements of type Modular, Element or Frame
export const CreateHorizontalConstruction = (project, view, horizontalMaterial) => {
    // Init params
    let horizontalConstruction = new Mesh("horizontal", view.scene);
    let thickness = buildingParams.construction.horizontalThickness;
    let { width, depth, stories, story_height, density } = project;
    
    // Colours 
    const grey95 = view.rgbToCol3(colours.grey95);
    const grey90 = view.rgbToCol3(colours.grey90);
    const grey85 = view.rgbToCol3(colours.grey85);

    // Calculation walls proportions
    let wallRatio = buildingParams.layout.ratio;
    let wallsX = Math.round(width / density);
    let wallsY = Math.round((width / density) / wallRatio);
    let roomWidth = width / wallsX;
    let roomDepth = depth / wallsY;

    // Base positions
    let base = new Vector3(0, 0, 0);
    base.x = 0 - width / 2;
    base.y = 0 + buildingParams.construction.foundationThickness;
    base.z = 0 - depth / 2;

    // Used to store meshes to be merged
    var meshes = [];

    // If Modular construction create a floor and ceiling
    if (project.construction_type === 0) {

        // Create floor and ceiling room sized meshes 
        for (let x = 0; x < wallsX; x++) {
            for (let y = 0; y < wallsY; y++) {
                let options = {
                    width: roomWidth - thickness,
                    height: thickness / 2,
                    depth: roomDepth - thickness,
                    faceColors: [grey90, grey90, grey85, grey85, grey95, grey95]
                };
                let moduleFloor = MeshBuilder.CreateBox("moduleFloor_" + x + "," + y, options, view.scene);
                moduleFloor.position = new Vector3(base.x + roomWidth / 2 + (roomWidth * x), base.y - (thickness / 4), base.z + roomDepth / 2 + (roomDepth * y));
                moduleFloor.material = horizontalMaterial;
                meshes.push(moduleFloor);

                let moduleCeiling = MeshBuilder.CreateBox("moduleCeiling_" + x + "," + y, options, view.scene);
                moduleCeiling.position = new Vector3(base.x + roomWidth / 2 + (roomWidth * x), base.y + (story_height - (thickness + (thickness / 4))), base.z + roomDepth / 2 + (roomDepth * y));
                moduleCeiling.material = horizontalMaterial;
                meshes.push(moduleCeiling);
            }
        }

        // Create one 'ground story' mesh from meshes
        let story0 = Mesh.MergeMeshes(meshes, true, true);
        story0.name = "story0";
        story0.parent = horizontalConstruction;

        // Duplicate other stories, if there are any. 
        for (let storyIndex = 1; storyIndex < stories; storyIndex++) {
            let nextStory = story0.clone("story" + storyIndex.toString());
            nextStory.position.y += (story_height * storyIndex);
        }

    // Else if the construction is Element or Frame, create floor and no ceiling. 
    } else {

        // Create ground floor
        let options = {
            width: width,
            height: thickness,
            depth: depth,
            faceColors: [grey90, grey90, grey85, grey85, grey95, grey95]
        };

        // Create floor mesh and set it's position and material
        let floor = MeshBuilder.CreateBox("floorMesh0", options, view.scene);
        floor.position = new Vector3(0, base.y, 0);
        floor.material = horizontalMaterial;
        floor.parent = horizontalConstruction;

        // Duplicate other stories, if there are any. 
        for (let storyIndex = 1; storyIndex < stories; storyIndex++) {
            let nextFloor = floor.clone("floorMesh" + storyIndex.toString());
            nextFloor.position.y += (story_height * storyIndex);
        }
    }

    return horizontalConstruction;
}
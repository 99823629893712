import React, { Component } from 'react'

// WebGL
import { BabylonView } from '../Scene/BabylonView';
import { AddConceptualMass } from '../Functions/ConceptualMass';

export default class BuildingSizeView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            view: null,
            geometry: null
        }
    }

    componentDidMount() {
        let { project } = this.props;
        let { width, story_height, stories, depth } = project;
        let view = new BabylonView("Conceptual");
        let geometry = AddConceptualMass(project, view);
        view.updateZoom(width, (story_height * stories), depth);
        this.setState({ view: view, geometry: geometry })
    }

    componentDidUpdate(prevProps) {
        let { width, depth, stories, story_height } = this.props.project;
        // Check if slider values have changed
        if (width !== prevProps.project.width || depth !== prevProps.project.depth || stories !== prevProps.project.stories || story_height !== prevProps.project.story_height) {
            this.state.geometry.dispose();
            let geometry = AddConceptualMass(this.props.project, this.state.view);
            this.state.view.updateZoom(width, (story_height * stories), depth);     // Update camera zoom
            this.setState({ geometry: geometry })
        }
    }

    render() {
        return (
            <canvas className="Canvas" id="Conceptual" />
        )
    }
}

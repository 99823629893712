import { buildingParams, colours } from '../../ProjectSettings';
import { Vector3, Mesh, MeshBuilder, StandardMaterial } from 'babylonjs';

// Creates a building sized box with semi transparent front faces and also creates a base slab mesh
export const CreateLayoutBox = (project, view) => {
    // Init params
    let layoutBox = new Mesh("layoutBox", view.scene);
    let slabHeight = buildingParams.slab.height;
    let { width, depth, stories, story_height } = project;
    let height = stories * story_height;

    // Colours
    let white = view.rgbaToCol4(colours.white); 
    let transparent = view.rgbaToCol4(colours.transparent);
    let grey80alpha45 = view.rgbaToCol4(colours.grey80alpha45); 
    let grey80alpha30 = view.rgbaToCol4(colours.grey80alpha30);
    let grey80alpha15 = view.rgbaToCol4(colours.grey80alpha15);

    // Material
    let layoutBoxMaterial = new StandardMaterial("layoutBoxMaterial", view.scene);
    layoutBoxMaterial.ambientColor = white;

    // Create white background box 
    let whiteBoxOptions = {
        width: width + (slabHeight * 2),
        height: height + (slabHeight * 2),
        depth: depth + (slabHeight * 2),
        faceColors: [transparent, white, transparent, white, transparent, white]
    };
    let whiteBox = MeshBuilder.CreateBox("whiteBackgroundBox", whiteBoxOptions, view.scene);
    whiteBox.position = new Vector3(0, (height / 2) - slabHeight, 0);
    whiteBox.hasVertexAlpha = true;
    whiteBox.flipFaces(false);
    whiteBox.material = layoutBoxMaterial;
    whiteBox.parent = layoutBox;

    // Create semi-transparent foreground box 
    let semiBoxOptions = {
        width: width,
        height: (height - slabHeight),
        depth: depth,
        faceColors: [grey80alpha45, transparent, grey80alpha15, transparent, grey80alpha30, transparent]
    };
    let semiBox = MeshBuilder.CreateBox("semiTransparentForegroundBox", semiBoxOptions, view.scene);
    semiBox.position = new Vector3(0, (height / 2), 0);
    semiBox.hasVertexAlpha = true;
    semiBox.material = layoutBoxMaterial;
    semiBox.parent = layoutBox;

    return layoutBox;
}


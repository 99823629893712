import React, { Fragment } from 'react'
import { ProjectConsumer } from '../ProjectContext';

// Settings
import { ProjectSettings } from '../ProjectSettings';

// 3D
import BuildingOpeningsView from '../Geometry/Views/BuildingOpeningsView';

// UI
import DescriptionFooter from './Common/DescriptionFooter';
import InputSlider from './Common/InputSlider';

function OpeningsView() {
    return (
        <ProjectConsumer>
            {({ project, handleSliderChange }) => (

                <Fragment>
                    <div className="ProjectHeadContainer">
                        <div className="TextContainer">
                            <div className="Title"><strong>Openings</strong></div>
                        </div>
                    </div>

                    <div className="ProjectContentContainer">
                        <BuildingOpeningsView project={project} />

                        <div className="ProjectTooltipContainer">Move sliders to define the building’s opening percentage</div>

                        <div className="ProjectSelectionContainer">
                            <div className="ProjectSelectionPadding">
                                <InputSlider
                                    icon={null}
                                    unit="%"
                                    value={project.openings}
                                    min={ProjectSettings.sliders.openingsMin}
                                    max={ProjectSettings.sliders.openingsMax}
                                    propName="openings"
                                    changeHandler={handleSliderChange}
                                />
                            </div>
                        </div>

                    </div>

                    <DescriptionFooter title="Facade openings" description="To estimate the amount of openings, which represent windows and doors in the building, a rough opening ratio needs to be defined. The openings are to be seen as the ratio only and not as a preliminary design of the facade. A low degree of openings might apply to factory or retail buildings, a medium degree of openings to residential buildings and large openings to offices or public buildings." />

                </Fragment>

            )}
        </ProjectConsumer>
    )
}

export default OpeningsView;
import React from "react";
import { ProjectConsumer } from "../../ProjectContext";

// Graphs
import ResourceGraph from "../../Graphs/ResourceGraph";
import PartsGraph from "../../Graphs/PartsGraph";
import MaterialsGraph from "../../Graphs/MaterialsGraph";
import ImpactGraph from "../../Graphs/ImpactGraph/ImpactGraph";

function GraphView(props) {
  let { project, results, building, side, unit, materials_list } = props;
  return (
    <ProjectConsumer>
      {({ active_graph_index }) =>
        graphs_list(project, results, building, side, unit, materials_list)[
          active_graph_index
        ]
      }
    </ProjectConsumer>
  );
}

const graphs_list = (
  project,
  results,
  building,
  side,
  unit,
  materials_list
) => [
  <ResourceGraph
    results={results}
    building={building}
    side={side}
    unit={unit}
  />,
  <PartsGraph
    project={project}
    results={results}
    building={building}
    side={side}
    unit={unit}
    materials_list={materials_list}
  />,
  <MaterialsGraph
    results={results}
    building={building}
    side={side}
    unit={unit}
    materials_list={materials_list}
  />,
  <ImpactGraph
    project={project}
    results={results}
    side={side}
    building={building}
    unit={unit}
  />,
];

export default GraphView;

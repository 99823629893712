import React, { Fragment, useState } from "react";
import { ProjectConsumer } from "../../ProjectContext";

//import DescriptionFooter from '../Common/DescriptionFooter';
import WorldCounts from "../Containers/WorldCounts";
import { Icon, List } from "semantic-ui-react";
import Color_key_strip from "../../img/MaterialTypes/Color_key_strip.png";

export default function MaterialInfoContainer(props) {
  const results = props.results;
  const [menuIndex, setMenuIndex] = useState(0);

  return (
    <ProjectConsumer>
      {({ latest_selection, display_graphs }) =>
        display_graphs ? (
          <GraphDescription />
        ) : (
          <div className="MaterialInfoContainer" key={latest_selection.id}>
            {latest_selection === null ? (
              <div>Please Select a material</div>
            ) : (
              <Fragment>
                <div className="MaterialDetailsContainer">
                  <div className="MaterialDetailIconCol">
                    <img
                      className="MaterialIcon"
                      src={"img/" + latest_selection.icon}
                      alt={latest_selection.name}
                    />
                  </div>
                  <div className="MaterialDetailTextCol">
                    <div className="MaterialDetailText">
                      <div className="MaterialDetailTextRow BoldHead1">
                        {latest_selection.name}
                      </div>
                      <div className="MaterialDetailTextRow Text1">
                        {latest_selection.general_description}
                      </div>
                      <div className="MaterialDetailTextRow Text1">
                        Lifespan: {latest_selection.lifespan} years
                      </div>
                    </div>
                  </div>
                </div>

                <div className="Divider" />

                {/* Material Info Menu */}
                <div className="MaterialMenuContainer">
                  <List>
                    {MenuItems().map((item, index) => (
                      <List.Item
                        key={index}
                        onClick={() => setMenuIndex(item.index)}
                      >
                        <Icon
                          className={`MaterialMenuIcon ${
                            menuIndex === item.index ? "Active" : ""
                          }`}
                          name="right triangle"
                        />
                        <List.Content>
                          <div
                            className={`MaterialMenuItem ${
                              menuIndex === item.index ? "Active" : ""
                            }`}
                          >
                            {item.text}
                          </div>
                        </List.Content>
                      </List.Item>
                    ))}
                  </List>
                </div>

                {/* Material Info */}
                <div className="MaterialDescriptionContainer">
                  {MenuItems(results)[menuIndex].content}
                </div>
              </Fragment>
            )}
          </div>
        )
      }
    </ProjectConsumer>
  );
}

const GeneralTab = () => {
  return (
    <ProjectConsumer>
      {({ latest_selection }) => (
        <Fragment>
          <div className="Col">
            <div className="Row Text1">
              <strong>Pros</strong>
            </div>
            <div className="Row RowDirection" style={{ paddingTop: 20 }}>
              <div className="Col RowDirection">
                <div className="Col">
                  {latest_selection.pro1_img ? (
                    <img
                      className="ProConImg"
                      src={"img/" + latest_selection.pro1_img}
                      alt={latest_selection.pro1_text}
                    />
                  ) : null}
                </div>
                <div className="Col Text2">{latest_selection.pro1_text}</div>
              </div>
              <div className="Col RowDirection">
                <div className="Col">
                  {latest_selection.pro2_img ? (
                    <img
                      className="ProConImg"
                      src={"img/" + latest_selection.pro2_img}
                      alt={latest_selection.pro2_text}
                    />
                  ) : null}
                </div>
                <div className="Col Text2">{latest_selection.pro2_text}</div>
              </div>
            </div>
          </div>

          <div className="RightBordered" style={{ padding: 20 }} />

          <div className="Col" style={{ paddingLeft: 20 }}>
            <div className="Row Text1">
              <strong>Cons</strong>
            </div>
            <div className="Row RowDirection" style={{ paddingTop: 20 }}>
              <div className="Col RowDirection">
                <div className="Col">
                  {latest_selection.con1_img ? (
                    <img
                      className="ProConImg"
                      src={"img/" + latest_selection.con1_img}
                      alt={latest_selection.con1_text}
                    />
                  ) : null}
                </div>
                <div className="Col Text2">{latest_selection.con1_text}</div>
              </div>
              <div className="Col RowDirection">
                <div className="Col">
                  {latest_selection.con2_img ? (
                    <img
                      className="ProConImg"
                      src={"img/" + latest_selection.con2_img}
                      alt={latest_selection.con2_text}
                    />
                  ) : null}
                </div>
                <div className="Col Text2">{latest_selection.con2_text}</div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </ProjectConsumer>
  );
};

const ConstructionTab = () => {
  return (
    <ProjectConsumer>
      {({ latest_selection }) => (
        <div className="Col RowDirection">
          <div className="Col">
            <div className="Row">
              <strong>PRODUCTION (A1-A3)</strong>
            </div>
            <div className="Row Text2">{latest_selection.production_text}</div>
          </div>
          <div className="Col">
            <div className="VerticalMidAligned">
              <img
                className="ProductionImg"
                src={"img/" + latest_selection.production_img}
                alt={latest_selection.name}
              />
            </div>
          </div>
        </div>
      )}
    </ProjectConsumer>
  );
};

const RecyclingTab = () => {
  return (
    <ProjectConsumer>
      {({ latest_selection }) => (
        <Fragment>
          <div className="Col">
            <div className="Row">
              <strong>RECYCLING (C1-4)</strong>
            </div>
            <div className="Row Text2">{latest_selection.recycling_text}</div>
          </div>
          <div className="Col">
            <img
              className="RecyclingImg"
              src={"img/" + latest_selection.recycling_img}
              alt={latest_selection.name}
            />
          </div>
        </Fragment>
      )}
    </ProjectConsumer>
  );
};

const CalculationTab = ({ results }) => {
  return (
    <ProjectConsumer>
      {({ latest_selection, project, calculation_unit }) => (
        <Fragment>
          {/* MATERIAL INFO */}
          <div className="Col">
            <tr className="ResultTableTopRow">
              <td className="ResultTableTitleCell">
                <strong>EPD</strong>
              </td>
              <td className="ResultTableResultCell">
                <a
                  style={{ color: "#77af18", fontWeight: 700 }}
                  href={latest_selection.data_source_link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Source
                </a>
              </td>
            </tr>
            <tr className="ResultTableRow">
              <td className="ResultTableTitleCell">
                <strong>Product:</strong>
              </td>
              <td className="ResultTableResultCell">{latest_selection.name}</td>
            </tr>
            <tr className="ResultTableRow">
              <td className="ResultTableTitleCell">
                <strong>Manufacturer:</strong>
              </td>
              <td className="ResultTableResultCell">
                {latest_selection.producer}
              </td>
            </tr>
            <tr className="ResultTableRow">
              <td className="ResultTableTitleCell">
                <strong>Material Origin:</strong>
              </td>
              <td className="ResultTableResultCell">
                {latest_selection.country_origin}
              </td>
            </tr>
            <tr className="ResultTableRow">
              <td className="ResultTableTitleCell">
                <strong>Datasource:</strong>
              </td>
              <td className="ResultTableResultCell">
                {latest_selection.data_source}
              </td>
            </tr>
            <tr className="ResultTableRow">
              <td className="ResultTableTitleCell">
                <strong>Valid until:</strong>
              </td>
              <td className="ResultTableResultCell">
                {latest_selection.expiry_date}
              </td>
            </tr>
          </div>

          {/* Calculation */}
          <div className="Col">
            <div className="MaterialCalculationContainer">
              <div
                className="Row"
                style={{
                  fontSize: "1.5vh",
                  fontWeight: 500,
                  paddingTop: 3,
                  paddingBottom: 3,
                }}
              >
                <strong style={{ fontSize: "2.5vh", fontWeight: 700 }}>
                  {calculation_unit
                    ? Math.round(results.latest_selection.impact.GWP)
                    : Math.round(results.latest_selection.impact.PENRT)}
                </strong>
                {calculation_unit ? " kgCO2e/m2 (A1-A3)" : " MJ/m2 (A1-A3)"}
              </div>
              <div className="Row" style={{ display: "flex" }}>
                <div
                  className="Col"
                  style={{ fontWeight: 700, fontSize: "1vh", flex: 1 }}
                >
                  Total impact:
                </div>
                <div
                  className="Col"
                  style={{ fontWeight: 500, fontSize: "1vh", flex: 2 }}
                >
                  {calculation_unit
                    ? Math.round(
                        results.latest_selection.impact.GWP *
                          (project.width * project.depth * project.stories)
                      )
                    : Math.round(
                        results.latest_selection.impact.PENRT *
                          (project.width * project.depth * project.stories)
                      )}
                  {calculation_unit
                    ? ` kgCO2e (${
                        project.width * project.depth * project.stories
                      }m2)`
                    : ` MJ (${
                        project.width * project.depth * project.stories
                      }m2)`}
                </div>
              </div>
              <div className="Row" style={{ display: "flex" }}>
                <div
                  className="Col"
                  style={{ fontWeight: 700, fontSize: "1vh", flex: 1 }}
                >
                  EPD:
                </div>
                <div
                  className="Col"
                  style={{ fontWeight: 500, fontSize: "1vh", flex: 2 }}
                >
                  {calculation_unit
                    ? results.latest_selection.epd.GWP +
                      ` kgCO2e/${results.latest_selection.unit}`
                    : results.latest_selection.epd.PENRT +
                      ` MJ/${results.latest_selection.unit}`}
                </div>
              </div>
              <div className="Row" style={{ display: "flex" }}>
                <div
                  className="Col"
                  style={{ fontWeight: 700, fontSize: "1vh", flex: 1 }}
                >
                  Amount ({results.latest_selection.unit}):
                </div>
                <div
                  className="Col"
                  style={{ fontWeight: 500, fontSize: "1vh", flex: 2 }}
                >
                  {roundNumber(results.latest_selection.amount)}{" "}
                  {results.latest_selection.unit}
                </div>
              </div>
              <div
                className="Row"
                style={{ fontSize: "1.2vh", color: "#a9a9a9" }}
              >
                (Click{" "}
                <a
                  href="/research"
                  target="_blank"
                  style={{ color: "#77af18", fontWeight: 700 }}
                >
                  here
                </a>{" "}
                for material volume calculation info)
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </ProjectConsumer>
  );
};

const MenuItems = (results) => [
  { index: 0, text: "GENERAL", content: <GeneralTab /> },
  { index: 1, text: "PRODUCTION", content: <ConstructionTab /> },
  { index: 2, text: "RECYCLING", content: <RecyclingTab /> },
  {
    index: 3,
    text: "CALCULATION",
    content: <CalculationTab results={results} />,
  },
];

/* Graph descriptions */
const GraphDescription = () => {
  return (
    <ProjectConsumer>
      {({ active_graph_index }) => GraphDescriptions[active_graph_index]}
    </ProjectConsumer>
  );
};

const GraphDescriptionText = ({ title, description }) => {
  return (
    <div className="GraphInfoCol">
      <div className="Title">{title}</div>
      <div className="Description">{description}</div>
      <div>
        <img className="Icon" src={Color_key_strip} alt="color_codes" />
      </div>
    </div>
  );
};

const GraphDescriptions = [
  <GraphDescriptionText
    title="Most contributing resource type"
    description="The graph visualizes the resource types of the chosen building materials that have the largest environmental impact measured in Global Warming Potential (kgCO2e/m2) or in Primary Energy from Non-renewable resources (MJ/m2). Each building material belongs to a raw material resource type, which can be identified by their color code."
  />,
  <GraphDescriptionText
    title="Most Contributing building Part"
    description="The graph visualizes the building parts that have the largest environmental impact measured in Global Warming Potential (kgCO2e/m2) or in Primary Energy from Non-renewable resources (MJ/m2). The graph is divided into the main building parts, which are represented in the color of their material resources type."
  />,
  <GraphDescriptionText
    title="Most contributing material"
    description="The graph visualizes the building materials that have the largest environmental impact measured in Global Warming Potential (kgCO2e/m2) or in Primary Energy from Non-renewable resources (MJ/m2)."
  />,
  <WorldCounts />,
];

const roundNumber = (number) => {
  return Math.round(number * 100) / 100;
};

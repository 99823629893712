import React from "react";

import "./ComparisonHeader.css";
import HeaderInfoCol from "./ComparisonHeader/HeaderInfoCol";
import HeaderResultCol from "./ComparisonHeader/HeaderResultCol";
import HeaderUnitsCol from "./ComparisonHeader/HeaderUnitsCol";
import HeaderResult from "../../../Graphs/HeaderResult";

const ComparisonHeaderLeft = ({ project, results, unit }) => (
  <div className="comparison-header-col left">
    <HeaderInfoCol project={project} name="Option A" side="left" />
    <HeaderResultCol results={results} building="building1" unit={unit} />
    <HeaderUnitsCol results={results} building="building1" unit={unit} />
    <HeaderResult
      results={results}
      unit={unit}
      side="left"
      building="building1"
    />
  </div>
);

const ComparisonHeaderRight = ({ project, results, unit }) => (
  <div className="comparison-header-col right">
    <HeaderResult
      results={results}
      unit={unit}
      side="right"
      building="building2"
    />
    <HeaderResultCol results={results} building="building2" unit={unit} />
    <HeaderUnitsCol results={results} building="building2" unit={unit} />
    <HeaderInfoCol project={project} name="Option B" side="right" />
  </div>
);

const ComparisonHeader = ({ project, results, unit }) => (
  <div className="comparison-header">
    <ComparisonHeaderLeft
      project={project}
      name="Option A"
      building="Building1"
      results={results}
      unit={unit}
      side="left"
    />
    <div className="RightBordered" />
    <ComparisonHeaderRight
      project={project}
      name="Option B"
      building="Building2"
      results={results}
      unit={unit}
      side="right"
    />
  </div>
);

export default ComparisonHeader;

import React, { useState } from 'react';
import { AuthConsumer } from '../../AuthContext';

// Semantic UI
import { Modal, Button, Icon } from 'semantic-ui-react';

export default function ResendVerificationModal() {
    const [is_open, set_open] = useState(false);

    return (
        <AuthConsumer>
            {({ user, ResendVerificationEmail }) => (
                <Modal
                    open={is_open}
                    closeIcon
                    onClose={() => set_open(false)}
                    trigger={
                        user.emailVerified ?
                            <Icon
                                color="green"
                                size="big"
                                name="check"
                            />
                            :
                            <button className="Button" onClick={() => set_open(true)}>Resend</button>
                    }
                >
                    <Modal.Header content="Resend confirmation email" />

                    <Modal.Content>
                        <Modal.Description>
                            <p>Send confirmation email to {user.email}?</p>
                        </Modal.Description>
                    </Modal.Content>

                    <Modal.Actions>
                        <Button
                            content="Send"
                            positive
                            onClick={() => {
                                set_open(false)
                                ResendVerificationEmail()
                            }}
                        />
                    </Modal.Actions>

                </Modal>
            )}
        </AuthConsumer>
    )
}

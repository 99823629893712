import React from 'react';
import { AuthConsumer } from '../../Authentication/AuthContext';

import { Icon, Transition } from 'semantic-ui-react';

const MessageHeader = () => {
    return (
        <AuthConsumer>
            {({ global_message, SetGlobalMessage }) => (
                <Transition visible={global_message !== null} animation="slide left" duration={500}>
                    <div className="PageMessage">
                        {global_message}
                        <Icon
                            className="CloseButton"
                            name="close"
                            onClick={() => SetGlobalMessage(null)}
                        />
                    </div>
                </Transition>
            )
            }
        </AuthConsumer >
    )
}

export default MessageHeader;
import { Vector3, MeshBuilder, CSG, Mesh, MultiMaterial } from 'babylonjs';

// Creates and returns a single wall mesh with openings in it.
export const CreateWallWithOpenings = (view, width, height, depth, sectionsX, sectionsY, sectionHeight, openingWidthPercentile , openingHeightPercentile, wallMaterial, openingMaterial) => {
    // Parent mesh
    let wallWithOpenings = new Mesh("wallWithOpenings", view.scene);

    // Calculate end openings dimensions
    let sectionWidth = (width / (sectionsX - 1 )); 
    let openingWidth = sectionWidth * (openingWidthPercentile / 100);
    let openingHeight = sectionHeight *  openingHeightPercentile;

    // Create base wall mesh and convert to CSG
    let w = MeshBuilder.CreateBox("w", {width: depth, height: sectionHeight, depth: width  }, view.scene );
    w.position.z += (width / 2) + (sectionWidth / 2);
    let wallCSG = CSG.FromMesh(w);
    w.dispose();
    
    // Array for storing opening meshes 
    var openingMeshes = []; 

    // Zero start positions
    let startX = 0;
    let startY = 0;
    let posX = startX;
    let posY = startY;

    // Create a mesh for the ground floor, first story.
    for (let y = 1; y < sectionsX; y++) { 
        posX += sectionWidth;
        let openingMesh = MeshBuilder.CreateBox("openingMesh", { width: 2, height: openingHeight, depth: openingWidth}, view.scene);
        openingMesh.position = new Vector3(0,posY, posX);
        openingMeshes.push(openingMesh);
    }
    // merge openings mesh, cerate CSG, then delete mesh
    let allOpenings = Mesh.MergeMeshes(openingMeshes, true);
    let openingsCSG = CSG.FromMesh(allOpenings);
    allOpenings.dispose();
    
    // create multi material
    var multiMat = new MultiMaterial("multiMat", view.scene);
    multiMat.subMaterials.push(wallMaterial, openingMaterial);

    // create new mesh using csg and multi material
    let subCSG = wallCSG.subtract(openingsCSG);
    let wall0 = subCSG.toMesh("wall", multiMat, view.scene, true);
    wall0.parent = wallWithOpenings;

    // Move ground floor wall into position
    wall0.position.y -= (height/2) - (sectionHeight / 2);
    wall0.position.z -= (width / 2) + (sectionWidth / 2);
    
    // Duplicate the ground floor mesh for other stories and add to array
    for (let storyIndex = 1; storyIndex < sectionsY; storyIndex++) {
        let nextWall = wall0.clone("wall" + storyIndex.toString());
        nextWall.position.y += (sectionHeight * storyIndex);
    }

    return wallWithOpenings;
}
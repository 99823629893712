import { buildingParams, colours} from '../../ProjectSettings';
import { Vector3, Mesh, MeshBuilder } from 'babylonjs';

// Creates a floor mesh, made of room sized floor meshes that can vary in size and position based on construction type (Modular, Element or Frame)
export const CreateFloor = (project, view, floorMaterial) => {
    // Init params
    let floor = new Mesh("floor", view.scene);
    let thickness = buildingParams.construction.floorThickness;
    let horizontalThickness = buildingParams.construction.horizontalThickness;
    let verticalThickness = buildingParams.construction.verticalThickness;
    let { width, depth, stories, story_height, density, construction_type } = project;

    // Colours 
    const grey95 = view.rgbToCol3(colours.grey95);
    const grey90 = view.rgbToCol3(colours.grey90);
    const grey85 = view.rgbToCol3(colours.grey85);

    // Calculation walls proportions
    let wallRatio = buildingParams.layout.ratio;
    let wallsX = Math.round(width / density);
    let wallsY = Math.round((width / density) / wallRatio);
    let roomWidth = width / wallsX;
    let roomDepth = depth / wallsY;

    // Base positions
    let base = new Vector3(0, 0, 0);
    base.x = 0 - width / 2;
    base.y = 0 + buildingParams.construction.foundationThickness;
    base.z = 0 - depth / 2;

    // Used to store meshes to be merged
    var meshes = [];

    let floorHeight = horizontalThickness - thickness;
    // If Modular construction lower the floor
    if (construction_type === 0) {
        floorHeight = floorHeight - (horizontalThickness / 2)
    }

    // Create floor and ceiling room sized meshes 
    for (let x = 0; x < wallsX; x++) {
        for (let y = 0; y < wallsY; y++) {
            let options = {
                width: roomWidth - (verticalThickness * 2),
                height: thickness,
                depth: roomDepth - (verticalThickness * 2),
                faceColors: [grey90, grey90, grey85, grey85, grey95, grey95]
            };
            let roomFloor = MeshBuilder.CreateBox("roomFloor_" + x + "," + y, options, view.scene);
            roomFloor.position = new Vector3(base.x + roomWidth / 2 + (roomWidth * x), base.y + floorHeight, base.z + roomDepth / 2 + (roomDepth * y));
            roomFloor.material = floorMaterial;
            meshes.push(roomFloor);
        }
    }

    // Create one 'ground story' mesh from meshes
    let story0 = Mesh.MergeMeshes(meshes, true, true);
    story0.name = "story0";
    story0.parent = floor;

    // Duplicate other stories, if there are any. 
    for (let storyIndex = 1; storyIndex < stories; storyIndex++) {
        let nextStory = story0.clone("story" + storyIndex.toString());
        nextStory.position.y += (story_height * storyIndex);
    }

    return floor;
}
import React from 'react';

export default function ConfirmEmail() {
    return (
        <div className="ContentContainer">
            <div className="ContentRow" style={{ fontSize: 20, fontWeight: 800 }}>Email not confirmed</div>
            <div className="ContentRow">Please confirm your email address.</div>
            <div className="ContentRow" style={{display: 'block'}}>Not recieved an email? Go to <a href="/profile">profile</a> page to request a new confirmation.</div>
            <div className="ContentRow" style={{display: 'block'}}>Just confirmed your email? Try refreshing the page</div>
        </div>
    )
}

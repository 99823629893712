import React, { useState } from 'react';
import { AuthConsumer } from '../../AuthContext';

import { Modal, Button, Form } from 'semantic-ui-react';

export default function DeleteUserModal() {
    const [is_open, set_open] = useState(false);
    const [password, set_password] = useState("");
    return (
        <AuthConsumer>
            {({ DeleteUser }) => (

                <Modal
                    trigger={<button className="Button Red" onClick={() => set_open(true)}>Delete</button>}
                    open={is_open}
                    onClose={() => set_open(false)}
                    closeIcon
                >
                    <Modal.Header content="Confirm account deletion" />

                    <Modal.Content>
                        <Modal.Description>
                            <div>Are you sure you want to delete your user account?</div>
                            <strong>This action cannot be reversed</strong>


                            <div style={{ paddingTop: 20 }}>Please re-enter password</div>
                            <Form>
                                <Form.Field>
                                    <input
                                        type="password"
                                        value={password}
                                        onChange={(e) => set_password(e.target.value)}
                                    />
                                </Form.Field>
                            </Form>
                        </Modal.Description>
                    </Modal.Content>

                    <Modal.Actions>
                        <Button
                            content="Delete"
                            negative
                            onClick={() => DeleteUser(password)}
                        />
                    </Modal.Actions>
                </Modal>

            )}
        </AuthConsumer>
    )
}

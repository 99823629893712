import { Engine, Scene, Vector3, Color3, Color4, ArcRotateCamera } from "babylonjs";

export class BabylonView {
    constructor(canvasId) {
        // Init canvas & engine
        this.canvas = document.getElementById(canvasId);
        this.engine = new Engine(this.canvas, true);

        // Init scene
        this.scene = new Scene(this.engine);
        this.scene.clearColor = new Color3(0.925, 0.925, 0.925);
        this.scene.ambientColor = new Color3(1, 1, 1);

        // Camera constants     N.B. Move these, and maybe zoom values to project settings
        const CAM_ALPHA = 0.85; // Tweaked to match xd design, not exaclty 30 by 30
        const CAM_BETA = 1.2;
        const CAM_START_RANGE = 50;

        // Init camera
        this.camera = new ArcRotateCamera("camera", CAM_ALPHA, CAM_BETA, CAM_START_RANGE, new Vector3(0,2,0), this.scene);

        // Camera zoom settings
        this.cameraZoom = CAM_START_RANGE;
        this.cameraSpeed = 10;               // move to settings
        this.zoomFactor = 2.6;               // magic number
        
        // Move (zoom) the camera until it's in the correct position.
        this.scene.onBeforeRenderObservable.add(()=>{
            if (this.camera.radius > this.cameraZoom) {
                this.camera.radius -= this.cameraSpeed;
                if (this.camera.radius < this.cameraZoom) this.camera.radius = this.cameraZoom;  // Check we don't go past
            }
            if (this.camera.radius < this.cameraZoom) {
                this.camera.radius += this.cameraSpeed;
                if (this.camera.radius > this.cameraZoom) this.camera.radius = this.cameraZoom;  // Check we don't go past
            }
        })

        // Run scene
        this.engine.runRenderLoop(() => {
            this.scene.render();
        })

        const engine = this.engine;
        window.addEventListener('resize', function() {
            engine.resize();
        });
    }

    // Caculate camera distance (radius) based on building size
    updateZoom(width, height, depth) {
        let largest = Math.max(width, (height * 1.5) , depth);
        this.cameraZoom = largest * this.zoomFactor;
    }

    // Helper function to convert RGB values to a babylonJS color3
    rgbToCol3(rgb) {
        let colour = new Color3(rgb.r,rgb.g,rgb.b);
        return colour;
    }

    // Helper function to convert RGBA values to a babylonJS color4
    rgbaToCol4(rgba) {
        let colour = new Color4(rgba.r,rgba.g,rgba.b,rgba.a);
        return colour;
    }

    // Helper function to convert material_type values to a babylonJS color3
    materialTypeToCol3(mat) {
        let colour = new Color3(mat.R,mat.G,mat.B);
        return colour;
    }
/*
    // Helper function to convert material_type values to a babylonJS color3
    materialTypeToCol3Dark(mat) {
        let colour = new Color3(mat.R_dark,mat.G_dark,mat.B_dark);
        return colour;
    }

    // Helper function to convert material_type values to a babylonJS color3
    materialTypeToCol3Light(mat) {
        let colour = new Color3(mat.R_light,mat.G_light,mat.B_light);
        return colour;
    }
*/

}
import React from "react";
import "./TabletRestriction.css";

const TabletRestriction = () => (
  <div className="tablet-warning">
    <p>
      <strong>Please note:</strong> This application works better on desktop
      devices
    </p>
  </div>
);

export default TabletRestriction;

import React, { useState, Fragment } from 'react';
import { AuthConsumer } from '../../Authentication/AuthContext';
import ContentHeader from '../Common/ContentHeader';

import { Button, Form, Message } from 'semantic-ui-react';

import './PageContent.css';

export default function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    return (
        <AuthConsumer>
            {({ Login, loginError }) => (
                <Fragment>
                    <ContentHeader text="Login" />

                    <div className="PageForm">
                        <div className="FormContainer">

                            {/* Error message (if available) */}
                            {loginError ?
                                <Message>
                                    {loginError}
                                </Message>
                                : null
                            }

                            <Form>
                                <Form.Field>
                                    <label>Email</label>
                                    <input
                                        placeholder="Email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>Password</label>
                                    <input
                                        placeholder="Password"
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </Form.Field>

                                <div className="Row PaddedBottom">
                                    <Button
                                        fluid
                                        color="facebook"
                                        content="Sign in"
                                        onClick={() => Login(email, password)}
                                    />
                                </div>

                                <div className="Row PaddedTop PaddedBottom">
                                    <div>Dont have an account? <a href="/register">Click here</a> to register</div>
                                </div>

                                <div className="Row PaddedTop PaddedBottom">
                                    <div>Forgot your <a href="recover">password?</a></div>
                                </div>

                            </Form>
                        </div>
                    </div>
                </Fragment>
            )}
        </AuthConsumer>
    )
}

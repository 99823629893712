import React from "react";

import { UNITS } from "../../../../../Constants/appConstants";

export const HeaderUnitsCol = ({ results, building, unit }) => {
  return (
    <div className="building-units-col">
      <div className="building-units-row carbon-capture-text">
        {results[building].GWP.CSP < 0 && unit ? "CARBON CAPTURE!" : null}
      </div>
      <div className="building-units-row">
        {results[building].GWP.CSP < 0 && unit ? (
          <div className="carbon-capture-label">
            {Math.round(results[building].GWP.CSP) + " " + UNITS.GWP.unit}
          </div>
        ) : null}
      </div>
      <div className="building-units-row indicator-text">
        {unit ? UNITS.GWP.description : UNITS.PENRT.description}
      </div>
      <div className="building-units-row unit-text">
        {unit ? UNITS.GWP.unit : UNITS.PENRT.unit}
      </div>
    </div>
  );
};

export default HeaderUnitsCol;

import React, { Fragment } from "react";
import "./AboutPage.css";

import ContentHeader from "../../Common/ContentHeader";
import MinistryLogo from "../../../static/AboutPage/Ministry of environment.png";
import ObayashiLogo from "../../../static/AboutPage/obayashi-logo.png";
import OopeaaLogo from "../../../static/AboutPage/OopeaaLogo.png";

const AboutPage = () => {
  return (
    <Fragment>
      <ContentHeader text="ABOUT" />

      <div className="ContentContainer">
        <div className="ContentRow TextHead Underlined text-section-header">
          <span>BETA Disclaimer</span>
        </div>
        <div className="ContentRow">
          <span>
            Please note that this is a beta version of the JOKOTAI Material
            Impact Screener which is still undergoing final testing and editing
            before its official release. Should you encounter any bugs,
            glitches, lack of functionality or other problems on the website,
            please let us know immediately so we can rectify these accordingly.
            Your help in this regard is greatly appreciated! You can write to us
            at this address: jokotai@oopeaa.com
          </span>
        </div>
        <div className="ContentRow">
          <hr style={{ width: "100%" }} />
        </div>
        <div className="ContentRow text-section-content">
          <span>
            With the JOKOTAI Material Impact Screener OOPEAA aims to address the
            large environmental impact of buildings caused by embodied emissions
            in particular, in the production and processing of building
            materials (Life Cycle Phase A1-3). The tool enables architects in
            the early design phase to understand and compare the environmental
            impact of different structural and material choices of their design
            and communicate those to other architects, clients and builders.
            Architects need to be aware of their immense responsibility and
            potential in generating a change of direction towards more
            sustainable building practices.
          </span>
        </div>
        <div className="ContentRow text-section-content">
          <span>
            The JOKOTAI Material Impact Screener has been developed by OOPEAA in
            partnership with the Obayashi Corporation with support from the
            Growth and Development from Wood Program coordinated by the Ministry
            of Environment, Finland.
          </span>
        </div>
        <div className="ContentRow">
          <div className="ContentCol Centered">
            <img className="Logo" src={MinistryLogo} alt="MinistryLogo" />
          </div>
          <div className="ContentCol Centered">
            <img className="Logo" src={ObayashiLogo} alt="Obayashi" />
          </div>
        </div>
        <div className="ContentRow">
          <hr style={{ width: "100%" }} />
        </div>
        <div className="ContentRow" style={{ display: "block" }}>
          <img
            className="Logo"
            src={OopeaaLogo}
            alt="OOPEAA"
            style={{ maxWidth: 100, height: "auto" }}
          />
        </div>
        <div className="ContentRow" style={{ display: "block" }}>
          <div className="TextBlock">
            <div>© OOPEAA Office for Peripheral Architecture Oy 2020</div>
            <div>
              All rights reserved. Copying, modifying and any other unauthorized
              use strictly forbidden.
            </div>
            <div>
              Produced by OOPEAA in partnership with the Obayashi Corporation
              with support from the Ministry of Environment, Finland / Growth
              from Wood Program.
            </div>
            <br />
            <strong>JOKOTAI Team</strong>
            <ul className="TextList">
              <li>Anssi Lassila, Director, OOPEAA</li>
              <li>Katharina Heidkamp, Research, OOPEAA</li>
              <li>Tuomo Tammenpää, User Interface Design</li>
              <li>Lucas Rinne Toh, Programming</li>
              <li>Daniel Blackburn, 3D Development</li>
            </ul>
            <br />
            <a className="TextLink" href="https://www.oopeaa.com/">
              OOPEAA.com
            </a>
          </div>
        </div>
        <div className="ContentRow">
          <a href="/TOS">Terms of Use</a>
        </div>
        <div className="ContentRow">
          <a href="/privacypolicy">Privacy Policy</a>
        </div>
      </div>
    </Fragment>
  );
};

export default AboutPage;

import React, { Fragment } from "react";
import { ProjectProvider, ProjectConsumer } from "./ProjectContext";

// Views
import "./Project.css";
import IntroView from "./Views/0_IntroView";
import DimensionsView from "./Views/1_DimensionsView";
import DensityView from "./Views/2_DensityView";
import ConstructionView from "./Views/3_ConstructionView";
import OpeningsView from "./Views/4_OpeningsView";
import MaterialsView from "./Views/5_MaterialsView";
import ComparisonView from "./Views/6_ComparisonView";
import MobileRestriction from "./Views/Containers/MobileRestriction/MobileRestriction";
import TabletRestriction from "./Views/Containers/TabletRestriction";

export default function ProjectView() {
  return (
    <ProjectProvider>
      <ProjectConsumer>
        {({
          activeViewIndex,
          projectIsLoading,
          project,
          materials_list,
          latest_selection,
        }) =>
          projectIsLoading ? null : (
            <Fragment>
              <MobileRestriction />
              <TabletRestriction />
              <div className="ProjectApp">
                {
                  ViewList(project, materials_list, latest_selection)[
                    activeViewIndex
                  ]
                }
              </div>
            </Fragment>
          )
        }
      </ProjectConsumer>
    </ProjectProvider>
  );
}

const ViewList = (project, materials_list, latest_selection) => [
  <IntroView />,
  <DimensionsView />,
  <DensityView />,
  <ConstructionView />,
  <OpeningsView />,
  <MaterialsView />,
  <ComparisonView
    project={project}
    materials_list={materials_list}
    latest_selection={latest_selection}
  />,
];

import React, { Component } from 'react'

// 3D
import { BabylonView } from '../Scene/BabylonView';
import { CreateFoundation } from '../Functions/Foundation';
import { CreateHorizontalConstruction } from '../Functions/HorizontalConstruction';
import { CreateVerticalConstruction } from '../Functions/VerticalConstruction';
import { CreateFacade } from '../Functions/Facade';
import { StandardMaterial } from 'babylonjs';
import { colours } from '../../ProjectSettings';

export default class BuildingOpeningsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            view: null,
            foundation: null,
            horizontalMaterial: null,
            verticalMaterial: null,
            facadeMaterial: null,
            openingMaterial: null,
            vertical: null,
            horizontal: null,
            facade: null
        }
    }

    componentDidMount() {
        // Unpack values
        let { project } = this.props;
        let { width, depth, stories, story_height } = project;

        // Create view
        let view = new BabylonView("Openings");
        view.updateZoom(width, (story_height * stories), depth);

        // Create materials
        let horizontalMaterial = new StandardMaterial("horizontalMaterial", view.scene);
        horizontalMaterial.ambientColor = view.rgbToCol3(colours.grey80);
        let verticalMaterial = new StandardMaterial("verticalMaterial", view.scene);
        verticalMaterial.ambientColor = view.rgbToCol3(colours.grey80);
        let facadeMaterial = new StandardMaterial("facadeMaterial", view.scene);
        facadeMaterial.ambientColor = view.rgbToCol3(colours.white);
        let openingMaterial = new StandardMaterial("openingMaterial", view.scene);
        openingMaterial.ambientColor = view.rgbToCol3(colours.grey80);

        // Create geometry
        let foundation = CreateFoundation(project, view);
        let vertical = CreateVerticalConstruction(project, view, verticalMaterial);
        let horizontal = CreateHorizontalConstruction(project, view, horizontalMaterial);
        let facade = CreateFacade(project, view, facadeMaterial, openingMaterial, true);

        this.setState({
            view: view,
            foundation: foundation,
            horizontalMaterial: horizontalMaterial,
            verticalMaterial: verticalMaterial,
            facadeMaterial: facadeMaterial,
            openingMaterial: openingMaterial,
            vertical: vertical,
            horizontal: horizontal,
            facade: facade
        })
    }

    componentDidUpdate(prevProps) {
        let { openings } = this.props.project;
        if (openings !== prevProps.project.openings) {
            this.state.facade.dispose();
            let facade = CreateFacade(this.props.project, this.state.view, this.state.facadeMaterial, this.state.openingMaterial, true);
            this.setState({ facade: facade });
        }
    }

    render() {
        return (
            <canvas className="Canvas" id="Openings" />
        )
    }
}

import React, { Component } from 'react'

// 3D
import { BabylonView } from '../Scene/BabylonView';
import { CreateFoundation } from '../Functions/Foundation';
import { CreateHorizontalConstruction } from '../Functions/HorizontalConstruction';
import { CreateVerticalConstruction } from '../Functions/VerticalConstruction';
import { StandardMaterial } from 'babylonjs';
import { colours } from '../../ProjectSettings';

export default class BuildingConstructionView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            view: null,
            foundation: null,
            horizontalMaterial: null,
            verticalMaterial: null,
            horizontalConstruction: null,
            verticalConstruction: null
        }
    }

    componentDidMount() {
        let { project } = this.props;
        let { width, story_height, stories, depth } = project;
        let view = new BabylonView("Constructions");

        // update cameraZoom
        view.updateZoom(width, (story_height * stories), depth);

        // building parts materials
        let horizontalMaterial = new StandardMaterial("horizontalMaterial", view.scene);
        horizontalMaterial.ambientColor = view.rgbToCol3(colours.grey80);
        let verticalMaterial = new StandardMaterial("verticalMaterial", view.scene);
        verticalMaterial.ambientColor = view.rgbToCol3(colours.grey80);

        // building parts geometry
        let foundation = CreateFoundation(project, view);
        let horizontalConstruction = CreateHorizontalConstruction(project, view, horizontalMaterial);
        let verticalConstruction = CreateVerticalConstruction(project, view, verticalMaterial);

        this.setState({
            view: view,
            foundation: foundation,
            horizontalMaterial: horizontalMaterial,
            verticalMaterial: verticalMaterial,
            horizontalConstruction: horizontalConstruction,
            verticalConstruction: verticalConstruction
        })
    }

    componentDidUpdate(prevProps) {
        let { construction_type } = this.props.project;
        // Check if values have changed
        if (construction_type !== prevProps.project.construction_type) {
            this.state.verticalConstruction.dispose();
            this.state.horizontalConstruction.dispose();

            let horizontalConstruction = CreateHorizontalConstruction(this.props.project, this.state.view, this.state.horizontalMaterial);
            let verticalConstruction = CreateVerticalConstruction(this.props.project, this.state.view, this.state.verticalMaterial);
            this.setState({
                horizontalConstruction: horizontalConstruction,
                verticalConstruction: verticalConstruction
            });
        }
    }

    render() {
        return (
            <canvas className="Canvas" id="Constructions" />
        )
    }
}

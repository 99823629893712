import { Vector3, Mesh, MeshBuilder } from 'babylonjs';
import { buildingParams, colours } from '../../ProjectSettings';

// Create Internal Walls as dashed lines
export const CreateInternalDashedLines = (project, view, color) => {
    // Init
    let internalWalls = new Mesh("internal walls", view.scene);
    let gapSize = buildingParams.layout.gapSize;
    let { width, depth, density } = project;

    // Colours
    const grey20 = view.rgbToCol3(colours.grey20);

    // Calculation proportions
    let wallsX = Math.round(width / density);
    let wallRatio = buildingParams.layout.ratio;
    let wallsY = Math.round((width / density) / wallRatio)

    // Create dashed lines
    for (let x = 1; x < wallsX; x++) {
        let points = [
            new Vector3(-width / 2 + width / wallsX * x, 0, -depth / 2),
            new Vector3(-width / 2 + width / wallsX * x, 0, depth / 2),
        ]
        let dashedLine = MeshBuilder.CreateDashedLines("wallX", { points: points, gapSize: gapSize, dashSize: gapSize, dashNb: depth / 2 }, view.scene);
        dashedLine.color = grey20;
        dashedLine.parent = internalWalls;
    }

    for (let y = 1; y < wallsY; y++) {
        let points = [
            new Vector3(-width / 2, 0, -depth / 2 + depth / wallsY * y),
            new Vector3(width / 2, 0, -depth / 2 + depth / wallsY * y),
        ]
        let dashedLine = MeshBuilder.CreateDashedLines("wallX", { points: points, gapSize: gapSize, dashSize: gapSize, dashNb: width / 2 }, view.scene);
        dashedLine.color = grey20;
        dashedLine.parent = internalWalls;
    }

    return internalWalls;
}
import React, { PureComponent } from 'react'
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer, LabelList } from 'recharts';
import { material_types } from '../ProjectSettings';

import M1 from '../img/Material_Icons/Timber_Facade.png';
import M2 from '../img/Material_Icons/CLT.png';
import M3 from '../img/Material_Icons/Mineral_Wool.png';
import M4 from '../img/Material_Icons/Concrete.png';
import M5 from '../img/Material_Icons/Laminate.png';
import M6 from '../img/Material_Icons/Cement.png';
import M7 from '../img/Material_Icons/Bitumen.png';
import M8 from '../img/Material_Icons/Steel.png';

import M10 from '../img/Material_Icons/Brick.png';
import M11 from '../img/Material_Icons/Limestone.png';
import M12 from '../img/Material_Icons/Cellulose.png';
import M13 from '../img/Material_Icons/Wood_Fibre.png';
import M14 from '../img/Material_Icons/Foam_Glass.png';
import M15 from '../img/Material_Icons/EPS.png';
import M16 from '../img/Material_Icons/Cement_Render.png';
import M17 from '../img/Material_Icons/Glass_Alu.png';
import M18 from '../img/Material_Icons/Concrete_Facade.png';
import M19 from '../img/Material_Icons/Copper.png';
import M20 from '../img/Material_Icons/Brick_Facade.png';
import M21 from '../img/Material_Icons/Ceramic_Floor_Tiles.png';
import M22 from '../img/Material_Icons/Parquet.png';
import M23 from '../img/Material_Icons/Carpet.png';
import M24 from '../img/Material_Icons/Zinc.png';
import M25 from '../img/Material_Icons/Green_Roof.png';
import M26 from '../img/Material_Icons/Shingles.png';
import M27 from '../img/Material_Icons/Tiles.png';
import M28 from '../img/Material_Icons/Rammed_Earth.png';
import M29 from '../img/Material_Icons/Cork.png';
import M30 from '../img/Material_Icons/Timber.png';
import M31 from '../img/Material_Icons/Glass.png';
import M32 from '../img/Material_Icons/Concrete.png';       // NB.  We need a new Concrete foundation icon
import M33 from '../img/Material_Icons/CLT.png';

import M35 from '../img/Material_Icons/Concrete.png';

import M40 from '../img/Material_Icons/Glass.png';          // N.B We either need a new icon for 'No Insulation' or we need to add a special case to remove it from the graph.


export default class MaterialsGraph extends PureComponent {
    render() {

        const { results, building, side, unit, materials_list } = this.props;
        const data = dictToList(results, building, unit, side, materials_list);

        return (
            <ResponsiveContainer height='100%' width='100%' minWidth={850} minHeight={500}  >
                <BarChart data={data} barCategoryGap={'8%'} margin={{ top: 100, right: this.props.side === "left" ? 100 : 200, left: this.props.side === "left" ? 200 : 100, bottom: 200, }}>
                    <CartesianGrid strokeDasharray="3 3" vertical={false} />
                    <XAxis dataKey="name" tick={<CustomAxisLabel data={data} />} axisLine={false} tickLine={false} interval={0}/>
                    <YAxis unit="%" orientation={this.props.side === "left" ? "right" : "left"} axisLine={true} tickLine={false} domain={[0, 80]} fontSize={12} />
                    <Legend content={<div style={{ transform: `translate(${this.props.side === "left" ? 50 : -50}px,${70}px)`, fontSize: 15 }}>{this.props.unit ? <React.Fragment>CO2e kg/m2</React.Fragment> : <React.Fragment>MJ/m2</React.Fragment>}</div>} align={this.props.side === "left" ? "right" : "left"} />
                    <Bar dataKey="percentage" name={this.props.unit ? "GWP" : "PENRT"}>
                        {
                            data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.color} />
                            ))
                        }
                        <LabelList dataKey="percentage" content={CustomizedListLabel} />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        )
    }
}


class CustomAxisLabel extends PureComponent {
    render() {
        const { x, y, payload, width, data } = this.props;
        const material = data[payload.index];
        const barWidth = width / data.length;

        return (
            <g transform={`translate(${x},${y})`}>
                <image y={10} href={iconsDict(material.id)} width={(barWidth)} transform={"translate(-" + (barWidth / 2).toString() + ",-20)"} ></image>
                <text x={0} y={0} dy={110} textAnchor="middle" fontSize="30px">{Math.round(this.props.data[payload.index].numericValue)}</text>
            </g>
        )
    }
}

const CustomizedListLabel = (props) => {
    const { x, y, width, value } = props;
    const offset = 8;
  
    return (
      <g>
        <text x={x + width / 2} y={y - offset} fill="#000" textAnchor="middle" dominantBaseline="middle" fontSize="10px" fontWeight="bold" >
          {value + '%'}
        </text>
      </g>
    );
  };

const dictToList = (results, building, unit, side, materials_list) => {
    let percentageList = []
    let sumValue = unit ? results[building].GWP.EMI : results[building].PENRT;
    let materials = results[building].materials;

    // Calculate item value
    for (let [key, value] of Object.entries(materials)) {
        let material_id = key;
        let material_obj = materials_list[material_id];
        let material_impact = unit ? value.GWP.EMI : value.PENRT;

        let material_type = material_obj.material_type;
        let type_color = material_types[material_type].colour;
        let type_color_hex = rgbToHex(Math.round(type_color.r * 255), Math.round(type_color.g * 255), Math.round(type_color.b * 255));

        let newObj = { id: key, name: material_obj.name, percentage: Math.round((material_impact / sumValue) * 100), numericValue: material_impact, icon: material_obj.icon, color: type_color_hex };
        percentageList.push(newObj);
    };

    // Sort list by size and orientation
    if (side === "left") {
        percentageList.sort((a, b) => (a.percentage > b.percentage) ? 1 : ((b.percentage > a.percentage) ? -1 : 0));
    } else {
        percentageList.sort((a, b) => (a.percentage < b.percentage) ? 1 : ((b.percentage < a.percentage) ? -1 : 0));
    }

    return percentageList;
};

/*
const ReverseDict = (obj) => {
    const ret = {};
    Object.keys(obj).forEach(key => {
        ret[obj[key]] = key;
    });
    return ret;
}
*/

function componentToHex(c) {
    var hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
}

function rgbToHex(r, g, b) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

const iconsDict = (materialID) => {
    switch (materialID) {
        case "1":
            return M1;
        case "2":
            return M2;
        case "3":
            return M3;
        case "4":
            return M4;
        case "5":
            return M5;
        case "6":
            return M6;
        case "7":
            return M7;
        case "8":
            return M8;

        case "10":
            return M10;
        case "11":
            return M11;
        case "12":
            return M12;
        case "13":
            return M13;
        case "14":
            return M14;
        case "15":
            return M15;
        case "16":
            return M16;
        case "17":
            return M17;
        case "18":
            return M18;
        case "19":
            return M19;
        case "20":
            return M20;
        case "21":
            return M21;
        case "22":
            return M22;
        case "23":
            return M23;
        case "24":
            return M24;
        case "25":
            return M25;
        case "26":
            return M26;
        case "27":
            return M27;
        case "28":
            return M28;
        case "29":
            return M29;
        case "30":
            return M30;
        case "31":
            return M31;
        case "32":
            return M32;
        case "33":
            return M33;

        case "35":
            return M35;

        case "40":
            return M40;

        default:
            return;
    }
}
import React from "react";
import "./ImpactGraph.css";

import ImpactSaved from "./ImpactSaved";
import ImpactInfo from "./ImpactInfo";

const ImpactGraph = ({ project, results, side, unit }) => {
  const building1_value = unit
    ? results["building1"].GWP.EMI
    : results["building1"].PENRT;
  const building2_value = unit
    ? results["building2"].GWP.EMI
    : results["building2"].PENRT;

  return (
    <div className="impact-graph">
      {side === "left" ? (
        building1_value <= building2_value ? (
          <ImpactSaved project={project} unit={unit} results={results} />
        ) : (
          <ImpactInfo />
        )
      ) : building1_value > building2_value ? (
        <ImpactSaved project={project} unit={unit} results={results} />
      ) : (
        <ImpactInfo />
      )}
    </div>
  );
};

export default ImpactGraph;

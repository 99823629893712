import React, { Fragment } from 'react';
import { AuthConsumer } from '../AuthContext';
import ContentHeader from '../../Layout/Common/ContentHeader';

import DeleteUserModal from './Modals/DeleteUserModal';
import ResendVerificationModal from './Modals/ResendVerificationModal';
import ChangePasswordModal from './Modals/ChangePasswordModal';

export default function ProfilePage() {
    return (
        <AuthConsumer>
            {({ user }) => (
                <Fragment>
                    <ContentHeader text="Profile" />

                    <div className="ContentContainer">
                        <div className="ContentRow">
                            <div className="ProfileList">
                                
                                {/* Verified */}
                                <div className="ListItem">
                                    <div className="Content">
                                        <div className="Title">Email verification</div>
                                        <div className="Subtitle">{user.emailVerified ? "Your email is verified" : "Your email is not verified"}</div>
                                    </div>
                                    <div className="Actions">
                                        <ResendVerificationModal />
                                    </div>
                                </div>

                                {/* Email */}
                                <div className="ListItem">
                                    <div className="Content">
                                        <div className="Title">Email address</div>
                                        <div className="Subtitle">{user.email}</div>
                                    </div>
                                    <div className="Actions">
                                        <button className="Button">Change</button>
                                    </div>
                                </div>

                                {/* Password */}
                                <div className="ListItem">
                                    <div className="Content">
                                        <div className="Title">Change password</div>
                                        <div className="Subtitle">Password must be at least 6 characters long</div>
                                    </div>
                                    <div className="Actions">
                                        <ChangePasswordModal />
                                    </div>
                                </div>

                                {/* Delete account */}
                                <div className="ListItem">
                                    <div className="Content">
                                        <div className="Title">Delete account</div>
                                        <div className="Subtitle">This action cannot be undone!</div>
                                    </div>
                                    <div className="Actions">
                                        <DeleteUserModal />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </Fragment>
            )}
        </AuthConsumer>
    )
}

import React, { Fragment } from 'react'
import { ProjectConsumer } from '../ProjectContext';

// Settings
import { ProjectSettings } from '../ProjectSettings';

// 3D
import BuildingDensityView from '../Geometry/Views/BuildingDensityView';

// UI
import DescriptionFooter from './Common/DescriptionFooter';
import InputSlider from './Common/InputSlider';

function DensityView() {
    return (
        <ProjectConsumer>
            {({ project, handleSliderChange }) => (

                <Fragment>
                    <div className="ProjectHeadContainer">
                        <div className="TextContainer">
                            <div className="Title"><strong>Structural density</strong></div>
                        </div>
                    </div>

                    <div className="ProjectContentContainer">
                        <BuildingDensityView project={project} />

                        <div className="ProjectTooltipContainer">Move sliders to define the building’s approximate structural density</div>

                        <div className="ProjectSelectionContainer">
                            <div className="ProjectSelectionPadding">
                                <InputSlider
                                    icon={null}
                                    value={project.density}
                                    unit="m"
                                    min={ProjectSettings.sliders.buildingDensityMin}
                                    max={ProjectSettings.sliders.buildingDensityMax}
                                    propName="density"
                                    changeHandler={handleSliderChange}
                                />
                            </div>
                        </div>

                    </div>

                    <DescriptionFooter title="Structural density" description="To estimate the mass of internal structural divisions, an abstract building layout is defined. The layout represents a rough estimate of structural divisions in form of post and beams or load-bearing walls, instead of defining the exact floor plan of the design. A compact layout density might apply to residential buildings, a medium density to office or retail buildings and a loose density to public or industrial buildings." />

                </Fragment>
            )}
        </ProjectConsumer>
    )
}

export default DensityView;
import React from "react";

import "./IconsRow.css";
import Area_Logo from "../../../../../img/UI_Misc/Area.png";
import Density_Logo from "../../../../../img/UI_Misc/Density.png";
import Openings_Logo from "../../../../../img/UI_Misc/Openings.png";
import C1_Icon from "../../../../../img/Constructions/c1_small.png";
import C2_Icon from "../../../../../img/Constructions/c2_small.png";
import C3_Icon from "../../../../../img/Constructions/c3_small.png";

const construction_icon_dict = {
  0: { icon: C1_Icon, text: "Modular" },
  1: { icon: C2_Icon, text: "Element" },
  2: { icon: C3_Icon, text: "Frame" },
};

const IconsRow = ({ project }) => (
  <div className="icons-row">
    <div className="icons-cell">
      <figure className="icons-figure">
        <img className="icons-image" src={Area_Logo} alt="Area" />
        <figcaption className="icons-text">
          {project.width * project.depth * project.stories} m&sup2;
        </figcaption>
      </figure>
    </div>

    <div className="icons-cell">
      <figure className="icons-figure">
        <img className="icons-image" src={Density_Logo} alt="Density" />
        <figcaption className="icons-text">{project.density} m</figcaption>
      </figure>
    </div>

    <div className="icons-cell">
      <figure className="icons-figure">
        <img
          className="icons-image"
          src={construction_icon_dict[project.construction_type].icon}
          alt="Openings"
        />
        <figcaption className="icons-text">
          {construction_icon_dict[project.construction_type].text}
        </figcaption>
      </figure>
    </div>

    <div className="icons-cell">
      <figure className="icons-figure">
        <img className="icons-image" src={Openings_Logo} alt="Openings" />
        <figcaption className="icons-text">{project.openings}%</figcaption>
      </figure>
    </div>
  </div>
);

export default IconsRow;

import React, { Fragment } from "react";
import ContentHeader from "../Common/ContentHeader";

const TOSPage = () => {
  return (
    <Fragment>
      <ContentHeader text="Terms of Use" />

      <div className="ContentContainer">
        {sections.map((section) => (
          <div
            className="ContentRow"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div style={{ fontWeight: 700 }}>{section.title}</div>
            <div style={{ fontWeight: 500 }}>{section.content}</div>
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default TOSPage;

const sections = [
  {
    title: "Acceptance of Terms Through Use",
    content: (
      <Fragment>
        <div>
          Please read these Terms of Use carefully before using the JOKOTAI
          Material Impact Screener website. Your use of this site indicates your
          agreement to be bound by all terms, conditions and notices contained
          or referenced in the currently posted Terms of Use which at all times
          govern the use of the offered services.
        </div>
        <br />
        <div>
          If you do not agree to these Terms of Use, please do not use this
          site.
        </div>
        <br />
        <div>
          Please note that OOPEAA Office for Peripheral Architecture reserves
          the right to edit, modify or otherwise change or update these Terms of
          Use from time to time. Please check the Terms of Use periodically for
          changes. We shall let our users know of these changes through
          electronic mail.
        </div>
        <br />
        <div>
          Your continued use of this Site following the posting of any changes
          to the Terms of Use will confirm your acceptance of those changes.
          This agreement is an understanding between OOPEAA Office for
          Peripheral Architecture and the user, and supersedes and replaces all
          prior agreements regarding the use of this website.
        </div>
      </Fragment>
    ),
  },
  {
    title: "Title and Ownership & Intellectual Property",
    content: (
      <Fragment>
        <div>
          All right, title and interest in and to the website and to the content
          and other information and materials available on this website,
          including but not limited to underlying software as well as user
          interface design, are proprietary to OOPEAA JOKOTAI Material Impact
          Screener and OOPEEAA Office for Peripheral Architecture (a limited
          liability company registered in Finland, see below) and protected by
          worldwide copyright laws and international copyright treaties, as well
          all specific and applicable intellectual property laws.
        </div>
        <br />
        <div>
          <strong>Therefore</strong>, you hereby agree that all materials,
          products, and services provided on this website are the property of
          OOPEAA Office for Peripheral Architecture (or its affiliates,
          suppliers or licensors or other business partners, as the case may be)
          whether protected by or as copyrights, trade secrets, trademarks,
          patents and/or other intellectual property regimes. You also agree
          that you will not reproduce or redistribute this intellectual property
          in any way (electronically, digitally or otherwise), or use it in or
          for new trademark or other intellectual property registrations or
          claim or seek to ascertain any other rights to it.
        </div>
        <br />
        <div>
          Moreover, you grant OOPEAA Office for Peripheral Architecture a
          royalty-free and non-exclusive perpetual license to display, use,
          copy, transmit, and broadcast the content you upload and publish. For
          issues regarding intellectual property claims, you should contact
          OOPEEAA Office for Peripheral Architecture in order to come to an
          agreement.
        </div>
      </Fragment>
    ),
  },
  {
    title: "User Accounts, Password-Protected Areas and Access",
    content: (
      <Fragment>
        <div>
          The service of user registration and project management available on
          this website are password-protected. In other words, as a user of this
          website, you will be asked to register with us and provide private
          information. You are responsible for ensuring the accuracy of this
          information, and you are responsible for your part for maintaining the safety and
          security of your identifying information. You are also responsible for
          all activities that occur under your account or password.
        </div>
        <br />
        <div>
          If you think there are any possible issues regarding the security of
          your account on the website, inform us immediately so we may address
          it accordingly.
        </div>
        <br />
        <div>
          We reserve all rights to terminate accounts, edit or remove content
          and cancel orders in our sole discretion.
        </div>
      </Fragment>
    ),
  },

  {
    title: "Open Source Tool",
    content:
      "The OOPEAA JOKOTAI Material Impact Screener is an open source tool. Therefore, no license needs to be purchased for using it.",
  },
  {
    title: "User Support",
    content: (
      <Fragment>
        <div>
          For the purposes of user support, a list of Frequently Asked Questions
          is provided in the tool. For additional support, the users can contact
          the OOPEAA JOKOTAI team by sending an email to {" "}
          <a href="mailto:jokotai@oopeaa.com">jokotai@oopeaa.com</a>.
        </div>
      </Fragment>
    ),
  },
  {
    title: "Maintenance and Errors",
    content: (
      <Fragment>
        We engage in constant maintenance of the website and are committed to a
        continuous process of elimination of any errors that are brought to our
        attention.
      </Fragment>
    ),
  },
  {
    title: "Copyright Information",
    content: "© OOPEAA Office for Peripheral Architecture Oy 2021",
  },
  {
    title: "Applicable Law",
    content:
      "By using this website, you agree that the laws of Finland, without regard to principles of conflict laws, will govern these terms and conditions, or any dispute of any sort that might come between OOPEAA JOKOTAI Material Impact Screener (or its business partners and/or affiliates, as the case may be) and you.",
  },
  {
    title: "Disputes",
    content:
      "Any dispute related in any way to your use or misuse of this website or the contents thereof shall be resolved by the Helsinki District Court as the first instance, and you consent to the exclusive jurisdiction and venue of such court. The Company may, at any time, seek also interim relief in the competent court or courts of your jurisdiction.",
  },
  {
    title: "Indemnification",
    content:
      "You agree to indemnify OOPEAA Office for Peripheral Architecture (and its business partners and/or affiliates, as the case may be) harmless against legal claims and demands that may arise from your use or misuse of our services. We reserve the right to proceed with any such claims in our sole discretion.",
  },
  {
    title: "Limitation on Liability",
    content:
      "OOPEAA Office for Peripheral Architecture is never liable for any damages that may occur to you as a result of your use of the website. Note also that, OOPEAA Office for Peripheral Architecture does not guarantee the accuracy of the information provided on the OOPEAA JOKOTAI Material Impact Screener, and you will have no legal recourse against or right for remedy towards the Company.",
  },
];

import React, { Fragment } from "react";
import ContentHeader from "../Common/ContentHeader";

const PrivacyPolicyPage = () => {
  return (
    <Fragment>
      <ContentHeader text="Privacy Policy" />

      <div className="ContentContainer">
        <div
          className="ContentRow"
          style={{ display: "flex", flexDirection: "column", fontWeight: 700 }}
        >
          <div>Privacy Policy and Personal Data Processing</div>
          <div>OOPEAA Office for Peripheral Architecture Oy</div>
          <div>Kauppakatu 19 A, 60100 Seinäjoki, Finland</div>
          <div>+358 6414 1225</div>
        </div>

        {sections.map((section) => (
          <div
            className="ContentRow"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div style={{ fontWeight: 700 }}>{section.title}</div>
            <div style={{ fontWeight: 500 }}>{section.content}</div>
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default PrivacyPolicyPage;

const sections = [
  {
    title: "Usage Information",
    content:
      "We capture information that helps us understand your experience with JOKOTAI, so that we can offer you more helpful content to help you get better at using the software you are working with.",
  },
  {
    title: "Profile Information",
    content:
      "We store information you provide to us to maintain an user account that saves and collects the projects that you have created. We use your email address to communicate with you in relation with feedback or if you so choose, you can be part of the mailing list to be informed about the latest updates of the tool. We use your information to provide helpful content to help you get better at the software you are working with, and you will always have the ability to update, remove, or add information as desired. We will store your personal data for as long as is necessary to fulfil the purpose of the processing. We take a variety of security measures to protect your personal information. For example, we use encryption to protect sensitive data transmitted over the Internet. Only employees who need the information to perform their job will have access to personal data. The IT systems used to store personal data are protected by technical and organisational measures.",
  },
  {
    title: "What We Collect",
    content: (
      <Fragment>
        <div>
          JOKOTAI uses email authentication and stores user information within
          the product. It collects your:{" "}
        </div>
        <ul>
          <li>email address</li>
          <li>IP address</li>
        </ul>
      </Fragment>
    ),
  },
  {
    title: "How We Use That Information",
    content: (
      <div>
        <p>
          This information is required to utilize the product which requires
          authentication (sign in). JOKOTAI uses the information for project
          management and file storage and for feedback. JOKOTAI uses email
          addresses for technical support if requested. JOKOTAI collects usage
          information, such as user activity, performance metrics data, and log
          information, which includes IP addresses, browser type, and error and
          crash information.
        </p>
        <p>
          JOKOTAI uses the information to provide user accounts with individual
          project libraries for project management and file storage. Information
          can be used if support for the usage of the program is requested.
          Usage information is used to understand product usage and to improve
          the product’s performance. We may disclose your personal information
          to our personal data assistants, e.g. companies that work with invoice
          administration or IT and cloud services. In such cases, personal data
          access agreements are entered into which ensure that your personal
          data is processed only in accordance with this policy. We do not sell,
          trade or otherwise transfer your personal information to third
          parties. We do not transfer any personal information outside of the
          EU.
        </p>
      </div>
    ),
  },
  {
    title: "How To Access, Update and Protect Information",
    content:
      "You can access your account information by logging into your user account and clicking on the user account button in the top right hand corner of the page. To edit information, please use the edit option next to the text fields. To delete your account, please use the “delete account” option. ",
  },
  {
    title: "Your Rights",
    content: (
      <Fragment>
        <div>
          You have the right to receive confirmation of whether we process
          personal data relating to you, and in that case to access this
          personal data and information regarding our handling of them. You have
          the right to have incorrect personal information corrected by us
          without undue delay.
        </div>
        <br />
        <div>
          You have the right, under certain circumstances, to have your personal
          data deleted by us, e.g. if the personal data is no longer necessary
          to fulfil the purposes for which it was collected, or if the personal
          data was processed illegally.
        </div>
        <br />
        <div>
          You have the right to demand that we limit the processing of your
          personal data in certain cases. For example, If you dispute the
          accuracy of the personal data, you may require that we limit the
          processing of the data during the time it takes for us to verify that
          the information is accurate. You have also the right to object to the
          processing of your personal data on the basis of your legitimate
          interest. You have always the right to object to the processing of
          your personal data insofar as the processing takes place for direct
          marketing purposes.
        </div>
        <br />
        <div>
          If you have any comments on our processing of your personal data, you
          are welcome to contact us. You also have always the right to submit a
          complaint to the Office of the Data Protection Ombudsman of Finland.
        </div>
      </Fragment>
    ),
  },
  {
    title: "Cookies",
    content: (
      <Fragment>
        <div>
          The site uses so-called cookies in order to personalise your user
          experience and improve the website. Cookies are small text files
          stored on our visitors’ computer that can be used to track what a
          visitor does on our site. There are two types of cookies: (i) a
          permanent cookie that remains on the visitor’s computer for a
          specified period of time and (ii) a session cookie stored only during
          the visit to the site. Session cookies disappear when you close your
          browser.
        </div>
        <br />
        <div>
          Cookies are processed with the support of your consent. If you do not
          accept the use of cookies, you can turn cookies off in the security
          settings of your browser. You can also set your browser so that you
          receive a query every time a site tries to place a cookie on your
          computer.
        </div>
        <br />
        <div>
          The browser can also delete previously stored cookies. See your
          browser’s help pages for more information. Furthermore, you can
          manually delete cookies from your hard drive at any time. Please note
          that if you choose not to accept cookies, functionality may be
          restricted on certain websites.
        </div>
        <br />
        <div>
          We use the following cookies:
          <ul>
            <li>
              Google Analytics/Firebase is used to measure the number of
              visitors, navigation, organisations’ IP addresses and to keep
              statistics.
            </li>
          </ul>
        </div>
      </Fragment>
    ),
  },
];

import React, { Fragment } from 'react';
import { ProjectConsumer } from '../ProjectContext';

// 3D
import BuildingMaterialsView from '../Geometry/Views/BuildingMaterialsView';

// UI
import DescriptionFooter from './Common/DescriptionFooter';
import MaterialButton from './Common/MaterialButton';

function MaterialsView() {
    return (
        <ProjectConsumer>
            {({ project, materials, materials_list, handleMaterialChange }) => (

                <Fragment>
                    <div className="ProjectHeadContainer">
                        <div className="TextContainer">
                            <div className="Title"><strong>Materials</strong></div>
                        </div>
                    </div>


                    <div className="ProjectContentContainer">
                        <BuildingMaterialsView project={project} materials_list={materials_list} option="Materials" />
                        <div className="ProjectTooltipContainer">Toggle the material buttons to choose a material for each building part</div>

                        <div className="ProjectSelectionContainer VerticalPadded">
                            <div className="ButtonCollection">
                                <MaterialButton project={project} category="Foundation" materials={materials} materials_list={materials_list} building="building1" changeHandler={handleMaterialChange} />
                                <MaterialButton project={project} category="Vertical structure" materials={materials} materials_list={materials_list} building="building1" changeHandler={handleMaterialChange} />
                                <MaterialButton project={project} category="Horizontal structure" materials={materials} materials_list={materials_list} building="building1" changeHandler={handleMaterialChange} />
                                <MaterialButton project={project} category="Insulation" materials={materials} materials_list={materials_list} building="building1" changeHandler={handleMaterialChange} />
                                <MaterialButton project={project} category="Facade" materials={materials} materials_list={materials_list} building="building1" changeHandler={handleMaterialChange} />
                                <MaterialButton project={project} category="Floor" materials={materials} materials_list={materials_list} building="building1" changeHandler={handleMaterialChange} />
                                <MaterialButton project={project} category="Roof" materials={materials} materials_list={materials_list} building="building1" changeHandler={handleMaterialChange} />
                            </div>
                        </div>

                    </div>

                    <DescriptionFooter title="Materials" description="The initial environmental impact of the defined building volume is now assessed by selecting a specific material for the foundation, each of the horizontal and vertical structural elements, insulation, facade, floor and roof. The materials are visualized in seven different colors that each represent one of the resource types: plant, soil, mineral, metal, oil, glass and mixed. Each of the materials is linked to an environmental product declaration (EPD). After having selected the initial materials for the building, a comparison with alternative material choices can be made in the next step." />
                </Fragment>

            )}
        </ProjectConsumer>
    )
}

export default MaterialsView;
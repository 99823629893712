import React, { createContext, Component } from "react";
import { withRouter } from "react-router-dom";
import firebase from "firebase";
import { firebaseAuth } from "../Firebase/FirebaseConfig";
import {
  construction_types,
  building_part_dict,
} from "../Project/ProjectSettings";
import _ from "lodash";
import {
  CreateProject,
  DeleteProject,
  GetProjects,
  UpdateProject,
  GetMaterials,
  AddToMailingList,
} from "../Firebase/FirebaseAPI";

class _AuthProvider extends Component {
  constructor() {
    super();
    this.state = {
      // Auth state
      isLoading: true,
      isAuth: false,
      user: null,
      global_message: null,

      // Project state
      projects: [],
      project: {},
      initial_project: {},
      materials: {
        Roof: [],
        Floor: [],
        Facade: [],
        Insulation: [],
        "Vertical structure": [],
        "Horizontal structure": [],
        Openings: [],
        Foundation: [],
      },
      materials_list: {},
      latest_selection: null,

      // errors
      registrationMessage: null,
    };
  }

  componentDidMount() {
    this.CheckAuthorization();
  }

  // Auth Functions
  CheckAuthorization = () => {
    firebaseAuth.onAuthStateChanged((user) => {
      try {
        if (user) {
          GetProjects((projects) => {
            GetMaterials((materials) => {
              // Get the latest updated project as the active project
              let project = projects.length === 0 ? {} : projects[0];
              let materials_list = {};

              // Create a dictionary for materials
              let materialsDict = { ...this.state.materials };

              materials.forEach((material) => {
                // Add material to material dictionary
                materialsDict[material["material_part"]].push(material.id);
                materials_list[material.id] = material;
              });
              const defaultLatestSelection = !_.isEmpty(project)
                ? materials_list[project["vertical_a"]]
                : materials[11];

              // Update state
              this.setState(
                {
                  isAuth: true,
                  user: user,
                  isLoading: false,
                  projects: projects,
                  project: project,
                  initial_project: project,
                  materials: materialsDict, // Material ID's grouped by building parts + array
                  materials_list: materials_list, // Material data
                  latest_selection: defaultLatestSelection,
                  save_timer: Date.now(),
                },
                () =>
                  // save active project every 20 minutes
                  (this.timerId = setInterval(
                    () => this.SaveProject(),
                    1000 * 20
                  ))
              );
            });
          });
        } else {
          this.setState({
            isAuth: false,
            user: {},
            isLoading: false,
          });
        }
      } catch (error) {
        this.setState({
          isAuth: false,
          user: {},
          isLoading: false,
        });
      }
    });
  };

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  SetGlobalMessage = (message) => {
    this.setState({ global_message: message });
  };

  // Redirect to project (set project as active and determine which view to start at)
  RedirectToProject = (project, viewIndex = 6) => {
    this.setState({ project: project }, () => {
      this.props.history.push({
        pathname: "/project",
        state: { view: viewIndex },
      });
    });
  };

  RedirectToCreateNewProject = () => {
    this.props.history.push({
      pathname: "/projects",
      state: { modalState: true },
    });
  };

  SetActiveProject = (project, callback) => {
    this.setState({ project: project }, () => {
      this.props.history.push({
        pathname: "/project",
        state: { view: 6 },
      });
      callback();
    });
  };

  Login = (email, password) => {
    firebaseAuth
      .signInWithEmailAndPassword(email, password)
      .then((user) => {
        this.setState(
          {
            user: user.user,
            isAuth: true,
            global_message: "Welcome back",
          },
          () => {
            this.props.history.push("/projects");
          }
        );
      })
      .catch((error) => {
        this.setState({ global_message: error.message });
      });
  };

  Logout = () => {
    firebaseAuth.signOut().then(() => {
      this.setState({
        isAuth: false,
        user: null,
        global_message: "Succesfully signed out",
      });
      this.props.history.push("/");
    });
  };

  Register = (email, password, password2, mailingList, callback) => {
    // Check for password match
    if (password !== password2) {
      this.setState({ global_message: "Error: Passwords don't match" });
    } else {
      firebaseAuth
        .createUserWithEmailAndPassword(email, password)
        .then((response) => {
          let user = response.user;
          user.sendEmailVerification();

          if (mailingList) {
            AddToMailingList(user.uid);
          }

          this.setState({
            isAuth: true,
            user: user,
            global_message:
              "A confirmation email has been sent to your email address",
            registrationMessage: null,
          });
          this.props.history.push("/");
          callback();
        })
        .catch((error) => {
          this.setState({
            registrationMessage: { type: "Error", message: error.message },
          });
        });
    }
  };

  DeleteUser = (password) => {
    let user = firebaseAuth.currentUser;
    let credentials = firebase.auth.EmailAuthProvider.credential(
      user.email,
      password
    );

    // Re-authenticate user
    user.reauthenticateWithCredential(credentials).then(() =>
      // Delete user
      user
        .delete()
        .then((response) => {
          this.props.history.push("/");
        })
        .catch((error) => {})
    );
  };

  ResendVerificationEmail = () => {
    let user = firebaseAuth.currentUser;
    user.sendEmailVerification();
    this.setState({
      global_message:
        "A confirmation email has been sent to your email address",
    });
  };

  ChangeUserPassword = (old_password, new_password, new_password2) => {
    if (new_password !== new_password2) {
      this.setState({ global_message: "Passwords don't match. Try again" });
    } else {
      let user = firebaseAuth.currentUser;

      // Get user credentials
      let credentials = firebase.auth.EmailAuthProvider.credential(
        user.email,
        old_password
      );

      // Reauthenticate user
      user
        .reauthenticateWithCredential(credentials)
        .then(() => {
          // Set new password
          user
            .updatePassword(new_password)
            .then(() => {
              this.setState({
                global_message: "Your password has successfully been changed",
              });
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  RecoverUserAccount = (email) => {
    firebaseAuth
      .sendPasswordResetEmail(email)
      .then(() => {
        this.setState({
          global_message:
            "A reset password email has been sent to your email address",
        });
      })
      .catch((error) => {
        this.setState({ global_message: error.message });
      });
  };

  ResetUserPassword = () => {
    let user = firebaseAuth.currentUser;
    firebaseAuth.sendPasswordResetEmail(user.email).then(() =>
      this.setState({
        global_message:
          "A reset password email has been sent to your email address",
      })
    );
  };

  // Project functions
  AddProject = (data) => {
    if (data.name === "") {
      this.setState({ global_message: "Project name must not be empty" });
    } else {
      CreateProject(data, (project) => {
        let projects = [...this.state.projects, project];
        this.setState({ projects: projects }, () =>
          this.RedirectToProject(project, 0)
        );
      });
    }
  };

  RemoveProject = (projectId) => {
    DeleteProject(projectId, () => {
      let projects = this.state.projects.filter(
        (project) => project.id !== projectId
      );
      this.setState({
        projects: projects,
        global_message: "Project successfully deleted",
      });
    });
  };

  SaveProject = () => {
    if (this.state.project !== null) {
      if (this.state.initial_project !== this.state.project) {
        UpdateProject(this.state.project.id, this.state.project, (project) => {
          let projectId = project.id;
          let projects = [...this.state.projects];
          let projectIndex = projects.findIndex(
            (project) => project.id === projectId
          );
          projects[projectIndex] = project;
          this.setState({
            project: project,
            initial_project: project,
            projects: projects,
          });
        });
      }
    }
  };

  PartialUpdateProject = (prop, e) => {
    let projectId = this.state.project.id;

    let value;
    // Checks for slider change
    if (e.target.type === "range") {
      value = parseFloat(e.target.value);
    }

    // Write data to db
    let data = { [prop]: value };
    UpdateProject(projectId, data, (project) => {
      this.setState({ project: project });
    });
  };

  // This function updates the name of a project which is not the active project
  UpdateProjectName = (projectId, name, callback) => {
    let data = { name: name };
    UpdateProject(projectId, data, (project) => {
      let projects = [...this.state.projects];
      let projectIndex = projects.findIndex(
        (projectObj) => projectObj.id === projectId
      );
      projects[projectIndex] = project;
      this.setState({ projects: projects });
      callback();
    });
  };

  // This handler changes state without writing to db
  handleSliderChange = (e) => {
    let project = { ...this.state.project };
    project[e.target.name] = parseFloat(e.target.value);
    this.setState({ project: project });
  };

  // This handler changes construction type without writing to db
  handleConstructionChange = (index) => {
    let latest_selection = { ...this.state.latest_selection };

    let project = { ...this.state.project };
    project["construction_type"] = index;

    // Check material locks for current materials
    const materialLocks = construction_types[index].material_locks;
    const buildings = [
      { name: "building1", suffix: "_a" },
      { name: "building2", suffix: "_b" },
    ];
    for (let building of buildings) {
      for (const [category, part] of Object.entries(building_part_dict)) {
        const material_id = parseInt(project[part + building.suffix]);
        if (materialLocks.indexOf(material_id) > -1) {
          const category_materials = this.state.materials[category];
          const cleaned_category_materials = [];
          for (let category_material of category_materials) {
            cleaned_category_materials.push(parseInt(category_material));
          }
          let material_category_index = cleaned_category_materials.indexOf(
            material_id
          );
          if (material_category_index > -1) {
            let next_index = material_category_index;
            while (
              materialLocks.includes(cleaned_category_materials[next_index])
            ) {
              if (next_index >= cleaned_category_materials.length - 1) {
                next_index = 0;
                if (
                  !materialLocks.includes(
                    cleaned_category_materials[next_index]
                  )
                ) {
                  break;
                }
              }
              next_index++;
            }
            project[part + building.suffix] =
              cleaned_category_materials[next_index];
            latest_selection = this.state.materials_list[
              cleaned_category_materials[next_index]
            ];
          } else {
            console.log("Unable to find locked material in dictionary");
          }
        }
      }
    }
    this.setState({ project: project, latest_selection: latest_selection });
  };

  // This handler changes material without writing to db
  handleMaterialChange = (prop, value) => {
    // Set project material by material id
    let project = { ...this.state.project };
    project[prop] = value;

    // Set latest selected material
    let latest_selection = this.state.materials_list[value];

    this.setState({ project: project, latest_selection: latest_selection });
  };

  render() {
    return (
      <AuthContext.Provider
        value={{
          // State
          isLoading: this.state.isLoading,
          isAuth: this.state.isAuth,
          user: this.state.user,
          projects: this.state.projects,
          project: this.state.project,
          materials: this.state.materials,
          materials_list: this.state.materials_list,
          latest_selection: this.state.latest_selection,
          global_message: this.state.global_message,

          // Errors
          registrationMessage: this.state.registrationMessage,

          // Functions
          Login: this.Login,
          Logout: this.Logout,
          Register: this.Register,
          DeleteUser: this.DeleteUser,
          ResetUserPassword: this.ResetUserPassword,
          ResendVerificationEmail: this.ResendVerificationEmail,
          RecoverUserAccount: this.RecoverUserAccount,
          ChangeUserPassword: this.ChangeUserPassword,
          SetGlobalMessage: this.SetGlobalMessage,
          SetActiveProject: this.SetActiveProject,

          AddProject: this.AddProject,
          RemoveProject: this.RemoveProject,
          PartialUpdateProject: this.PartialUpdateProject,
          handleSliderChange: this.handleSliderChange,
          handleConstructionChange: this.handleConstructionChange,
          handleMaterialChange: this.handleMaterialChange,
          UpdateProjectName: this.UpdateProjectName,
          SaveProject: this.SaveProject,
          RedirectToProject: this.RedirectToProject,
          RedirectToCreateNewProject: this.RedirectToCreateNewProject,
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

export const AuthProvider = withRouter(_AuthProvider);
export const AuthContext = createContext();
export const AuthConsumer = AuthContext.Consumer;

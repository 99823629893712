import React, { Fragment } from 'react'
import { ProjectConsumer } from '../../ProjectContext';

// UI
import { Popup } from 'semantic-ui-react';

// Icons
import GWP_Icon from '../../img/UI_Misc/t_co2e.png';
import PENRT_Icon from '../../img/UI_Misc/t_pe.png';
import Setup_Icon from '../../img/UI_Misc/Setup.png';
import AB_Icon from '../../img/UI_Misc/A_B.png';
import Help_Icon from '../../img/UI_Misc/Help.png';
import Results_Icon from '../../img/UI_Misc/Results.png';
import Graph1_Icon from '../../img/UI_Misc/r_1.png';
import Graph2_Icon from '../../img/UI_Misc/r_2.png';
import Graph3_Icon from '../../img/UI_Misc/r_3.png';
import Graph4_Icon from '../../img/UI_Misc/r_4.png';


export default function ComparisonMenu() {
    return (
        <ProjectConsumer>
            {({ display_graphs, setViewState, calculation_unit, setViewIndex, setGuideIndex }) => (
                <div className="ComparisonMenu">

                    <MenuIcon
                        icon={calculation_unit ? GWP_Icon : PENRT_Icon}
                        message={calculation_unit ? "Global Warming Potential" : "Primary non-renewable energy"}
                        position="Top"
                        clickHandler={() => setViewState("calculation_unit", !calculation_unit)}
                    />

                    {display_graphs ?
                        <Fragment>
                            <MenuIcon
                                message="Resources"
                                icon={Graph1_Icon}
                                clickHandler={() => setViewState("active_graph_index", 0)}
                            />
                            <MenuIcon
                                message="Building Parts"
                                icon={Graph2_Icon}
                                clickHandler={() => setViewState("active_graph_index", 1)}
                            />
                            <MenuIcon
                                message="Materials"
                                icon={Graph3_Icon}
                                clickHandler={() => setViewState("active_graph_index", 2)}
                            />
                            <MenuIcon
                                message="Impact"
                                icon={Graph4_Icon}
                                clickHandler={() => setViewState("active_graph_index", 3)}
                            />
                        </Fragment>
                        :
                        <Fragment>
                            <MenuIcon
                                message="Building settings"
                                icon={Setup_Icon}
                                clickHandler={() => setViewIndex(1)}
                            />
                            <MenuIcon
                                message="Guide"
                                icon={Help_Icon}
                                clickHandler={() => setGuideIndex(0)}
                            />
                        </Fragment>
                    }

                    <MenuIcon
                        icon={display_graphs ? AB_Icon : Results_Icon}
                        message="Result Breakdown"
                        position="MiddleBottom"
                        clickHandler={() => setViewState("display_graphs", !display_graphs)}
                    />
                </div>
            )}
        </ProjectConsumer>
    )
}

const MenuIcon = ({ icon, clickHandler, position = "", message = "" }) => {
    return (
        <Popup
            position="top center"
            content={message}
            trigger={
                <div className={`MenuItem ${position}`}>
                    <img
                        className="MenuIcon Hoverable"
                        src={icon}
                        alt="img"
                        onClick={clickHandler}
                    />
                </div>
            }
        >
        </Popup >
    )
}
import React, { useState } from "react";

import { Button, Dropdown, Form, Icon, Modal } from "semantic-ui-react";
import ContentHeader from "../Layout/Common/ContentHeader";
import { AuthConsumer } from "../Authentication/AuthContext";

export default function ProjectList(props) {
  const initalState = props.location.state
    ? props.location.state.modalState
    : false;
  const [modalState, setModalState] = useState(initalState);
  const [name, setName] = useState("");
  return (
    <AuthConsumer>
      {({
        projects,
        AddProject,
        RemoveProject,
        RedirectToProject,
        UpdateProjectName,
      }) => (
        <React.Fragment>
          {/* Add Project Modal */}
          <Modal open={modalState} onClose={() => setModalState(false)}>
            <Modal.Header>Add new project</Modal.Header>
            <Modal.Content>
              <Form>
                <Form.Field>
                  <label>Name</label>
                  <input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Field>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button positive onClick={() => AddProject({ name: name })}>
                Save
              </Button>
            </Modal.Actions>
          </Modal>

          <ContentHeader text="Projects" />

          <div className="ListContainer">
            <div className="ListContent">
              <div
                className="HorizontalCenterAligned Row RowDirection"
                style={{
                  fontSize: 18,
                  fontWeight: 700,
                  paddingBottom: 20,
                  paddingTop: 20,
                }}
              >
                BETA DISCLAIMER
              </div>
              <div style={{ fontSize: 14, fontWeight: 500, paddingBottom: 20 }}>
                Please note that this is a beta version of the JOKOTAI Material
                Impact Screener which is still undergoing final testing and
                editing before its official release. Should you encounter any
                bugs, glitches, lack of functionality or other problems on the
                website, please let us know immediately so we can rectify these
                accordingly. Your help in this regard is greatly appreciated!
                You can write to us at this address:{" "}
                <strong>jokotai@oopeaa.com</strong>
                <p>
                  Please note that this beta version is optimized to work on
                  desktop computers with large screens.
                </p>
              </div>

              <div className="ProjectList">
                <div
                  className="ProjectListRow Actions"
                  onClick={() => setModalState(!modalState)}
                >
                  + new project
                </div>

                {projects.map((project) => (
                  <ProjectListItem
                    project={project}
                    key={project.id}
                    handleRemove={RemoveProject}
                    handleUpdate={UpdateProjectName}
                    handleClick={RedirectToProject}
                  />
                ))}
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </AuthConsumer>
  );
}

function ProjectListItem({ project, handleRemove, handleClick, handleUpdate }) {
  const [is_editing, set_editing] = useState(false);
  const [new_name, set_new_name] = useState(project.name);

  const submit_change = () => {
    handleUpdate(project.id, new_name, () => {
      set_editing(false);
    });
  };

  const cancel_editing = () => {
    set_new_name(project.name);
    set_editing(false);
  };

  return (
    <div className="ProjectListRow">
      <div className="ProjectListName">
        {is_editing ? (
          <input
            className={`NameInput ${is_editing ? "Active" : ""}`}
            readOnly={!is_editing}
            value={new_name}
            onChange={(e) => set_new_name(e.target.value)}
          />
        ) : (
          <div className="Title" onClick={() => handleClick(project)}>
            {project.name}
          </div>
        )}
        <Icon
          className={`NameInputSave ${is_editing ? "Active" : ""}`}
          name="check"
          color="green"
          disabled={project.name === new_name}
          onClick={() => submit_change()}
        />
        <Icon
          className={`NameInputCancel ${is_editing ? "Active" : ""}`}
          name="cancel"
          onClick={() => cancel_editing()}
          color="red"
        />
      </div>
      <div className="ProjectListActions">
        <Dropdown direction="left" pointing={false} icon="ellipsis vertical">
          <Dropdown.Menu>
            <Dropdown.Item
              text="Rename"
              icon="edit"
              onClick={() => set_editing(!is_editing)}
            ></Dropdown.Item>
            <Dropdown.Item disabled text="Share" icon="share"></Dropdown.Item>
            <Dropdown.Item
              text="Delete"
              icon="trash"
              onClick={() => handleRemove(project.id)}
            ></Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
}

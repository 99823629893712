import { Vector3, MeshBuilder, Mesh } from 'babylonjs';
import { buildingParams, colours } from '../../ProjectSettings';
import { CreateWallWithOpenings } from './Walls';

export const CreateInsulation = (project, view, insulationMaterial, openingMaterial, showEnd = true, showSide = true, showTop = true) => {
    // Init 
    let insulation = new Mesh("insulationMesh", view.scene);
    let { width, depth, density, story_height, stories, openings } = project;
    let building_height = story_height * stories;

    let wallRatio = buildingParams.layout.ratio;
    let wallsX = Math.round(width / density) + 1;
    let wallsY = Math.round((width / density) / wallRatio) + 1;

    // Colours
    let white = view.rgbaToCol4(colours.white); 
    let grey80 = view.rgbaToCol4(colours.grey80);

    // Setup ofset position
    let ratio = buildingParams.construction.insulationOffsetRatio;
    let longestSide = ((width > depth) ? width : depth);
    let offset = longestSide * ratio;

    // Create end insulation
    if (showEnd) {

        let endInsulation = CreateWallWithOpenings(view,
            depth,
            building_height,
            buildingParams.construction.insulationThickness,
            wallsY,
            stories,
            story_height,
            openings,
            buildingParams.construction.openingHeight,
            insulationMaterial,
            openingMaterial);

        endInsulation.position = new Vector3(0 + (width / 2) + offset, 0 + (building_height / 2), 0);
        endInsulation.parent = insulation;
    }

    // Create side insulation
    if (showSide) {
        let sideInsulation = CreateWallWithOpenings(view,
            width,
            building_height,
            buildingParams.construction.insulationThickness,
            wallsX,
            stories,
            story_height,
            openings,
            buildingParams.construction.openingHeight,
            insulationMaterial,
            openingMaterial);

        sideInsulation.position = new Vector3(0, 0 + (building_height / 2), 0 + (depth / 2) + offset);
        sideInsulation.rotation.y += 1.5707963268;
        sideInsulation.parent = insulation;
    }

    // Create top insulation
    if (showTop) {

        let options = {
            width: width,
            height: buildingParams.construction.insulationThickness,
            depth: depth,
            faceColors: [grey80, grey80, grey80, grey80, white, white]
        };

        // Create box and set it's position and material
        let topInsulation = MeshBuilder.CreateBox("topInsulation", options, view.scene);
        topInsulation.position = new Vector3(0, 0 + ((building_height) + offset), 0);
        topInsulation.material = insulationMaterial;
        topInsulation.parent = insulation;
    }

    return insulation;
}
import React, { Fragment } from "react";
import "./VisionPage.css";

import ContentHeader from "../../Common/ContentHeader";
import img1 from "../../../static/VisionPage/01_Hands.jpg";
import img2 from "../../../static/VisionPage/02_Comparison.jpg";
import img3 from "../../../static/VisionPage/03_Materials.jpg";
import img4 from "../../../static/VisionPage/04_Production.jpg";
import img5 from "../../../static/VisionPage/05_Collaboration.jpg";
import img6 from "../../../static/VisionPage/06_Development.jpg";

const VisionPage = () => {
  return (
    <Fragment>
      <ContentHeader text="VISION" />

      <div className="ContentContainer">
        {/* Content Sub-header */}
        <div className="ContentRow">
          <div>
            <p style={{ fontSize: "x-large", fontWeight: 400 }}>
              A Quest for More Responsible Building Practices
            </p>
            <p className="Content">
              As architects, we are stakeholders in the processes that shape our
              built environment. In order to take seriously our responsibility
              in that, we at OOPEAA felt the need to better understand the
              impact that our choices of material have on the sustainability of
              a building project. Instead of merely relying on instinct and
              common beliefs, we wanted to create a tool that would help us
              deepen our knowledge about the impact of materials through an
              analysis of verified and reliable data. We wanted to be able to
              compare the impact of alternative material choices before nailing
              down our decision regarding the materials to be used in a new
              project under design.
            </p>
            <br />
            <img src={img1} alt={img1} style={{ maxWidth: "100%" }} />
          </div>
        </div>

        {sections.map((section) => (
          <div className="ContentRow" key={section.id}>
            <div>
              <p className="RowHeader text-section-header">
                <span>{section.header}</span>
              </p>
              <p className="RowText text-section-content">
                <span>{section.content}</span>
              </p>
              {section.img ? <br /> : null}
              {section.img ? (
                <img
                  src={section.img}
                  alt={section.img}
                  style={{ maxWidth: "100%" }}
                />
              ) : null}

              {section.imgSources.length !== 0 ? (
                <div style={{ fontSize: 12 }}>Image Copyright:</div>
              ) : null}
              <ul style={{ padding: 0, fontSize: 12, margin: 0 }}>
                {section.imgSources.map((imgSource) => (
                  <div key={imgSource.number}>
                    {imgSource.number}: {imgSource.text}
                  </div>
                ))}
              </ul>
            </div>
          </div>
        ))}

        <div className="ContentRow">
          <div>
            <hr />
            <div>Anssi Lassila</div>
            <div>Founder, director, architect</div>
            <div>OOPEAA</div>
            <br />
            <div>Seinäjoki, July 6, 2020</div>
            <br />
            <div>
              For more information about OOPEAA Research projects, please visit:
            </div>
            <a
              style={{ color: "black", fontWeight: 700 }}
              href="https://www.oopeaa.com/research"
            >
              www.oopeaa.com/research
            </a>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default VisionPage;

const sections = [
  {
    id: 0,
    header: "A Tool for the Early Design Stage",
    content:
      "Currently, there are plenty of tools available to assess the sustainability of a building over its full life cycle. However, those tools are mainly intended for conducting an analysis of a building project once all the crucial decisions have already been made and the design process has already reached quite an advanced stage. In practical terms, that means that at that stage one can no longer go back and start over the design with a different material. To complement the existing Life Cycle Assessment tools, we felt the need for a tool that would be quick and easy to use at an early stage of the design process when it is still possible to re-evaluate and change our choices regarding materials to be used.",
    img: null,
    imgSources: [],
  },
  {
    id: 1,
    header: "A Tool for Communication",
    content:
      "Additionally, we wanted a tool that would make it possible for us to communicate about the impact of the material choices in a clear and comprehensible manner not only among members of our own team but also with clients, developers and public officials in charge of building permits. Finally, we also wanted to share the tool with others internationally and use it as a platform for discussion about sustainable practices beyond our Nordic experience in Finland.",
    img: img2,
    imgSources: [],
  },
  {
    id: 2,
    header: "A Tool for Screening Environmental Impacts",
    content:
      "To underscore the intent of providing a tool that allows us to make comparisons between alternative material choices, we decided to name the tool JOKOTAI. It is based on the Finnish words “joko – tai” that means “either – or”. To emphasize the nature of the tool as a quick evaluation tool to be used at an early stage of the design process to screen alternative options, we included the further specification “Material Impact Screener” in the name.",
    img: img3,
    imgSources: [],
  },
  {
    id: 3,
    header:
      "Graphical Information about the Sustainability of Building Materials",
    content:
      "To support the aim of making decisions based on a fuller understanding of the impact that our choice of building materials has on the environment, we also felt the need to provide information about the environmental harm caused by some of the commonly used building materials. Therefore, we have chosen to complement the calculation results provided by the JOKOTAI tool by graphical information about the environmental burdens, the expected service life and the recyclability of a material.",
    img: null,
    imgSources: [],
  },
  {
    id: 4,
    header: "EPDs as Sources of Data",
    content:
      "Evaluating the environmental sustainability of building materials requires transparent communication of all impacts related to their production, service life and afterlife from the producer. However, currently only a fraction of the building materials produced in Europe include a complete environmental product declaration (EPD). And, depending on the location, in some parts of the world information about environmental impacts of materials might not be required at all. That needs to change in order to make it possible for us to be fully aware of all impacts related to the extraction of valuable resources and emissions and waste caused during production and at the end of a product’s service life.",
    img: img4,
    imgSources: [{ number: 1, text: "Alexandra Pugachevsky" }],
  },
  {
    id: 5,
    header: "Focus on the Most Resource Intensive Building Phases",
    content:
      "The current version of the JOKOTAI Material Impact Screener is only the very first iteration of the tool. In this first version it is possible to analyze the impact of alternative material choices in the case of singular buildings. In this first development round of the tool, we decided to focus on the phase that, after the consumption of operative energy, is the most resource intensive part of a building’s life cycle, that from the extraction of raw material to the production of the building materials used. This is also the phase in which the architect has the most pertinent influence in determining choices that impact the sustainability of a building.",
    img: img5,
    imgSources: [],
  },
  {
    id: 6,
    header: "Learning from Collaboration",
    content:
      "As architects, the OOPEAA team is in a special position to create a tool to address the needs of architects. The journey of collaboration with programmers and user interface designers to create a web-based assessment tool has been an interesting and rewarding one, and it has given all of the parties involved a great opportunity to learn from each other. Having been chosen as one of the projects in the Growth and Development from Wood Program by the Ministry of Environment, Finland has given us a meaningful framework to connect the project with. It has also provided us valuable financial support that has made it possible for us to focus on research work.",
    img: null,
    imgSources: [],
  },
  {
    id: 7,
    header: "Transparency as a Guiding Principle",
    content:
      "The partnership with Obayashi, a Japanese construction company, has further enriched the process and broadened our perspective beyond the European experience. It has made us aware of the fact that the way in which data regarding the impact of materials on the environment is collected and madeavailable varies greatly from country to country. This makes it sometimes challenging to compare the data from one place with that of the data from another. It also highlights the need for transparency regarding both the sources of data used as well as the method of calculation applied. Therefore, transparency has been one of our key guiding principles in creating the JOKOTAI tool.",
    img: img6,
    imgSources: [],
  },
  {
    id: 8,
    header: "Plans for Further Development",
    content:
      "It is our intention to continue to develop the tool further and make it possible to analyze larger complexes of several buildings or entire blocks in the context of a specific location. We also intend to extend the analysis to address the full scope of a building’s life span. However, we intend to keep the tool focused on the needs of the very early sketching stage of the design process. This tool is intended to provide a compass for direction, not a roadmap with specific instructions.",
    img: null,
    imgSources: [],
  },
];

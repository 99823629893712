import React from 'react';
import { ProjectConsumer } from '../../../ProjectContext';

// Semantic UI
import { Modal, Button } from 'semantic-ui-react';

// Assets
import './GuideModals.css';
import GuideImage1 from '../../../img/Guide/Guide_1.png';
import GuideImage2 from '../../../img/Guide/Guide_2.png';
import GuideImage3 from '../../../img/Guide/Guide_3.png';
import Back_White from '../../../img/UI_Misc/Back_white.png';
import Next_White from '../../../img/UI_Misc/Next_white.png';
import Close_Icon from '../../../img/UI_Misc/Close.png';


const guideImages = [
    GuideImage1,
    GuideImage2,
    GuideImage3,
]

export default function GuideModals() {
    return (
        <ProjectConsumer>
            {({ guideIndex, setGuideIndex }) => (
                <Modal
                    className="GuideModal"
                    open={!(guideIndex === -1)}
                    onClose={() => setGuideIndex(-1)}
                    closeIcon={
                        <img
                            src={Close_Icon}
                            alt="Close"
                            className="guide-modal-close"
                        />
                    }
                >
                    <div className="GuideModalContent">
                        <div className="GuideModalNavCol Left">
                            <Button
                                className={`NavButton ${guideIndex === 0 ? "Disabled" : ""}`}
                                icon
                            >
                                <img
                                    src={Back_White}
                                    alt="Back"
                                    className="NavButtonIcon"
                                    onClick={() => setGuideIndex(guideIndex - 1)}
                                />
                            </Button>
                        </div>
                        <div className="GuideModalImgCol">
                            <img
                                className="GuideImage"
                                src={guideImages[guideIndex]}
                                alt="Guide"
                                onClick={() => setGuideIndex(guideIndex === 2 ? -1 : guideIndex + 1)}
                            />
                        </div>
                        <div className="GuideModalNavCol Right">
                            <Button
                                className={`NavButton ${guideIndex === guideImages.length - 1 ? "Disabled" : ""}`}
                                icon
                            >
                                <img
                                    src={Next_White}
                                    alt="Next"
                                    className="NavButtonIcon"
                                    onClick={() => setGuideIndex(guideIndex + 1)}
                                />
                            </Button>
                        </div>
                    </div>
                </Modal>
            )}
        </ProjectConsumer>
    )
}
import React from "react";

const HeaderResultCol = ({ results, building, unit }) => {
  return (
    <div className="building-number-col">
      {unit
        ? Math.round(results[building].GWP.EMI)
        : Math.round(results[building].PENRT)}
    </div>
  );
};

export default HeaderResultCol;

import firebase from "firebase";
import { firestore, firebaseAuth } from "./FirebaseConfig";
import { defaultProject } from "../Models/Project";

/* PROJECT API */

// Create
export const CreateProject = (data, callback) => {
  // Create default project data
  let projectData = {
    ...data,
    ...defaultProject,
    created: firebase.firestore.Timestamp.now(),
    updated: firebase.firestore.FieldValue.serverTimestamp(),
  };

  // Write data to db
  let user = firebaseAuth.currentUser;
  firestore
    .collection("users")
    .doc(user.uid)
    .collection("projects")
    .add(projectData)
    .then((docRef) => {
      GetProject(docRef.id, (project) => {
        callback(project);
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

// Detail
export const GetProject = (projectId, callback) => {
  let user = firebaseAuth.currentUser;
  firestore
    .collection("users")
    .doc(user.uid)
    .collection("projects")
    .doc(projectId)
    .get()
    .then((snapshot) => {
      callback({ ...snapshot.data(), id: snapshot.id });
    })
    .catch((error) => {
      console.log(error);
    });
};

// Update
export const UpdateProject = (projectId, data, callback) => {
  let user = firebaseAuth.currentUser;
  let projectData = {
    ...data,
    updated: firebase.firestore.Timestamp.now(),
  };
  firestore
    .collection("users")
    .doc(user.uid)
    .collection("projects")
    .doc(projectId)
    .update(projectData)
    .then(() => {
      GetProject(projectId, (project) => {
        callback(project);
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

// List
export const GetProjects = (callback) => {
  let user = firebaseAuth.currentUser;
  let projectList = [];
  firestore
    .collection("users")
    .doc(user.uid)
    .collection("projects")
    .orderBy("updated", "desc")
    .get()
    .then((snapshot) => {
      snapshot.forEach((doc) => {
        projectList.push({ ...doc.data(), id: doc.id });
      });
      callback(projectList);
    });
};

/*
// Used to check there is always one project  (could just get this info from GetProjects, as it returns the same data)
export const GetNumProjects = (callback) => {
    let user = firebaseAuth.currentUser;
    let projectCount = 0;
    firestore.collection('users').doc(user.uid).collection('projects').get()
        .then(snapshot => {
            snapshot.forEach( 
                projectCount++
            )
            callback(projectCount);
        })
}
*/

// Delete
export const DeleteProject = (projectId, callback) => {
  let user = firebaseAuth.currentUser;
  firestore
    .collection("users")
    .doc(user.uid)
    .collection("projects")
    .doc(projectId)
    .delete()
    .then(() => {
      callback(projectId);
    })
    .catch((error) => {
      console.log(error);
    });
};

/* MATERIALS API */
export const GetMaterials = (callback) => {
  let materials = [];
  firestore
    .collection("materials_dev")
    .get() // Change to 'material_dev' for development collection
    .then((snapshot) => {
      snapshot.forEach((doc) => {
        materials.push({ ...doc.data(), id: doc.id });
      });
      callback(materials);
    });
};

/* CONSTRUCTIONS API */
export const GetConstructions = (callback) => {
  let constructions = [];
  firestore
    .collection("constructions")
    .orderBy("sort_order", "desc")
    .get()
    .then((snapshot) => {
      snapshot.forEach((doc) => {
        constructions.push({ ...doc.data(), id: doc.id });
      });
      callback(constructions);
    });
};

/* MATERIALTYPES API */
export const GetMaterialTypes = (callback) => {
  let material_types = [];
  firestore
    .collection("material_types_test")
    .get()
    .then((snapshot) => {
      snapshot.forEach((doc) => {
        material_types.push({ ...doc.data(), id: doc.id });
      });
      callback(material_types);
    });
};

/* MAILING LIST */
export const AddToMailingList = (userId) => {
  firestore
    .collection("users")
    .doc(userId)
    .collection("mailing_list")
    .doc("status")
    .set({ accepted: true });
};

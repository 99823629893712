import React from 'react'
import { Grid } from 'semantic-ui-react'

import C1_CO2 from '../../img/WorldCounts/C1_CO2.png';
import C2_Resources from '../../img/WorldCounts/C2_Resources.png';
import C3_Waste from '../../img/WorldCounts/C3_Waste.png';
import C4_Energy from '../../img/WorldCounts/C4_Energy.png';
import C5_World from '../../img/WorldCounts/C5_World.png';

export default function WorldCounts() {
    return (
        <div style={{ marginLeft: "15%", marginRight: "15%" }}>
            <Grid columns="equal" style={{ margin: 0 }}>
                <Grid.Row>

                    <Grid.Column >
                        <img src={C1_CO2} alt={C1_CO2} style={imageStyle} />
                        <Iframe iframe={iframe1} />
                    </Grid.Column>

                    <Grid.Column >
                        <img src={C2_Resources} alt={C2_Resources} style={imageStyle} />
                        <Iframe iframe={iframe2} />
                    </Grid.Column>

                    <Grid.Column >
                        <img src={C3_Waste} alt={C3_Waste} style={imageStyle} />
                        <Iframe iframe={iframe3} />
                    </Grid.Column>

                    <Grid.Column >
                        <img src={C4_Energy} alt={C4_Energy} style={imageStyle} />
                        <Iframe iframe={iframe4} />
                    </Grid.Column>

                    <Grid.Column >
                        <img src={C5_World} alt={C5_World} style={imageStyle} />
                        <Iframe iframe={iframe5} />
                    </Grid.Column>

                </Grid.Row>
            </Grid >
        </div>
    )
}

const iframe1 = "<iframe title='Tons of CO2 emitted into the atmosphere' src='https://www.theworldcounts.com/embed/challenges/23?background_color=white&color=black&font_family=%22Helvetica+Neue%22%2C+Arial%2C+sans-serif&font_size=14' style='border: none' height=100% width=100%></iframe>";
const iframe2 = "<iframe title='Tons of resources extracted from Earth' src='https://www.theworldcounts.com/embed/challenges/5?background_color=white&color=black&font_family=%22Helvetica+Neue%22%2C+Arial%2C+sans-serif&font_size=14' style='border: none' height=100% width=100%></iframe>";
const iframe3 = "<iframe title='Tons of waste dumped' src='https://www.theworldcounts.com/embed/challenges/104?background_color=white&color=black&font_family=%22Helvetica+Neue%22%2C+Arial%2C+sans-serif&font_size=14' style='border: none' height=100% width=100%></iframe>";
const iframe4 = "<iframe title='Terajoules of energy used' src='https://www.theworldcounts.com/embed/challenges/25?background_color=white&color=black&font_family=%22Helvetica+Neue%22%2C+Arial%2C+sans-serif&font_size=14' style='border: none' height=100% width=100%></iframe>";
const iframe5 = "<iframe title='Number of planet Earths we need' src='https://www.theworldcounts.com/embed/challenges/83?background_color=white&color=black&font_family=%22Helvetica+Neue%22%2C+Arial%2C+sans-serif&font_size=14' style='border: none' height=100% width=100%></iframe>";

function Iframe(props) {
    return (<div dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }} />);
}

const imageStyle = {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: "25%"
}
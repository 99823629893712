import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { AuthConsumer } from "../Authentication/AuthContext";

import "./PageHeader.css";
import { Button, Dropdown, Icon, Menu } from "semantic-ui-react";

export default function PageHeader() {
  return (
    <AuthConsumer>
      {({ isAuth, Logout, project, projects, RedirectToProject }) => (
        <Menu className="PageHeader" borderless inverted fixed="top">
          <Menu.Item className="HeaderLogo" as={Link} to="/">
            <div>
              <strong>OOPEAA </strong>JOKOTAI
            </div>
            <div className="Beta">BETA</div>
          </Menu.Item>

          <Menu.Item className="HeaderItem" as={Link} to="/about">
            ABOUT
          </Menu.Item>

          <Menu.Item className="HeaderItem" as={Link} to="/research">
            RESEARCH
          </Menu.Item>

          <Menu.Item className="HeaderItem" as={Link} to="/vision">
            VISION
          </Menu.Item>

          <Menu.Item className="HeaderItem" as={Link} to="/faq">
            FAQ
          </Menu.Item>

          {isAuth ? (
            <Dropdown
              className="ProjectsDropwdown"
              item
              direction="left"
              text="Projects"
            >
              <Dropdown.Menu>
                {projects.map((projectObject) => (
                  <Dropdown.Item
                    key={projectObject.id}
                    active={projectObject === project}
                    onClick={() => RedirectToProject(projectObject)}
                  >
                    {projectObject.name}
                  </Dropdown.Item>
                ))}
                <Dropdown.Divider />
                <Dropdown.Item
                  content="Projects list"
                  as={Link}
                  to="/projects"
                />
              </Dropdown.Menu>
            </Dropdown>
          ) : null}

          {isAuth ? (
            <Dropdown
              className="HeaderItem"
              item
              icon={<Icon name="user outline" />}
              direction="left"
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  as={Link}
                  to="/profile"
                  text="Profile"
                  icon="user outline"
                />
                <Dropdown.Divider />
                <Dropdown.Item
                  onClick={() => Logout()}
                  text="Logout"
                  icon="sign out"
                />
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <Menu.Item style={{ marginLeft: "auto" }} className="HeaderItem">
              <Button basic inverted as={Link} to="/login">
                Login
              </Button>
            </Menu.Item>
          )}

          {/* Mobile menu */}
          <Dropdown
            className="HeaderItemMobile"
            item
            icon={
              isAuth ? (
                <Icon className="HeaderItemIcon" name="user outline" />
              ) : (
                <Icon className="HeaderItemIcon" name="bars" size="big" />
              )
            }
            direction="left"
          >
            <Dropdown.Menu>
              {isAuth ? (
                <Fragment>
                  <Dropdown.Item as={Link} to="/projects">
                    Projects
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to="/profile">
                    Profile
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => Logout()}>Logout</Dropdown.Item>
                </Fragment>
              ) : (
                <Fragment>
                  <Dropdown.Header>User</Dropdown.Header>
                  <Dropdown.Item as={Link} to="/login">
                    Login
                  </Dropdown.Item>
                </Fragment>
              )}

              <Dropdown.Divider />
              <Dropdown.Header>Information</Dropdown.Header>
              <Dropdown.Item as={Link} to="/about">
                About
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/faq">
                FAQ
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/research">
                Research
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/vision">
                Vision
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Menu>
      )}
    </AuthConsumer>
  );
}

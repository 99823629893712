import { buildingParams, colours } from '../../ProjectSettings';
import { Vector3, MeshBuilder, StandardMaterial } from 'babylonjs';

// Creates a building sized foundation slab mesh
export const CreateFoundation = (project, view) => {
    // Init params
    let height = buildingParams.construction.foundationThickness;
    let { width, depth } = project;

    // Colours
    let white = view.rgbaToCol4(colours.white);
    let grey80 = view.rgbaToCol4(colours.grey80);
    let grey60 = view.rgbaToCol4(colours.grey60);

    // Material
    let foundationMaterial = new StandardMaterial("conceptualMassMaterial", view.scene);
    foundationMaterial.ambientColor = white;

    // Create foundation slab
    let options = {
        width: width,
        height: height,
        depth: depth,
        faceColors: [grey60, grey60, grey60, grey60, grey80, grey80]
    };
    let foundation = MeshBuilder.CreateBox("foundationMesh", options, view.scene);
    foundation.position = new Vector3(0, -height / 2, 0);
    foundation.material = foundationMaterial;

    return foundation;
}
import { buildingParams, material_types_dict } from "../ProjectSettings";

// Function takes two arguments: project (building variables) and materials_list (a dictionary of materials from Firesstore)
export const calculateResult = (project, materials_list, latest_selection) => {
  // This is the calculation function
  // NOTES:
  // Index 1 & 2 refer to the corresponding building

  // Initial dictionary for results
  let results = {
    building1: {
      GWP: { total: 0, CSP: 0, EMI: 0 },
      PENRT: 0,
      parts: {
        Foundation: { GWP: { total: 0, CSP: 0, EMI: 0 }, PENRT: 0 },
        "Vertical structure": { GWP: { total: 0, CSP: 0, EMI: 0 }, PENRT: 0 },
        "Horizontal structure": { GWP: { total: 0, CSP: 0, EMI: 0 }, PENRT: 0 },
        Insulation: { GWP: { total: 0, CSP: 0, EMI: 0 }, PENRT: 0 },
        Facade: { GWP: { total: 0, CSP: 0, EMI: 0 }, PENRT: 0 },
        Floor: { GWP: { total: 0, CSP: 0, EMI: 0 }, PENRT: 0 },
        Roof: { GWP: { total: 0, CSP: 0, EMI: 0 }, PENRT: 0 },
      },
      type: {
        Plants: { GWP: { total: 0, CSP: 0, EMI: 0 }, PENRT: 0 },
        Minerals: { GWP: { total: 0, CSP: 0, EMI: 0 }, PENRT: 0 },
        Soil: { GWP: { total: 0, CSP: 0, EMI: 0 }, PENRT: 0 },
        Oils: { GWP: { total: 0, CSP: 0, EMI: 0 }, PENRT: 0 },
        Glass: { GWP: { total: 0, CSP: 0, EMI: 0 }, PENRT: 0 },
        Metals: { GWP: { total: 0, CSP: 0, EMI: 0 }, PENRT: 0 },
        Mix: { GWP: { total: 0, CSP: 0, EMI: 0 }, PENRT: 0 },
      },
      materials: {},
    },
    building2: {
      GWP: { total: 0, CSP: 0, EMI: 0 },
      PENRT: 0,
      parts: {
        Foundation: { GWP: { total: 0, CSP: 0, EMI: 0 }, PENRT: 0 },
        "Vertical structure": { GWP: { total: 0, CSP: 0, EMI: 0 }, PENRT: 0 },
        "Horizontal structure": { GWP: { total: 0, CSP: 0, EMI: 0 }, PENRT: 0 },
        Insulation: { GWP: { total: 0, CSP: 0, EMI: 0 }, PENRT: 0 },
        Facade: { GWP: { total: 0, CSP: 0, EMI: 0 }, PENRT: 0 },
        Floor: { GWP: { total: 0, CSP: 0, EMI: 0 }, PENRT: 0 },
        Roof: { GWP: { total: 0, CSP: 0, EMI: 0 }, PENRT: 0 },
      },
      type: {
        Plants: { GWP: { total: 0, CSP: 0, EMI: 0 }, PENRT: 0 },
        Minerals: { GWP: { total: 0, CSP: 0, EMI: 0 }, PENRT: 0 },
        Soil: { GWP: { total: 0, CSP: 0, EMI: 0 }, PENRT: 0 },
        Oils: { GWP: { total: 0, CSP: 0, EMI: 0 }, PENRT: 0 },
        Glass: { GWP: { total: 0, CSP: 0, EMI: 0 }, PENRT: 0 },
        Metals: { GWP: { total: 0, CSP: 0, EMI: 0 }, PENRT: 0 },
        Mix: { GWP: { total: 0, CSP: 0, EMI: 0 }, PENRT: 0 },
      },
      materials: {},
    },
    latest_selection: {
      unit: null,
      amount: null,
      epd: { GWP: null, PENRT: null },
      impact: { GWP: null, PENRT: null },
    },
  };

  // Calculate building areas
  const { stories, story_height, width, depth, openings } = project;
  const buildingHeight = stories * story_height;
  const FloorArea = width * depth * stories; // Unit [m2]
  const RoofArea = width * depth; // Unit [m2]
  const FacadeArea =
    (width * buildingHeight * 2 + depth * buildingHeight * 2) *
    ((100 - openings) / 100); // Unit [m2]
  const GlazingArea =
    (width * buildingHeight * 2 + depth * buildingHeight * 2) *
    (openings / 100); // Unit [m2]
  const InsulationArea = FacadeArea + RoofArea; // Unit [m2]

  // Horizontal Structure
  CalculateHorizontalStructure(
    materials_list,
    project,
    project["horizontal_a"],
    results,
    "building1",
    latest_selection
  );
  CalculateHorizontalStructure(
    materials_list,
    project,
    project["horizontal_b"],
    results,
    "building2",
    latest_selection
  );

  // Vertical Structure
  CalculateVerticalStructure(
    materials_list,
    project,
    project["vertical_a"],
    results,
    "building1",
    latest_selection
  );
  CalculateVerticalStructure(
    materials_list,
    project,
    project["vertical_b"],
    results,
    "building2",
    latest_selection
  );

  // Insulation
  CalculateUnitSizesByArea(
    materials_list,
    InsulationArea,
    project["insulation_a"],
    results,
    "building1",
    FloorArea,
    latest_selection
  );
  CalculateUnitSizesByArea(
    materials_list,
    InsulationArea,
    project["insulation_b"],
    results,
    "building2",
    FloorArea,
    latest_selection
  );

  // Facade
  CalculateUnitSizesByArea(
    materials_list,
    FacadeArea,
    project["facade_a"],
    results,
    "building1",
    FloorArea,
    latest_selection
  );
  CalculateUnitSizesByArea(
    materials_list,
    FacadeArea,
    project["facade_b"],
    results,
    "building2",
    FloorArea,
    latest_selection
  );

  // Floor
  CalculateUnitSizesByArea(
    materials_list,
    FloorArea,
    project["floor_a"],
    results,
    "building1",
    FloorArea,
    latest_selection
  );
  CalculateUnitSizesByArea(
    materials_list,
    FloorArea,
    project["floor_b"],
    results,
    "building2",
    FloorArea,
    latest_selection
  );

  // Roof
  CalculateUnitSizesByArea(
    materials_list,
    RoofArea,
    project["roof_a"],
    results,
    "building1",
    FloorArea,
    latest_selection
  );
  CalculateUnitSizesByArea(
    materials_list,
    RoofArea,
    project["roof_b"],
    results,
    "building2",
    FloorArea,
    latest_selection
  );

  // Glazing
  CalculateUnitSizesByArea(
    materials_list,
    GlazingArea,
    project["openings_a"],
    results,
    "building1",
    FloorArea,
    latest_selection
  );
  CalculateUnitSizesByArea(
    materials_list,
    GlazingArea,
    project["openings_b"],
    results,
    "building2",
    FloorArea,
    latest_selection
  );

  // Foundation
  CalculateFoundation(
    materials_list,
    project,
    project["foundation_a"],
    project["vertical_a"],
    results,
    "building1",
    FloorArea,
    latest_selection
  );
  CalculateFoundation(
    materials_list,
    project,
    project["foundation_b"],
    project["vertical_b"],
    results,
    "building2",
    FloorArea,
    latest_selection
  );

  // Set latest material
  results.latest_selection.unit = latest_selection.unit;
  results.latest_selection.epd.GWP = latest_selection.GWP_A1_3;
  results.latest_selection.epd.PENRT = latest_selection.PENRT_A1_3;

  // Special case If vertical and horizontal material are both either concrete or CLT, then merge them
  if (results["building1"].materials[4] && results["building1"].materials[35])
    MergeMaterials(results, 4, 35, "building1");

  if (results["building1"].materials[2] && results["building1"].materials[33])
    MergeMaterials(results, 2, 33, "building1");

  if (results["building2"].materials[4] && results["building2"].materials[35])
    MergeMaterials(results, 4, 35, "building2");

  if (results["building2"].materials[2] && results["building2"].materials[33])
    MergeMaterials(results, 2, 33, "building2");

  return results;
};

const CalculateFoundation = (
  materials_list,
  project,
  FoundationMaterial_id,
  VerticalMaterial_id,
  resultsDict,
  building,
  FloorArea,
  latest_selection
) => {
  const FoundationMaterial = materials_list[FoundationMaterial_id];
  const VerticalMaterial = materials_list[VerticalMaterial_id];

  const { width, depth } = project;
  const FoundationLength = 2 * width + 2 * depth;
  const volume =
    (FoundationLength * 0.5 * VerticalMaterial.default_thickness) / 1000 +
    depth * width * 0.3;

  // Calculation results
  let GWP = (volume * FoundationMaterial.GWP_A1_3) / FloorArea;
  let CSP = (volume * FoundationMaterial.CSP) / FloorArea;
  let EMI = (volume * FoundationMaterial.EMI) / FloorArea;
  let PENRT = (volume * FoundationMaterial.PENRT_A1_3) / FloorArea;

  if (latest_selection.material_part === "Foundation") {
    resultsDict.latest_selection.amount = volume;
    resultsDict.latest_selection.impact.GWP = EMI;
    resultsDict.latest_selection.impact.PENRT = PENRT;
  }

  AddMaterialToResult(
    materials_list,
    GWP,
    CSP,
    EMI,
    PENRT,
    resultsDict,
    building,
    FoundationMaterial_id
  );
};

const CalculateHorizontalStructure = (
  materials_list,
  project,
  material_id,
  resultsDict,
  building,
  latest_selection
) => {
  // NOTE: This function uses project to determine which calculation method should be used

  const material = materials_list[material_id];
  const { width, depth, stories, density } = project; // Unpack values
  let FloorArea = width * depth * stories;
  let wallRatio = buildingParams.layout.ratio; // Height-depth ratio for compartments
  let wallsX = Math.round(width / density) - 1; // Amount of internal walls in depth-direction
  let wallsY = Math.round(width / density / wallRatio) - 1; // Amount of internal walls in width-direction
  switch (project["construction_type"]) {
    // If modular construction
    case 0: {
      // Calculate sizes
      CalculateUnitSizesByArea(
        materials_list,
        FloorArea * 2,
        material_id,
        resultsDict,
        building,
        FloorArea,
        latest_selection
      );
      break;
    }
    // If element construction
    case 1: {
      CalculateUnitSizesByArea(
        materials_list,
        FloorArea,
        material_id,
        resultsDict,
        building,
        FloorArea,
        latest_selection
      );
      break;
    }
    // If frame construction
    case 2: {
      let beamArea;
      if (material.material_type === 3) {
        // If metal
        beamArea = 0.00781; // HEB 200
      } else {
        beamArea = 0.04; // 200x200
      }

      // Calculate sizes
      let beamLength = (wallsX * depth + wallsY * width) * stories;
      let beamVolume = beamArea * beamLength;
      CalculateUnitSizeByVolume(
        materials_list,
        beamVolume,
        material_id,
        resultsDict,
        building,
        FloorArea,
        latest_selection
      );
      break;
    }
    default:
      break;
  }
};

const CalculateVerticalStructure = (
  materials_list,
  project,
  material_id,
  resultsDict,
  building,
  latest_selection
) => {
  const material = materials_list[material_id];
  const { width, depth, stories, density, story_height } = project; // Unpack values
  let buildingHeight = stories * story_height;
  let FloorArea = width * depth * stories;
  let wallRatio = buildingParams.layout.ratio; // Height-depth ratio for compartments
  let wallsX = Math.round(width / density) - 1; // Amount of internal walls in depth-direction
  let wallsY = Math.round(width / density / wallRatio) - 1; // Amount of internal walls in width-direction
  switch (project["construction_type"]) {
    // If modular construction
    case 0: {
      // Calculate sizes
      let length = (wallsX * depth + wallsY * width) * stories; // 2D-length of walls
      let area = length * story_height * 2; // Area of walls
      CalculateUnitSizesByArea(
        materials_list,
        area,
        material_id,
        resultsDict,
        building,
        FloorArea,
        latest_selection
      );
      break;
    }
    // If element construction
    case 1: {
      let length = (wallsX * depth + wallsY * width) * stories; // 2D-length of walls
      let area = length * story_height; // Area of walls
      CalculateUnitSizesByArea(
        materials_list,
        area,
        material_id,
        resultsDict,
        building,
        FloorArea,
        latest_selection
      );
      break;
    }
    // If frame construction
    case 2: {
      let columnArea;
      if (material.material_type === 3) {
        // Metal
        columnArea = 0.00781; // HEB 200
      } else {
        columnArea = 0.04; // 200x200
      }

      let columns = (wallsX + 2) * (wallsY + 2);
      let columnsVolume = columns * columnArea * buildingHeight;
      CalculateUnitSizeByVolume(
        materials_list,
        columnsVolume,
        material_id,
        resultsDict,
        building,
        FloorArea,
        latest_selection
      );
      break;
    }
    default:
      break;
  }
};

const CalculateUnitSizeByVolume = (
  materials_list,
  volume,
  material_id,
  resultsDict,
  building,
  FloorArea,
  latest_selection
) => {
  // NOTE: This function only takes volume as input, and will not calculate m2
  const material = materials_list[material_id];
  let liter = volume * 1000;
  let weightKg = volume * material.density;
  let weightTonne = weightKg / 1000;
  switch (material.unit) {
    case "m3": {
      let GWP = (volume * material.GWP_A1_3) / FloorArea;
      let CSP = (volume * material.CSP) / FloorArea;
      let EMI = (volume * material.EMI) / FloorArea;
      let PENRT = (volume * material.PENRT_A1_3) / FloorArea;
      AddMaterialToResult(
        materials_list,
        GWP,
        CSP,
        EMI,
        PENRT,
        resultsDict,
        building,
        material_id
      );
      if (parseInt(material_id) === parseInt(latest_selection.id)) {
        resultsDict.latest_selection.amount = volume;
        resultsDict.latest_selection.impact.GWP = EMI;
        resultsDict.latest_selection.impact.PENRT = PENRT;
      }
      break;
    }
    case "kg": {
      let GWP = (weightKg * material.GWP_A1_3) / FloorArea;
      let CSP = (weightKg * material.CSP) / FloorArea;
      let EMI = (weightKg * material.EMI) / FloorArea;
      let PENRT = (weightKg * material.PENRT_A1_3) / FloorArea;
      AddMaterialToResult(
        materials_list,
        GWP,
        CSP,
        EMI,
        PENRT,
        resultsDict,
        building,
        material_id
      );
      if (parseInt(material_id) === parseInt(latest_selection.id)) {
        resultsDict.latest_selection.amount = weightKg;
        resultsDict.latest_selection.impact.GWP = EMI;
        resultsDict.latest_selection.impact.PENRT = PENRT;
      }
      break;
    }
    case "tonne": {
      let GWP = (weightTonne * material.GWP_A1_3) / FloorArea;
      let CSP = (weightTonne * material.CSP) / FloorArea;
      let EMI = (weightTonne * material.EMI) / FloorArea;
      let PENRT = (weightTonne * material.PENRT_A1_3) / FloorArea;
      AddMaterialToResult(
        materials_list,
        GWP,
        CSP,
        EMI,
        PENRT,
        resultsDict,
        building,
        material_id
      );
      if (parseInt(material_id) === parseInt(latest_selection.id)) {
        resultsDict.latest_selection.amount = weightTonne;
        resultsDict.latest_selection.impact.GWP = EMI;
        resultsDict.latest_selection.impact.PENRT = PENRT;
      }
      break;
    }
    case "liter": {
      let GWP = (liter * material.GWP_A1_3) / FloorArea;
      let CSP = (liter * material.CSP) / FloorArea;
      let EMI = (liter * material.EMI) / FloorArea;
      let PENRT = (liter * material.PENRT_A1_3) / FloorArea;
      AddMaterialToResult(
        materials_list,
        GWP,
        CSP,
        EMI,
        PENRT,
        resultsDict,
        building,
        material_id
      );
      if (parseInt(material_id) === parseInt(latest_selection.id)) {
        resultsDict.latest_selection.amount = liter;
        resultsDict.latest_selection.impact.GWP = EMI;
        resultsDict.latest_selection.impact.PENRT = PENRT;
      }
      break;
    }
    case "na": {
      //console.log("na / none : not adding to results data");
      break;
    }
    default:
      console.log(
        "warning: unrecognized material unit in results calculations"
      );
      break;
  }
};

const CalculateUnitSizesByArea = (
  materials_list,
  area,
  material_id,
  resultsDict,
  building,
  FloorArea,
  latest_selection
) => {
  const material = materials_list[material_id];
  let volume = (area * material.default_thickness) / 1000;
  let liter = volume * 1000;
  let weightKg = volume * material.density;
  let weightTonne = weightKg / 1000;

  switch (material.unit) {
    case "m3": {
      let GWP = (volume * material.GWP_A1_3) / FloorArea;
      let CSP = (volume * material.CSP) / FloorArea;
      let EMI = (volume * material.EMI) / FloorArea;
      let PENRT = (volume * material.PENRT_A1_3) / FloorArea;
      AddMaterialToResult(
        materials_list,
        GWP,
        CSP,
        EMI,
        PENRT,
        resultsDict,
        building,
        material_id
      );
      if (parseInt(material_id) === parseInt(latest_selection.id)) {
        resultsDict.latest_selection.amount = volume;
        resultsDict.latest_selection.impact.GWP = EMI;
        resultsDict.latest_selection.impact.PENRT = PENRT;
      }
      break;
    }
    case "kg": {
      let GWP = (weightKg * material.GWP_A1_3) / FloorArea;
      let CSP = (weightKg * material.CSP) / FloorArea;
      let EMI = (weightKg * material.EMI) / FloorArea;
      let PENRT = (weightKg * material.PENRT_A1_3) / FloorArea;
      AddMaterialToResult(
        materials_list,
        GWP,
        CSP,
        EMI,
        PENRT,
        resultsDict,
        building,
        material_id
      );
      if (parseInt(material_id) === parseInt(latest_selection.id)) {
        resultsDict.latest_selection.amount = weightKg;
        resultsDict.latest_selection.impact.GWP = EMI;
        resultsDict.latest_selection.impact.PENRT = PENRT;
      }
      break;
    }
    case "m2": {
      let GWP = (area * material.GWP_A1_3) / FloorArea;
      let CSP = (area * material.CSP) / FloorArea;
      let EMI = (area * material.EMI) / FloorArea;
      let PENRT = (area * material.PENRT_A1_3) / FloorArea;
      AddMaterialToResult(
        materials_list,
        GWP,
        CSP,
        EMI,
        PENRT,
        resultsDict,
        building,
        material_id
      );
      if (parseInt(material_id) === parseInt(latest_selection.id)) {
        resultsDict.latest_selection.amount = area;
        resultsDict.latest_selection.impact.GWP = EMI;
        resultsDict.latest_selection.impact.PENRT = PENRT;
      }
      break;
    }
    case "tonne": {
      let GWP = (weightTonne * material.GWP_A1_3) / FloorArea;
      let CSP = (weightTonne * material.CSP) / FloorArea;
      let EMI = (weightTonne * material.EMI) / FloorArea;
      let PENRT = (weightTonne * material.PENRT_A1_3) / FloorArea;
      AddMaterialToResult(
        materials_list,
        GWP,
        CSP,
        EMI,
        PENRT,
        resultsDict,
        building,
        material_id
      );
      if (parseInt(material_id) === parseInt(latest_selection.id)) {
        resultsDict.latest_selection.amount = weightTonne;
        resultsDict.latest_selection.impact.GWP = EMI;
        resultsDict.latest_selection.impact.PENRT = PENRT;
      }
      break;
    }
    case "liter": {
      let GWP = (liter * material.GWP_A1_3) / FloorArea;
      let CSP = (liter * material.CSP) / FloorArea;
      let EMI = (liter * material.EMI) / FloorArea;
      let PENRT = (liter * material.PENRT_A1_3) / FloorArea;
      AddMaterialToResult(
        materials_list,
        GWP,
        CSP,
        EMI,
        PENRT,
        resultsDict,
        building,
        material_id
      );
      if (parseInt(material_id) === parseInt(latest_selection.id)) {
        resultsDict.latest_selection.amount = liter;
        resultsDict.latest_selection.impact.GWP = EMI;
        resultsDict.latest_selection.impact.PENRT = PENRT;
      }
      break;
    }
    case "na": {
      //console.log("na / none : not adding to results data");
      break;
    }
    default:
      console.log(
        "warning: unrecognized material unit in results calculations"
      );
      break;
  }
};

const AddMaterialToResult = (
  materials_list,
  GWP,
  CSP,
  EMI,
  PENRT,
  resultsDict,
  building,
  material_id
) => {
  const material = materials_list[material_id];

  // Add to total
  resultsDict[building].GWP.total += GWP;
  resultsDict[building].GWP.CSP += CSP;
  resultsDict[building].GWP.EMI += EMI;
  resultsDict[building].PENRT += PENRT;

  // Add to building part
  if (material.material_part === "Openings") {
    // Add glazing results to facade totals
    resultsDict[building].parts.Facade.GWP.total += GWP;
    resultsDict[building].parts.Facade.GWP.CSP += CSP;
    resultsDict[building].parts.Facade.GWP.EMI += EMI;
    resultsDict[building].parts.Facade.PENRT += PENRT;
  } else {
    resultsDict[building].parts[material.material_part].GWP.total += GWP;
    resultsDict[building].parts[material.material_part].GWP.CSP += CSP;
    resultsDict[building].parts[material.material_part].GWP.EMI += EMI;
    resultsDict[building].parts[material.material_part].PENRT += PENRT;
  }

  // Add to material type
  if (material.material_type !== 0) {
    // Don't add 'none' materials
    resultsDict[building].type[
      material_types_dict[material.material_type]
    ].GWP.total += GWP;
    resultsDict[building].type[
      material_types_dict[material.material_type]
    ].GWP.CSP += CSP;
    resultsDict[building].type[
      material_types_dict[material.material_type]
    ].GWP.EMI += EMI;
    resultsDict[building].type[
      material_types_dict[material.material_type]
    ].PENRT += PENRT;
  }

  // Add to individual materials
  if (material_id !== "40") {
    // Don't add 'no insulation' to materials
    let materialResults = {
      GWP: { total: GWP, CSP: CSP, EMI: EMI },
      PENRT: PENRT,
    };
    resultsDict[building].materials[material_id] = materialResults;
  }
};

// adds material2's values to material1's. Then removes material2.
const MergeMaterials = (resultsDict, material1, material2, building) => {
  resultsDict[building].materials[material1].GWP.total +=
    resultsDict[building].materials[material2].GWP.total;
  resultsDict[building].materials[material1].GWP.CSP +=
    resultsDict[building].materials[material2].GWP.CSP;
  resultsDict[building].materials[material1].GWP.EMI +=
    resultsDict[building].materials[material2].GWP.EMI;
  resultsDict[building].materials[material1].PENRT +=
    resultsDict[building].materials[material2].PENRT;
  delete resultsDict[building].materials[material2];
};

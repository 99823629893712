import React from "react";
import PropTypes from "prop-types";
import { building_part_dict, construction_types } from "../../ProjectSettings";

function MaterialButton(props) {
  let {
    project,
    category,
    materials,
    materials_list,
    building,
    changeHandler,
  } = props;

  // Get the selected material id by category and building and construction type
  const selected_material_id = get_project_material(
    project,
    building,
    category
  );
  const selected_construction_type = project.construction_type;

  // Get the selected material from the list of available materials
  let category_materials = materials[category];
  let selected_material_index = category_materials.indexOf(
    selected_material_id
  );
  let selected_material = materials_list[selected_material_id];

  // This handler increments the materials from a list of category options
  const handleMaterialIncrement = () => {
    let next_index = selected_material_index + 1;

    // Check to see if end of list
    if (next_index === category_materials.length) {
      next_index = 0;
    }

    // Locked materials check
    const cleanedCategoryMaterials = [];
    category_materials.map((material) =>
      cleanedCategoryMaterials.push(parseInt(material))
    );
    const material_locks =
      construction_types[selected_construction_type].material_locks;
    while (material_locks.includes(cleanedCategoryMaterials[next_index])) {
      if (next_index >= cleanedCategoryMaterials.length - 1) {
        next_index = 0;
        if (!material_locks.includes(cleanedCategoryMaterials[next_index].id)) {
          break;
        }
      }
      next_index++;
    }

    // Set the new index
    let new_material_id = category_materials[next_index];

    // Get the prop name
    let parameter_category = building_part_dict[category];
    let parameter_building = building_dict[building];
    changeHandler(parameter_category + parameter_building, new_material_id);
  };

  return (
    <img
      className="SelectionButton HoverSelection"
      src={"img/" + selected_material.material_button}
      alt={category}
      onClick={() => handleMaterialIncrement()}
    />
  );
}

MaterialButton.propTypes = {
  project: PropTypes.object.isRequired,
  building: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  materials: PropTypes.object.isRequired,
  materials_list: PropTypes.object.isRequired,
  changeHandler: PropTypes.func.isRequired,
};

export default MaterialButton;

const building_dict = {
  building1: "_a",
  building2: "_b",
};

const get_project_material = (project, building, category) => {
  let parameter_category = building_part_dict[category];
  let parameter_building = building_dict[building];
  let material_id = project[parameter_category + parameter_building];
  return material_id;
};

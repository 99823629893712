import { MeshBuilder, Vector3, Mesh, StandardMaterial } from 'babylonjs';
import { colours } from '../../ProjectSettings';

export const AddConceptualMass = (project, view) => {
    let { width, depth, stories, story_height } = project;

    // Constants
    const GAP = story_height / 6;
    const BASELINE = 0 + (story_height / 2);

    // Colours
    let white = view.rgbaToCol4(colours.white); 
    let grey80 = view.rgbaToCol4(colours.grey80);

    // Material
    let massMaterial = new StandardMaterial("conceptualMassMaterial", view.scene);
    massMaterial.ambientColor = white;

    let meshes = [];

    for (let storyIndex = 0; storyIndex < stories; storyIndex++) {
        // Define box options
        let options = {
            width: width,
            height: story_height - GAP,
            depth: depth,
            faceColors: [grey80, grey80, grey80, grey80, white, white]
        };
        // Create box and set it's position and material
        let mass = MeshBuilder.CreateBox("conceptualMass", options, view.scene);
        mass.position = new Vector3(0, BASELINE + (story_height * storyIndex), 0);
        mass.material = massMaterial;
        meshes.push(mass);
    }

    let conceptualMass = Mesh.MergeMeshes(meshes, true);
    conceptualMass.name = "conceptualMassMesh";

    return conceptualMass;
};
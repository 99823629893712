import React, { Fragment } from 'react';
import { ProjectConsumer } from '../ProjectContext';

import { Button } from 'semantic-ui-react';
import Intro_Graphic from '../img/IntroPage/Intro_Graphic.png';

const IntroView = () => {
    return (
        <ProjectConsumer>
            {({ setViewIndex }) => (

                <Fragment>
                    <div className="ContentHeader White">
                        <div className="TextContainer">
                            <div className="Title"><strong>OOPEAA</strong> JOKOTAI</div>
                            <div className="SubTitle">Building Material Impact Screener</div>
                        </div>
                    </div>

                    <div className="ContentContainer">

                        <div className="ContentRow">
                            <p>OOPEAA JOKOTAI is a tool for <strong>screening</strong> the environmental impact of structural and material choices on the sustainability of a building project. The tool enables architects to quickly sketch the <strong>building volume</strong> of a preliminary design and compare the environmental impact of alternative material choices to support the early decision making process.</p>
                        </div>

                        <div className="ContentRow Centered">
                            <img src={Intro_Graphic} alt="Intro Graphic" style={{ maxWidth: "65%", justifyContent: 'center' }} />
                        </div>

                        <div className="ContentRow">
                            <p>The result breakdown identifies the most contributing material choices in terms of CO2 emissions and primary energy demand during production. The calculated environmental impact of the <strong>production phase (A1-3)</strong> is complemented by additional information about the expected service life, production process and recycling potential of materials.</p>
                        </div>

                        <div className="ContentRow Centered">
                            <Button size="large" style={startButtonStyle} onClick={() => setViewIndex(1)}>Start</Button>
                        </div>

                    </div>
                </Fragment>

            )}
        </ProjectConsumer>
    )
}

export default IntroView;

const startButtonStyle = {
    backgroundColor: 'white',
    color: 'black',
    boxShadow: "2px 2px 1px 1px #ccc",
}